import React, { FC, useEffect, useMemo, useState } from 'react';
import { UserInfo } from '../../../common/model/dto/user-info';
import { useTranslation } from 'react-i18next';
import ChartData from '../../../common/model/type/chart-data.type';
import { Range } from 'react-date-range';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { DailyData } from '../../../common/model/dto/daily-data';
import ChartUtils from '../../../common/utils/services/chart-utils';
import DateUtils from '../../../common/utils/services/date-utils';
import { clearDailyDataList } from '../../../redux/reducers/daily-data.slice';
import MathUtils from '../../../common/utils/services/math-utils';
import { Constants } from '../../../common/constants';
import { HormoneValueType } from '../../../common/model/type/hormone-values.type';
import { addDays } from 'date-fns';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import { Box } from '@mui/material';
import OvHormoneChartToggleButtons from '../molecules/OvHormoneChartToggleButtons';
import OvChartLabel from '../atoms/OvChartLabel';
import OvHormoneLineChart from './OvHormoneLineChart';
import OvHormoneDetails from '../molecules/OvHormoneDetails';
import OvNoContent from '../molecules/OvNoContent';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import OvUserChartsDaterangeSelector from '../molecules/OvUserChartsDaterangeSelector';
import { clearPatientDailyData } from '../../../redux/reducers/clinic-location.slice';
import Colours from '../../../design-system/colours';

interface OvUserChartsProps {
  user: UserInfo;
  clinicLocationId?: string | undefined;
  loadDailyData: any;
  loadDailyDataForCalendar: any;
}

const OvUserCharts: FC<OvUserChartsProps> = ({
  user,
  clinicLocationId,
  loadDailyData,
  loadDailyDataForCalendar,
}) => {
  const { t } = useTranslation();
  const [selectCycleValue, setSelectCycleValue] = useState('last cycle');
  const [isBBTOn, setIsBBTOn] = useState(false);
  const [showAllScans, setShowAllScans] = useState(false);
  const [lhData, setLhData] = useState<ChartData[] | null>(null);
  const [pgData, setPgData] = useState<ChartData[] | null>(null);
  const [egData, setEgData] = useState<ChartData[] | null>(null);
  const [selectedDay, setSelectedDay] = useState<ChartData>();
  const [cycleRange, setCycleRange] = useState<Range[]>([
    {
      startDate: undefined,
      endDate: new Date(''),
      key: 'selection',
    },
  ]);
  const userDailyDataArray: DailyData[] = useAppSelector(
    (state) => state.dailyData.dailyDataList
  );
  const patientDailyDataArray: DailyData[] = useAppSelector(
    (state) => state.clinicLocation.patientDailyData
  );
  const isUserDailyDataLoading = useAppSelector(
    (state) => state.dailyData.isUserDailyDataLoading
  );
  const isPatientDailyDataLoading = useAppSelector(
    (state) => state.clinicLocation.isPatientDailyDataLoading
  );
  const isLoading = isUserDailyDataLoading || isPatientDailyDataLoading;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      const { startDate, endDate } = ChartUtils.getDateRange(
        selectCycleValue,
        DateUtils.getDateFromTimestamp(
          user?.last_first_date_of_period ?? user.created_at
        ),
        user.length_of_average_monthly_cycle,
        cycleRange[0]
      );

      if (clinicLocationId) {
        dispatch(clearPatientDailyData());
        dispatch(
          loadDailyData({
            startDate: startDate,
            endDate: endDate,
            clinicLocationId,
            patientId: user.document_id!,
          })
        );
      } else {
        dispatch(clearDailyDataList());
        dispatch(
          loadDailyData({
            startDate: startDate,
            endDate: endDate,
            userDocumentId: user.document_id!,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleRange, user, selectCycleValue]);

  useMemo(() => {
    const dailyDataArray = clinicLocationId
      ? patientDailyDataArray
      : userDailyDataArray;

    if (
      !user ||
      cycleRange.length <= 0 ||
      cycleRange[0] === undefined ||
      (!user.last_first_date_of_period && !user.created_at) ||
      !dailyDataArray
    ) {
      return [[], []];
    }

    const { startDate, endDate } = ChartUtils.getDateRange(
      selectCycleValue,
      DateUtils.getDateFromTimestamp(
        user?.last_first_date_of_period ?? user.created_at
      ),
      user.length_of_average_monthly_cycle,
      cycleRange[0]
    );

    const windowLength = ChartUtils.getWindowLength(
      startDate,
      endDate,
      selectCycleValue
    );

    const lhChartData: ChartData[] = [];
    const pgChartData: ChartData[] = [];
    const egChartData: ChartData[] = [];

    let currentDate = startDate;

    for (let i = 0; i < windowLength; i++) {
      const dateTag = DateUtils.getDbDateTag(currentDate);
      const dailyData = dailyDataArray.find((dailyData) => {
        return dailyData.day === dateTag;
      });

      const baseChartData = {
        index: i,
        dateStr: DateUtils.formatLocaleDate(currentDate),
        dailyData: dailyData,
      };

      lhChartData.push({
        ...baseChartData,
        value: MathUtils.normalizeValue(
          dailyData?.lh ?? Constants.LH_MIN,
          Constants.LH_MIN,
          Constants.LH_MAX
        ),
        measured: ChartUtils.isValuePresent(HormoneValueType.LH, dailyData),
        scansChartData: ChartUtils.mapScansToScanChartData(
          Constants.LH_MIN,
          Constants.LH_MAX,
          i,
          HormoneValueType.LH,
          dailyData
        ),
      });
      pgChartData.push({
        ...baseChartData,
        value: MathUtils.normalizeValue(
          dailyData?.pg ?? Constants.PG_MIN,
          Constants.PG_MIN,
          Constants.PG_MAX
        ),
        measured: ChartUtils.isValuePresent(HormoneValueType.PG, dailyData),
        scansChartData: ChartUtils.mapScansToScanChartData(
          Constants.PG_MIN,
          Constants.PG_MAX,
          i,
          HormoneValueType.PG,
          dailyData
        ),
      });
      egChartData.push({
        ...baseChartData,
        value: MathUtils.normalizeValue(
          dailyData?.lr_e3g ?? Constants.LR_E3G_MIN,
          Constants.LR_E3G_MIN,
          Constants.LR_E3G_MAX
        ),
        measured: ChartUtils.isValuePresent(HormoneValueType.LR_E3G, dailyData),
        scansChartData: ChartUtils.mapScansToScanChartData(
          Constants.LR_E3G_MIN,
          Constants.LR_E3G_MAX,
          i,
          HormoneValueType.LR_E3G,
          dailyData
        ),
      });

      currentDate = addDays(currentDate, 1);
    }

    ChartUtils.estimateMissingData(lhChartData);
    ChartUtils.estimateMissingData(pgChartData);
    ChartUtils.estimateMissingData(egChartData);

    setLhData(lhChartData);
    setPgData(pgChartData);
    setEgData(egChartData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCycleValue, cycleRange, userDailyDataArray, patientDailyDataArray]);

  const handleSelectDayFromHormoneChart = (selectedDay: ChartData) => {
    setSelectedDay(selectedDay);
  };

  return (
    <>
      <OverViewWrapper>
        {!isLoading && lhData && pgData && egData && user && (
          <Container>
            <OvUserChartsDaterangeSelector
              selectCycleValue={selectCycleValue}
              defaultText={`${lhData[0].dateStr} - ${
                lhData[lhData.length - 1].dateStr
              }`}
              setSelectCycleValue={setSelectCycleValue}
              cycleRange={cycleRange}
              setCycleRange={setCycleRange}
              user={user}
              loadDailyDataBetweenDates={loadDailyDataForCalendar}
              clinicLocationId={clinicLocationId}
            />

            <ContentWrapper>
              <ChartContainer>
                <Box pt={3.5} pb={2} pl={5} pr={5}>
                  <OvHormoneChartToggleButtons
                    isBBTOn={isBBTOn}
                    setIsBBTOn={setIsBBTOn}
                    showAllScans={showAllScans}
                    setShowAllScans={setShowAllScans}
                  />
                  <ChartWrapper>
                    <OvChartLabel
                      style={{ display: 'block', marginBottom: '1' }}
                    >
                      {t('dashboard.luteinizingHormone')}
                    </OvChartLabel>
                    <OvHormoneLineChart
                      data={{
                        type: HormoneValueType.LH,
                        unit: Constants.LH_UNIT_OF_MEASURE,
                        values: lhData,
                      }}
                      isBBTOn={isBBTOn}
                      isCustomDateRange={selectCycleValue !== 'last cycle'}
                      onSelectDay={handleSelectDayFromHormoneChart}
                      chartMaxValue={Constants.LH_MAX + 10}
                      showAllScans={showAllScans}
                    />
                    <StyledDisclaimerContainer>
                      <ul>
                        <li>Follicular Phase: 2.4 - 12.6</li>
                        <li>Ovulation or Mid Cycle: 14.0 - 95.6</li>
                        <li>Luteal Phase: 1.0 - 11. 4</li>
                        <li>Postmenopausal: 7.7 - 58.5</li>
                      </ul>
                    </StyledDisclaimerContainer>
                  </ChartWrapper>
                  <ChartWrapper>
                    <OvChartLabel
                      style={{ display: 'block', marginBottom: '1rem' }}
                    >
                      {t('dashboard.progesteroneTitle')}
                    </OvChartLabel>

                    <OvHormoneLineChart
                      data={{
                        type: HormoneValueType.PG,
                        unit: Constants.PG_UNIT_OF_MEASURE,
                        values: pgData,
                      }}
                      isBBTOn={isBBTOn}
                      isCustomDateRange={selectCycleValue !== 'last cycle'}
                      onSelectDay={handleSelectDayFromHormoneChart}
                      chartMaxValue={Constants.PG_MAX + 5}
                      showAllScans={showAllScans}
                    />
                    <StyledDisclaimerContainer>
                      <ul>
                        <li>Follicular Phase: 0.1 − 0.9</li>
                        <li>Ovulation or Mid Cycle: 0.1 − 12.0</li>
                        <li>Luteal Phase: 1.8 − 23.9</li>
                        <li>Perimenopause: 0.48 - 19.0</li>
                      </ul>
                    </StyledDisclaimerContainer>
                  </ChartWrapper>
                  <ChartWrapper>
                    <OvChartLabel
                      style={{ display: 'block', marginBottom: '1rem' }}
                    >
                      {t('dashboard.estrogenTitle')}
                    </OvChartLabel>
                    <OvHormoneLineChart
                      data={{
                        type: HormoneValueType.LR_E3G,
                        unit: Constants.E3G_UNIT_OF_MEASURE,
                        values: egData,
                      }}
                      isBBTOn={isBBTOn}
                      isCustomDateRange={selectCycleValue !== 'last cycle'}
                      onSelectDay={handleSelectDayFromHormoneChart}
                      chartMaxValue={Constants.LR_E3G_CHART_MAX}
                      showAllScans={showAllScans}
                    />
                    <StyledDisclaimerContainer>
                      <ul>
                        <li>Follicular Phase: 12.5 − 166.0</li>
                        <li>Ovulation or Mid Cycle: 85.8 − 498.0</li>
                        <li>Luteal Phase: 43.8 − 211.0</li>
                        <li>Postmenopausal: &lt;6.0 − 54.7</li>
                      </ul>
                    </StyledDisclaimerContainer>
                  </ChartWrapper>
                </Box>
              </ChartContainer>

              {selectedDay && (
                <HormoneDetailsContainer>
                  <OvHormoneDetails data={selectedDay} />
                </HormoneDetailsContainer>
              )}
            </ContentWrapper>
          </Container>
        )}
        {!isLoading && !lhData && !pgData && !egData && (
          <OvNoContent>{t('dashboard.noContent')}</OvNoContent>
        )}
      </OverViewWrapper>
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default OvUserCharts;

const OverViewWrapper = styled.div`
  display: 'flex';
  flex-direction: 'row';
  flex-wrap: 'nowrap';
  width: 100%;
`;

const Container = styled.div``;

const ChartContainer = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  border-radius: ${Variables.borderRadius.SMALL};
  width: calc(100% - 18.5rem);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const HormoneDetailsContainer = styled.div`
  margin-left: 1.5rem;
  width: 17rem;
  word-break: break-word;
`;

const StyledDisclaimerContainer = styled.div`
  color: ${Colours.OV_BASE};
  font-size: 0.8rem;
`;

const ChartWrapper = styled.div`
  border-bottom: 1px solid ${Colours.OV_BASE};
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
  }
`;
