import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config';
import { setAuthData } from '../../redux/reducers/auth.slice';
import { useAppDispatch } from '../../redux/hooks';
import { userInfo } from '../../redux/thunks/user.thunk';
import { getEntitlements } from '../../redux/thunks/auth.thunk';
import UserService from '../../services/user.service';
import { Role } from '../../common/model/type/role.enum';
import { ApprovementStatus } from '../../common/model/dto/approvement-status.enum';

export default function useUserAuthStatus(): boolean {
  const [isInitialUserFetch, setIsInitialUserFetch] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isInitialUserFetch) {
        setIsInitialUserFetch(false);
      }

      if (user) {
        // If user is not a provider or an admin login is not allowed
        const userEntity = await UserService.getUserInfo();
        if (
          !userEntity.roles.includes(Role.Admin) &&
          userEntity.provider_clinic_locations?.filter(
            (providerClinicLocation) =>
              providerClinicLocation.status === ApprovementStatus.ACCEPTED
          ).length < 1
        ) {
          dispatch(setAuthData(''));
          return;
        }

        // if session is live, set auth data for state, and get current user info
        dispatch(setAuthData(user.uid));
        dispatch(getEntitlements());
        dispatch(userInfo());
      } else {
        dispatch(setAuthData(''));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch, isInitialUserFetch]);

  return isInitialUserFetch;
}
