import { UserInfo } from '../../model/dto/user-info';
import {
  OvTableData,
  OvTableRowData,
} from '../../../components/UI/molecules/OvTable';
import DateUtils from './date-utils';
import ConversionUtils from './conversion-utils';
import { isNumber } from 'lodash';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import { UserInfoFields } from '../../../firebase/document-field.enums';
import i18n from 'i18next';
import { CovidVaccineType } from '../../model/type/covid-vaccine.type';
import OvOsIcon from '../../../components/UI/atoms/OvOsIcon';
import React from 'react';
import { Constants } from '../../constants';
import { CycleInfo } from '../../model/dto/cycle-info';
import Colours from '../../../design-system/colours';
import { HealthGoalType } from '../../model/dto/health-goal-type.enum';

export class UserInfoDataUtils {
  static mapUserAccountDetailsToTableDataFormat(
    userInfo?: UserInfo
  ): OvTableData {
    return [
      {
        label: 'common.os',
        value: React.createElement(OvOsIcon, {
          osType: userInfo?.os || '',
          size: '1.5rem',
        }),
        translateValue: false,
        translateLabel: true,
      },
      {
        label: 'common.userFields.roles',
        translateLabel: true,
        value: userInfo?.roles,
        editOptions: {
          propertyName: UserInfoFields.roles,
          widgetType: TableRowEditWidgetType.NON_NULLABLE_MULTI_SELECT,
          options: [
            {
              label: 'Regular',
              value: 'Regular',
            },
            {
              label: 'Admin',
              value: 'Admin',
            },
          ],
        },
      },
      {
        label: 'common.userFields.currentlyUsedProduct',
        value: userInfo?.currently_used_products
          ? userInfo.currently_used_products[0]
          : null,
        translateLabel: true,
        editOptions: {
          options: [
            {
              label: i18n.t(
                'common.userFields.currentlyUsedProductValues.oovaKitOrRefill'
              ),
              value: 'Oova Kit or Refill',
            },
            {
              label: i18n.t(
                'common.userFields.currentlyUsedProductValues.discoveryPack'
              ),
              value: 'Discovery Pack',
            },
          ],
          propertyName: UserInfoFields.currently_used_products,
          widgetType: TableRowEditWidgetType.SELECT,
        },
      },
      {
        label: 'common.userFields.isUsageGuidanceEnabled',
        value: userInfo?.is_usage_guidance_enabled,
        prettifiedValue: userInfo?.is_usage_guidance_enabled
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: {
          propertyName: UserInfoFields.is_usage_guidance_enabled,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'common.userFields.location',
        value: userInfo?.location,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.location,
          placeholder: i18n.t('common.userFields.location'),
        },
      },
      {
        label: 'common.userFields.scanWindowOffset',
        value: userInfo?.scan_window_start_adjustment,
        translateLabel: true,
      },
      {
        label: 'common.userFields.nextScanWindowStart',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.next_scan_cycle_start,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.nextScanWindowEnd',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.next_scan_cycle_end,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.logPreferences',
        value: userInfo?.daily_logs,
        translateLabel: true,
      },
      {
        label: 'common.userFields.regDate',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.registration_date,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationTimezone',
        value: userInfo?.registration_time_zone_abbreviation,
        translateLabel: true,
      },
      {
        label: 'common.userFields.registrationTimezoneLocation',
        value: userInfo?.registration_time_zone_identifier,
        translateLabel: true,
      },
    ];
  }

  static mapGeneralUserInfoDataToTableFormat(userInfo?: UserInfo): OvTableData {
    return [
      {
        label: 'common.userFields.email',
        value: userInfo?.email,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.email,
          placeholder: i18n.t('common.userFields.email'),
        },
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.date_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.date_of_birth,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.date_of_birth,
        },
      },

      {
        label: 'common.userFields.height',
        value: userInfo?.height_array,
        prettifiedValue: userInfo?.height_array?.length
          ? `${userInfo?.height_array[0]}' ${
              userInfo?.height_array[1]
            }'' | ${ConversionUtils.heightInCm(
              userInfo?.height_array[0],
              userInfo?.height_array[1]
            ).toFixed(2)} ${i18n.t('common.measurements.cm')}`
          : '',
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.HEIGHT,
          propertyName: UserInfoFields.height_array,
        },
      },
      {
        label: 'common.userFields.weight',
        value: userInfo?.weight,
        prettifiedValue: isNumber(userInfo?.weight)
          ? `${userInfo?.weight} ${i18n.t('common.measurements.lbs')} | ${
              userInfo?.weight &&
              ConversionUtils.lbsToKg(userInfo?.weight).toFixed(2)
            } ${i18n.t('common.measurements.kg')}`
          : userInfo?.weight,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          min: 0,
          placeholder: `${i18n.t('common.userFields.weight')} ${i18n.t(
            'common.measurements.lbs'
          )}`,
          propertyName: UserInfoFields.weight,
        },
      },
      {
        label: 'common.userFields.race',
        value: userInfo?.race,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.SELECT,
          propertyName: UserInfoFields.race,
          options: [
            {
              label: i18n.t(
                'common.userFields.raceValues.americanIndianOrAlaskaNative'
              ),
              value: 'American Indian or Alaska Native',
            },
            {
              label: i18n.t('common.userFields.raceValues.asian'),
              value: 'Asian',
            },
            {
              label: i18n.t(
                'common.userFields.raceValues.blackOrAfricanAmerican'
              ),
              value: 'Black or African-American',
            },
            {
              label: i18n.t('common.userFields.raceValues.nativeHawaiian'),
              value: 'Native Hawaiian or Other Pacific Islands',
            },
            {
              label: i18n.t('common.userFields.raceValues.white'),
              value: 'White',
            },
            {
              label: i18n.t('common.userFields.raceValues.moreThanOne'),
              value: 'More than once race',
            },
            {
              label: i18n.t('common.userFields.raceValues.unknown'),
              value: 'Unknown',
            },
          ],
        },
      },
      {
        label: 'common.userFields.ethnicity',
        value: userInfo?.ethnicity,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.SELECT,
          propertyName: UserInfoFields.ethnicity,
          options: [
            {
              label: i18n.t(
                'common.userFields.ethnicityValues.hispanicOrLatino'
              ),
              value: 'Hispanic or Latino',
            },
            {
              label: i18n.t(
                'common.userFields.ethnicityValues.notHispanicOrLatino'
              ),
              value: 'Not Hispanic or Latino',
            },
            {
              label: i18n.t('common.userFields.ethnicityValues.unknown'),
              value: 'Unknown',
            },
          ],
        },
      },
    ];
  }

  static mapUserCovidInfoToTableDataFormat(
    userInfo?: UserInfo,
    editable: boolean = false
  ): OvTableData {
    return [
      {
        label: 'common.userFields.covidInfo.testedPositive',
        value: userInfo?.covid_ever_tested_positive,
        prettifiedValue: userInfo?.covid_ever_tested_positive
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.covid_ever_tested_positive,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.covidInfo.firstDateOfPositiveTest',
        value: userInfo?.covid_date_of_first_positive_test,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.covid_date_of_first_positive_test,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.covid_date_of_first_positive_test,
              widgetType: TableRowEditWidgetType.DATE,
            }
          : undefined,
      },
      {
        label: 'common.userFields.covidInfo.isVaccinatedEver',
        value: userInfo?.covid_ever_received_vaccine,
        prettifiedValue: userInfo?.covid_ever_received_vaccine
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.covid_ever_received_vaccine,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.covidInfo.amountOfDoses',
        value: userInfo?.covid_number_of_doses_received,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.covid_number_of_doses_received,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 0,
              max: 5,
              step: 0.5,
            }
          : undefined,
      },
      {
        label: 'common.userFields.covidInfo.vaccineBrand',
        value: userInfo?.covid_brand_of_vaccine_received,
        translateLabel: true,
        editOptions: editable
          ? {
              options: [
                {
                  label: CovidVaccineType.JOHNSON_AND_JOHNSON,
                  value: CovidVaccineType.JOHNSON_AND_JOHNSON,
                },
                {
                  label: CovidVaccineType.PFIZER,
                  value: CovidVaccineType.PFIZER,
                },
                {
                  label: CovidVaccineType.MODERNA,
                  value: CovidVaccineType.MODERNA,
                },
                {
                  label: CovidVaccineType.ASTRA,
                  value: CovidVaccineType.ASTRA,
                },
                {
                  label: CovidVaccineType.OTHER,
                  value: CovidVaccineType.OTHER,
                },
              ],
              propertyName: UserInfoFields.covid_brand_of_vaccine_received,
              widgetType: TableRowEditWidgetType.MULTI_SELECT,
            }
          : undefined,
      },
    ];
  }

  static mapUserHealthInfoToTableDataFormat(
    userInfo?: UserInfo,
    editable: boolean = false
  ): OvTableData {
    return [
      {
        label: 'common.userFields.personalMaxLh',
        value: userInfo?.personal_max_lh,
        prettifiedValue: isNumber(userInfo?.personal_max_lh)
          ? `${userInfo?.personal_max_lh} ${Constants.LH_UNIT_OF_MEASURE}`
          : null,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.personal_max_lh,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 0.0,
              max: Constants.LH_MAX,
              step: 0.01,
              canReset: true,
            }
          : undefined,
      },
      {
        label: 'common.userFields.personalMaxPg',
        value: userInfo?.personal_max_pg,
        prettifiedValue: isNumber(userInfo?.personal_max_pg)
          ? `${userInfo?.personal_max_pg} ${Constants.PG_UNIT_OF_MEASURE}`
          : null,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.personal_max_pg,
              widgetType: TableRowEditWidgetType.NUMBER,
              min: 0.0,
              max: Constants.PG_MAX,
              step: 0.01,
              canReset: true,
            }
          : undefined,
      },
      {
        label: 'common.userFields.healthGoal',
        value: userInfo?.current_fertility_goal,
        translateLabel: true,
        editOptions: editable
          ? {
              options: [
                {
                  label: i18n.t('common.userFields.goals.tryToConceive'),
                  value: HealthGoalType.TRYING_TO_CONCEIVE,
                },
                {
                  label: i18n.t(
                    'common.userFields.goals.perimenopauseTracking'
                  ),
                  value: HealthGoalType.PERIMENOPAUSE_TRACKING,
                },
                {
                  label: i18n.t(
                    'common.userFields.goals.generalHealthMonitoring'
                  ),
                  value: HealthGoalType.GENERAL_HEALTH_MONITORING,
                },
              ],
              propertyName: UserInfoFields.current_fertility_goal,
              widgetType: TableRowEditWidgetType.SELECT,
            }
          : undefined,
      },
      {
        label: 'common.userFields.reproductiveDisorder',
        value: userInfo?.known_reproductive_disorder,
        translateLabel: true,
        editOptions: editable
          ? {
              options: [
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.none'
                  ),
                  value: 'None',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.endometriosis'
                  ),
                  value: 'Endometriosis',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.fibroids'
                  ),
                  value: 'Fibroids',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.hormonalImbalance'
                  ),
                  value: 'Hormonal Imbalance',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.infertility'
                  ),
                  value: 'Infertility',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.pelvicInflammatoryDisease'
                  ),
                  value: 'Pelvic Inflammatory Disease (PID)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.perimenopause'
                  ),
                  value: 'Perimenopause',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.polycysticOvarySyndrome'
                  ),
                  value: 'Polycystic Ovary Syndrome (PCOS)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.poorEggQuality'
                  ),
                  value: 'Poor Egg Quality',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.sexuallyTransmittedDiseases'
                  ),
                  value: 'Sexually Transmitted Diseases (STDs)',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.tubalFactors'
                  ),
                  value: 'Tubal Factors',
                },
                {
                  label: i18n.t(
                    'common.userFields.reproductiveDisorderValues.other'
                  ),
                  value: 'Other',
                },
              ],
              propertyName: UserInfoFields.known_reproductive_disorder,
              widgetType: TableRowEditWidgetType.SELECT,
            }
          : undefined,
      },
      {
        label: 'common.userFields.birthControl',
        prettifiedValue: userInfo?.is_taking_birth_control
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: userInfo?.is_taking_birth_control,
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.is_taking_birth_control,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.sexuallyActive',
        value: userInfo?.is_sexually_active,
        prettifiedValue: userInfo?.is_sexually_active
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.is_sexually_active,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.isPregnant',
        value: userInfo?.is_pregnant,
        prettifiedValue: userInfo?.is_pregnant
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.is_pregnant,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.breastFeeding',
        value: userInfo?.breast_feeding,
        prettifiedValue: userInfo?.breast_feeding
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        translateLabel: true,
        editOptions: editable
          ? {
              propertyName: UserInfoFields.breast_feeding,
              widgetType: TableRowEditWidgetType.BOOLEAN,
            }
          : undefined,
      },
      {
        label: 'common.userFields.averageCycleLength',
        value: userInfo?.length_of_average_monthly_cycle,
        translateLabel: true,
      },
      {
        label: 'common.userFields.lastPeriodStart',
        value: DateUtils.getDateStrFromTimestamp(
          userInfo?.last_first_date_of_period,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.lastPeriodStartReg',
        value: userInfo?.registration_last_first_date_of_period,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.registration_last_first_date_of_period,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.registration_last_first_date_of_period,
        },
      },
    ];
  }

  static mapGeneralPatientInfoToTableDataFormat(
    userInfo?: UserInfo
  ): OvTableData {
    return [
      {
        label: 'common.userFields.height',
        value: userInfo?.height_array,
        prettifiedValue: userInfo?.height_array?.length
          ? `${userInfo?.height_array[0]}' ${
              userInfo?.height_array[1]
            }'' | ${ConversionUtils.heightInCm(
              userInfo?.height_array[0],
              userInfo?.height_array[1]
            ).toFixed(2)} ${i18n.t('common.measurements.cm')}`
          : '',
        translateLabel: true,
      },
      {
        label: 'common.userFields.weight',
        value: userInfo?.weight,
        prettifiedValue: isNumber(userInfo?.weight)
          ? `${userInfo?.weight} ${i18n.t('common.measurements.lbs')} | ${
              userInfo?.weight &&
              ConversionUtils.lbsToKg(userInfo?.weight).toFixed(2)
            } ${i18n.t('common.measurements.kg')}`
          : userInfo?.weight,
        translateLabel: true,
      },
      {
        label: 'common.userFields.race',
        value: userInfo?.race,
        translateLabel: true,
      },
      {
        label: 'common.userFields.ethnicity',
        value: userInfo?.ethnicity,
        translateLabel: true,
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.date_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.date_of_birth,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
    ];
  }

  static mapGeneralProviderData(userInfo?: UserInfo): OvTableData {
    return [
      {
        label: 'common.userFields.firstName',
        value: userInfo?.first_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.first_name,
          placeholder: i18n.t('common.userFields.firstName'),
        },
      },
      {
        label: 'common.userFields.lastName',
        value: userInfo?.last_name,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.last_name,
          placeholder: i18n.t('common.userFields.lastName'),
        },
      },
      {
        label: 'common.userFields.email',
        value: userInfo?.email,
        translateLabel: true,
      },
      {
        label: 'common.userFields.dateOfBirth',
        value: userInfo?.date_of_birth,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          userInfo?.date_of_birth,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: UserInfoFields.date_of_birth,
        },
      },
    ];
  }

  static mapProviderInfoToTableDataFormat(
    userInfo?: UserInfo,
    clinicLocationId?: string
  ): OvTableData {
    return [
      {
        label: 'common.userFields.providerData.title',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.title,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.title,
          placeholder: i18n.t('common.userFields.providerData.title'),
        },
      },
      {
        label: 'common.userFields.providerData.phoneNumber',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.phone_number,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: UserInfoFields.phone_number,
          placeholder: i18n.t('common.userFields.providerData.phoneNumber'),
        },
      },
      {
        label: 'common.userFields.providerData.aboutMe',
        value: userInfo?.provider_clinic_locations.find(
          (providerClinicLocation) =>
            providerClinicLocation.id === clinicLocationId
        )?.about_me,
        translateLabel: true,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT_AREA,
          propertyName: UserInfoFields.about_me,
          placeholder: i18n.t('common.userFields.providerData.aboutMe'),
        },
      },
    ];
  }

  static mapUserCycleInfoToTableDataFormat(cycle_info: CycleInfo): OvTableData {
    const futureLhPgScanWindowsNestedRows: OvTableRowData[] = [];
    const futureLrE3gScanWindowsNestedRows: OvTableRowData[] = [];

    if (cycle_info?.scanWindows) {
      let index = 1;

      for (const scanWindow of cycle_info.scanWindows) {
        futureLhPgScanWindowsNestedRows.push({
          label: i18n.t('common.userFields.cycleInfo.scanWindow', {
            numberOfScanWindow: index,
          }),
          value: scanWindow.lh_pg,
          prettifiedValue: `${DateUtils.getDateStrFromTimestamp(
            scanWindow?.lh_pg?.scan_window_start,
            'DD MMMM, YYYY'
          )} - ${DateUtils.getDateStrFromTimestamp(
            scanWindow?.lh_pg?.scan_window_end,
            'DD MMMM, YYYY'
          )}`,
          translateLabel: true,
        });

        futureLrE3gScanWindowsNestedRows.push({
          label: i18n.t('common.userFields.cycleInfo.scanWindow', {
            numberOfScanWindow: index++,
          }),
          value: scanWindow?.lr_e3g,
          prettifiedValue: `${DateUtils.getDateStrFromTimestamp(
            scanWindow?.lr_e3g?.scan_window_start,
            'DD MMMM, YYYY'
          )} - ${DateUtils.getDateStrFromTimestamp(
            scanWindow?.lr_e3g?.scan_window_end,
            'DD MMMM, YYYY'
          )}`,
          translateLabel: true,
        });
      }
    }

    return [
      {
        label: 'common.userFields.cycleInfo.position',
        value: cycle_info?.position,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningStartsForLHPG',
        value: cycle_info?.daysUntilScanningStartsForLHPG,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningEndsForLHPG',
        value: cycle_info?.daysUntilScanningEndsForLHPG,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningStartsForLRE3G',
        value: cycle_info?.daysUntilScanningStartsForLRE3G,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilScanningEndsForLRE3G',
        value: cycle_info?.daysUntilScanningEndsForLRE3G,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysUntilFertileWindow',
        value: cycle_info?.daysUntilFertileWindow,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfCycleStart',
        value: cycle_info?.dateOfCycleStart,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfCycleStart,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfScanStart',
        value: cycle_info?.dateOfScanStart,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfScanStart,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dateOfScanEnd',
        value: cycle_info?.dateOfScanEnd,
        prettifiedValue: DateUtils.getDateStrFromTimestamp(
          cycle_info?.dateOfScanEnd,
          'DD MMMM, YYYY'
        ),
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.scanWindowLength',
        value: cycle_info?.scanWindowLength,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.scanWindowStartOffset',
        value: cycle_info?.scanWindowStartOffset,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dayOfScanning',
        value: cycle_info?.dayOfScanning,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.dayOfCycle',
        value: cycle_info?.dayOfCycle,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.cycleLengthInDays',
        value: cycle_info?.cycleLengthInDays,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.shouldScanLhPg',
        prettifiedValue: cycle_info?.shouldScanLhPg
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: cycle_info?.shouldScanLhPg,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.shouldScanLrE3g',
        prettifiedValue: cycle_info?.shouldScanE3g
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        value: cycle_info?.shouldScanE3g,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysSinceLastPeriodStart',
        value: cycle_info?.daysSinceLastPeriodStart,
        translateLabel: true,
      },
      {
        label: 'common.userFields.cycleInfo.daysInPostOvulation',
        value: cycle_info?.daysInPostOvulation,
        translateLabel: true,
      },
      {
        label: i18n.t('common.userFields.cycleInfo.scanWindows', {
          hormoneType: 'LH-PG',
        }),
        nestedRows: futureLhPgScanWindowsNestedRows,
        value: !!cycle_info?.scanWindows,
        style: {
          backgroundColor: Colours.OV_LIGHT_GRAY,
        },
        showNestedRows: false,
      },
      {
        label: i18n.t('common.userFields.cycleInfo.scanWindows', {
          hormoneType: 'LR-E3G',
        }),
        nestedRows: futureLrE3gScanWindowsNestedRows,
        value: !!cycle_info?.scanWindows,
        style: {
          backgroundColor: Colours.OV_LIGHT_GRAY,
        },
        showNestedRows: false,
      },
    ];
  }
}
