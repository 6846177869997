import React, { FC } from 'react';
import styled from 'styled-components';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { OsType } from '../../../common/model/type/os.type';
import Colours from '../../../design-system/colours';

const OvOsIcon: FC<{ osType: string; size: string; color?: string }> = ({
  osType,
  size,
  color,
}) => {
  const osTypeLowerCase = osType?.toLowerCase();

  if (osTypeLowerCase.indexOf(OsType.IOS) !== -1) {
    return <StyledAppleIcon style={{ width: size, height: size, color }} />;
  } else if (osTypeLowerCase.indexOf(OsType.ANDROID) !== -1) {
    return <StyledAndroidIcon style={{ width: size, height: size, color }} />;
  }

  return <></>;
};

export default OvOsIcon;

const StyledAppleIcon = styled(AppleIcon)`
  && {
    color: ${Colours.BLACK};
  }
`;

const StyledAndroidIcon = styled(AndroidIcon)`
  && {
    color: ${Colours.ANDROID_GREEN};
  }
`;
