import { FC } from 'react';
import styled from 'styled-components';
import { ClinicLocationUtils } from '../../common/utils/services/clinic-location-utils';
import breakpoints from '../../design-system/breakpoints';
import OvTable from '../UI/molecules/OvTable';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { useTranslation } from 'react-i18next';
import { CLINIC_LOCATION_PROFILE_IMAGES_PATH } from '../../api/firebase-storage';
import OvEditableAvatar from '../UI/molecules/OvEditableAvatar';

const ClinicLocationInfo: FC<{
  selectedClinicLocation?: ClinicLocation;
  handleClinicLocationUpdate?: (obj: any) => any;
  isLoading?: boolean;
  canEdit?: boolean;
}> = ({
  selectedClinicLocation,
  handleClinicLocationUpdate,
  isLoading,
  canEdit,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <OvEditableAvatar
        entity={selectedClinicLocation}
        handleUpdate={handleClinicLocationUpdate}
        imageBasePath={CLINIC_LOCATION_PROFILE_IMAGES_PATH}
        dialogTitle={t('clinicLocationDialogs.clinicLocationImageUploadTitle')}
        defaultImageComponent={<ProfileImage />}
      />

      <TableWrapper>
        <OvTable
          data={ClinicLocationUtils.mapClinicLocationToTableDataFormat(
            selectedClinicLocation,
            canEdit
          )}
          onSaveRowValue={handleClinicLocationUpdate}
        />
        {isLoading && <OvLoadingIndicator position={'fixed'} />}
      </TableWrapper>
    </Container>
  );
};

export default ClinicLocationInfo;

const Container = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const ProfileImage = styled(LocalHospitalIcon)`
  && {
    width: 12rem !important;
    height: 12rem !important;
    border: 0.375rem solid;
    border-radius: 50%;
    margin-bottom: 0.25rem;
    padding: 0.75rem;
  }
`;
