import React, { FC } from 'react';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import OvScanSequenceListRow from './OvScanSequenceListRow';

const OvScanSequencesList: FC<{ scanSequences: ScanSequence[] }> = ({
  scanSequences,
}) => {
  const { t } = useTranslation();
  const headerNames = [
    'common.scanSequenceFields.healthGoal',
    'common.scanSequenceFields.isHealthGoalDefault',
    'common.scanSequenceFields.order',
    'common.scanSequenceFields.scanningPlanType',
    'common.scanSequenceFields.numberOfScanningPlans',
    'common.scanSequenceFields.enabled',
    'common.operation',
    'common.action',
  ];

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          {headerNames.map((headerName) => (
            <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
          ))}
        </OvListTableRow>
        {scanSequences.map((scanSequence) => (
          <OvListTableRow>
            <OvScanSequenceListRow scanSequence={scanSequence} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvScanSequencesList;

const WrapperBox = styled.div`
  background: white 0 0 no-repeat padding-box;
  border-radius: 0.75rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
