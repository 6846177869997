import React, { FC } from 'react';
import styled from 'styled-components';
import { Clinic } from '../../../common/model/dto/clinic';
import OvClinicListRow from './OvClinicListRow';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import Variables from '../../../design-system/variables';
import { useTranslation } from 'react-i18next';

const OvClinicList: FC<{ clinics: Clinic[] }> = ({ clinics }) => {
  const { t } = useTranslation();

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          <OvListTableHeaderField>
            {t('common.clinicFields.clinicName')}
          </OvListTableHeaderField>
          <OvListTableHeaderField style={{ textAlign: 'right' }}>
            {t('common.action')}
          </OvListTableHeaderField>
        </OvListTableRow>

        {clinics.map((clinic) => (
          <OvListTableRow>
            <OvClinicListRow clinic={clinic} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvClinicList;

const WrapperBox = styled.div`
  background: white 0 0 no-repeat padding-box;
  border-radius: 0.75rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
