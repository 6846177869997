import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isNumber } from 'lodash';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ClinicLocationPatient } from '../../common/model/dto/clinic-location-patient';
import { ProviderPatient } from '../../common/model/dto/provider-patient';
import { UserInfo } from '../../common/model/dto/user-info';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterType } from '../../common/model/ui/filter.type';
import { ListPaging } from '../../common/types';
import { ClinicLocationUtils } from '../../common/utils/services/clinic-location-utils';
import {
  DocumentFields,
  UserInfoFields,
} from '../../firebase/document-field.enums';
import i18n from '../../i18n/config';
import ReducerUtils from '../reducer.utils';
import { ClinicLocationState } from '../states/clinic-location.state';
import {
  acceptPatientInvitation,
  acceptPatientInvitationFromProvider,
  acceptProviderInvitation,
  assignPatientToClinicLocation,
  assignPatientToProvider,
  createDemoAccount,
  getClinicLocationById,
  getPatientDetails,
  getPatientScans,
  getScansForClinicLocation,
  loadAllFertilityDisorders,
  loadClinicLocationPatients,
  loadClinicLocationProviders,
  loadDailyDataBetweenDateRangeByPatientId,
  loadDailyDataEntity,
  loadOwnDailyData,
  loadOwnDailyDataBetWeenDateRange,
  loadPatientDailyData,
  revokePatientProviderAssignment,
} from '../thunks/clinic-location.thunk';
import { DailyData } from '../../common/model/dto/daily-data';
import { Timestamp } from 'firebase/firestore';
import DateUtils from '../../common/utils/services/date-utils';
import moment from 'moment';
import { Scan } from '../../common/model/dto/scan';
import { FilterUtils } from '../../common/utils/services/filter.utils';

const initialState: ClinicLocationState = {
  selectedClinicLocation: null,
  isLoading: false,
  userList: [],
  userListFilters: [
    {
      fieldName: UserInfoFields.provider_id,
      label: 'common.userFields.provider',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: UserInfoFields.should_hide_not_registered_users,
      label: 'common.userSearchFilters.shouldHideNotRegisteredUsers',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.is_demo_account,
      label: 'common.userSearchFilters.showDemoAccounts',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.date_of_birth,
      label: 'common.userFields.age',
      type: FilterType.slider,
      value: [0, 99],
      min: 0,
      max: 99,
      convertTo: 'age',
    },
    {
      fieldName: UserInfoFields.is_pregnant,
      label: 'common.userSearchFilters.isPregnant',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.known_reproductive_disorder,
      label: 'common.userFields.fertilityDisorder',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: UserInfoFields.is_taking_birth_control,
      label: 'common.userSearchFilters.isTakingBirthControl',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.length_of_average_monthly_cycle,
      label: 'common.userFields.averageCycleLength',
      type: FilterType.slider,
      value: [0, 40],
      min: 0,
      max: 40,
      convertTo: 'number',
    },
  ],
  userListSortingOptions: [
    {
      value: DocumentFields.id,
      label: 'common.userFields.userId',
      direction: 'asc',
      multiDir: false,
    },
    {
      value: UserInfoFields.scan_created_at,
      label: 'common.userFields.latestScan',
      direction: '',
      multiDir: false,
    },
    {
      value: UserInfoFields.first_name,
      label: 'common.userFields.firstName',
      direction: '',
      multiDir: true,
    },
    {
      value: UserInfoFields.last_name,
      label: 'common.userFields.lastName',
      direction: '',
      multiDir: true,
    },
  ],
  showUserListFilters: true,
  userListPaging: {
    total: 0,
    offset: 0,
  },
  patientDailyData: [],
  isPatientDailyDataLoading: false,
  patientDailyDataInCalendar: [],
  areCalendarDailyLogsLoading: false,
  selectedUser: null,
  areFertilityDisordersLoading: false,
  areProvidersLoading: false,
  error: '',
  acceptRequestError: '',
  isAcceptRequestLoading: false,
  visibleDailyDataInCalendar: [],
  scanList: [],
  isPatientScansLoading: false,
  scanListFilters: [],
  scanListPaging: {
    total: 0,
    offset: 0,
  },
};

const clinicLocationSlice = createSlice({
  name: 'clinicLocation',
  initialState,
  reducers: {
    clearSelectedClinicLocation: (state) => {
      state.selectedClinicLocation = null;
    },
    setUserListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.userListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.userListPaging.offset = action.payload.offset;
      }
    },
    clearUserList: (state) => {
      state.userList = [];
    },
    setUserListStoredQuery: (state, action: PayloadAction<string>) => {
      state.userListStoredQuery = action.payload;
    },
    toggleFilterVisibility: (state) => {
      state.showUserListFilters = !state.showUserListFilters;
    },
    clearUserFilters: (state) => {
      state.userListFilters = state.userListFilters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.userListFilters[index].value,
          disabled: false,
        };
      });
      state.userListStoredQuery = '';
      state.userListPaging.offset = 0;
    },
    restorePatientSearchModel: (state, action: PayloadAction<any>) => {
      state.userListFilters = ReducerUtils.restoreFilters(
        action.payload,
        state.userListFilters
      );
      state.userListSortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.userListSortingOptions
      );
      state.userListPaging.offset = action.payload.offset;
    },
    changePatientListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.userListFilters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      let parsedValue = action.payload?.value;

      if (action.payload?.value === 'true') {
        parsedValue = true;
      } else if (action.payload?.value === 'false') {
        parsedValue = false;
      }

      state.userListFilters[index].value = parsedValue;
      state.userListPaging.offset = 0;
    },
    changeUserListSorting: (state, action: PayloadAction<UserInfoFields>) => {
      state.userListSortingOptions = state.userListSortingOptions.map(
        (option) => {
          if (option.value === action.payload) {
            if (option.multiDir) {
              option.direction = option.direction === 'asc' ? 'desc' : 'asc';
            } else {
              option.direction =
                option.value === UserInfoFields.scan_created_at
                  ? 'desc'
                  : 'asc';
            }
          } else {
            option.direction = '';
          }

          return option;
        }
      );

      state.userListPaging.offset = 0;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },
    clearPatientDailyData: (state) => {
      state.patientDailyData = [];
    },
    clearAcceptRequestError: (state) => {
      state.acceptRequestError = '';
    },
    clearDaySlotSelection: (state) => {
      state.selectedDailyDataInCalendar = undefined;
      state.selectedDay = undefined;
    },
    selectDaySlot: (state, action: PayloadAction<Date>) => {
      if (action.payload) {
        state.selectedDay = Timestamp.fromDate(action.payload);

        if (state.patientDailyData?.length) {
          state.selectedDailyDataInCalendar = state.patientDailyData.find(
            ({ day }) => {
              return DateUtils.areSameDays(
                action.payload,
                moment(day, 'YYYY-MM-DD').toDate()
              );
            }
          );
        }
      }
    },
    clearSelectedDailyData: (state) => {
      state.selectedDailyData = undefined;
    },
    clearScanList: (state) => {
      state.scanList = [];
      state.scanListPaging = initialState.scanListPaging;
    },
    setScanListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.scanListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.scanListPaging.offset = action.payload.offset;
      }
    },
    setScanListStoredQuery: (state, action: PayloadAction<string>) => {
      state.scanListStoredQuery = action.payload;
    },
    restoreScanListSearchModel: (state, action: PayloadAction<any>) => {
      state.scanListFilters = ReducerUtils.restoreFilters(
        action.payload,
        state.scanListFilters
      );
      state.scanListPaging.offset = action.payload.offset;
    },
    clearScanListFilters: (state) => {
      state.scanListFilters = state.scanListFilters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.scanListFilters[index].value,
          disabled: false,
        };
      });
      state.scanListStoredQuery = '';
      state.scanListPaging.offset = 0;
    },
    changeScanListFilters: (state, action: PayloadAction<FilterModel>) => {
      const index = state.scanListFilters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.scanListFilters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.scanListPaging.offset = 0;
    },
    changeFullscriptPatientId: (state, action: PayloadAction<string>) => {
      if (state.selectedUser) {
        state.selectedUser.fullscript_patient_id = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClinicLocationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getClinicLocationById.fulfilled,
        (state, action: PayloadAction<ClinicLocation>) => {
          state.selectedClinicLocation = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadClinicLocationPatients.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        loadClinicLocationPatients.fulfilled,
        (state, action: PayloadAction<UserInfo[]>) => {
          state.userList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadPatientDailyData.pending, (state) => {
        state.isPatientDailyDataLoading = true;
      })
      .addCase(
        loadPatientDailyData.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.patientDailyData = action.payload;
          state.isPatientDailyDataLoading = false;
        }
      )
      .addCase(loadPatientDailyData.rejected, (state) => {
        state.isPatientDailyDataLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadOwnDailyData.pending, (state) => {
        state.isPatientDailyDataLoading = true;
      })
      .addCase(
        loadOwnDailyData.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.patientDailyData = action.payload;
          state.isPatientDailyDataLoading = false;
        }
      )
      .addCase(loadOwnDailyData.rejected, (state) => {
        state.isPatientDailyDataLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getPatientDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getPatientDetails.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = action.payload;

          if (state.userList) {
            state.userList = state.userList.map((user) => {
              if (user.id === action.payload.id) {
                return {
                  ...user,
                  ...action.payload,
                };
              }

              return user;
            });
          }

          state.isLoading = false;
        }
      )
      .addCase(getPatientDetails.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadDailyDataBetweenDateRangeByPatientId.pending, (state) => {
        state.areCalendarDailyLogsLoading = true;
      })
      .addCase(
        loadDailyDataBetweenDateRangeByPatientId.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.areCalendarDailyLogsLoading = false;
          state.patientDailyDataInCalendar = action.payload;
        }
      )
      .addCase(loadDailyDataBetweenDateRangeByPatientId.rejected, (state) => {
        state.areCalendarDailyLogsLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getPatientScans.pending, (state) => {
        state.isPatientScansLoading = true;
      })
      .addCase(
        getPatientScans.fulfilled,
        (state, action: PayloadAction<Scan[]>) => {
          state.isPatientScansLoading = false;
          state.scanList = action.payload;
        }
      )
      .addCase(getPatientScans.rejected, (state) => {
        state.isPatientScansLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getScansForClinicLocation.pending, (state) => {
        state.isPatientScansLoading = true;
        state.error = '';
      })
      .addCase(
        getScansForClinicLocation.fulfilled,
        (state, action: PayloadAction<Scan[]>) => {
          state.isPatientScansLoading = false;
          state.scanList = action.payload;
          state.error = '';
        }
      )
      .addCase(
        getScansForClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isPatientScansLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadDailyDataEntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadDailyDataEntity.fulfilled,
        (state, action: PayloadAction<DailyData>) => {
          state.isLoading = false;
          state.selectedDailyData = action.payload;
        }
      )
      .addCase(loadDailyDataEntity.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadOwnDailyDataBetWeenDateRange.pending, (state) => {
        state.areCalendarDailyLogsLoading = true;
      })
      .addCase(
        loadOwnDailyDataBetWeenDateRange.fulfilled,
        (state, action: PayloadAction<DailyData[]>) => {
          state.areCalendarDailyLogsLoading = false;
          state.patientDailyDataInCalendar = action.payload;
        }
      )
      .addCase(loadOwnDailyDataBetWeenDateRange.rejected, (state) => {
        state.areCalendarDailyLogsLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadAllFertilityDisorders.pending, (state) => {
        state.areFertilityDisordersLoading = true;
      })
      .addCase(
        loadAllFertilityDisorders.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.areFertilityDisordersLoading = false;
          const fertilityDisorderFilterIdx = state.userListFilters.findIndex(
            (filter) =>
              filter.fieldName === UserInfoFields.known_reproductive_disorder
          );
          state.userListFilters[fertilityDisorderFilterIdx].options = [];
          state.userListFilters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.none'),
            value: 'None',
          });

          action.payload?.forEach((disorder) => {
            state.userListFilters[fertilityDisorderFilterIdx]?.options?.push({
              label: disorder,
              value: disorder,
            });
          });

          state.userListFilters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.other'),
            value: 'Other',
          });
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadClinicLocationProviders.pending, (state) => {
        state.areProvidersLoading = true;
      })
      .addCase(
        loadClinicLocationProviders.fulfilled,
        (
          state,
          action: PayloadAction<
            { first_name: string; last_name: string; id: string }[]
          >
        ) => {
          state.areProvidersLoading = false;
          const fertilityDisorderFilterIdx = state.userListFilters.findIndex(
            (filter) => filter.fieldName === UserInfoFields.provider_id
          );

          action.payload?.forEach((provider) => {
            state.userListFilters[fertilityDisorderFilterIdx]?.options?.push({
              label: `${provider.first_name} ${provider.last_name}`,
              value: provider.id,
            });
          });
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(assignPatientToProvider.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        assignPatientToProvider.fulfilled,
        (state, action: PayloadAction<ProviderPatient>) => {
          if (state.selectedUser) {
            state.selectedUser = {
              ...state.selectedUser,
              ...action.payload.patient,
            };
          }

          if (state.userList) {
            state.userList = state.userList.map((user) => {
              if (user.id === action.payload.patient.id) {
                return {
                  ...user,
                  ...action.payload.patient,
                };
              }

              return user;
            });
          }

          state.isLoading = false;
        }
      )
      .addCase(assignPatientToProvider.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(revokePatientProviderAssignment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        revokePatientProviderAssignment.fulfilled,
        (state, action: PayloadAction<ProviderPatient>) => {
          state.isLoading = false;
        }
      )
      .addCase(revokePatientProviderAssignment.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(assignPatientToClinicLocation.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        assignPatientToClinicLocation.fulfilled,
        (state, action: PayloadAction<ClinicLocationPatient>) => {
          state.isLoading = false;
          if (state.userList) {
            state.userList = [action.payload.user, ...state.userList];
          } else {
            state.userList = [action.payload.user];
          }
        }
      )
      .addCase(
        assignPatientToClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          const responseData = action.payload.response?.data as any;

          state.error = ClinicLocationUtils.isDuplicateKeyError(
            responseData?.message
          )
            ? i18n.t('clinicLocationDetails.errors.patientAlreadyInvited')
            : action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createDemoAccount.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        createDemoAccount.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.isLoading = false;
        }
      )
      .addCase(
        createDemoAccount.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(acceptPatientInvitation.pending, (state) => {
        state.isAcceptRequestLoading = true;
        state.error = '';
      })
      .addCase(acceptPatientInvitation.fulfilled, (state) => {
        state.isAcceptRequestLoading = false;
      })
      .addCase(
        acceptPatientInvitation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          const data = action.payload.response?.data as any;
          state.isAcceptRequestLoading = false;
          state.acceptRequestError = data.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(acceptProviderInvitation.pending, (state) => {
        state.isAcceptRequestLoading = true;
        state.error = '';
      })
      .addCase(acceptProviderInvitation.fulfilled, (state) => {
        state.isAcceptRequestLoading = false;
      })
      .addCase(
        acceptProviderInvitation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          const data = action.payload.response?.data as any;
          state.isAcceptRequestLoading = false;
          state.acceptRequestError = data.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(acceptPatientInvitationFromProvider.pending, (state) => {
        state.isAcceptRequestLoading = true;
        state.error = '';
      })
      .addCase(acceptPatientInvitationFromProvider.fulfilled, (state) => {
        state.isAcceptRequestLoading = false;
      })
      .addCase(
        acceptPatientInvitationFromProvider.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          const data = action.payload.response?.data as any;
          state.isAcceptRequestLoading = false;
          state.acceptRequestError = data.message;
        }
      );
  },
});

export const {
  clearSelectedClinicLocation,
  setUserListPaging,
  clearUserList,
  setUserListStoredQuery,
  toggleFilterVisibility,
  clearUserFilters,
  restorePatientSearchModel,
  changePatientListFilter,
  changeUserListSorting,
  clearSelectedUser,
  clearPatientDailyData,
  clearAcceptRequestError,
  selectDaySlot,
  clearDaySlotSelection,
  clearSelectedDailyData,
  clearScanList,
  changeFullscriptPatientId,
  setScanListStoredQuery,
  restoreScanListSearchModel,
  setScanListPaging,
  clearScanListFilters,
  changeScanListFilters,
} = clinicLocationSlice.actions;
export default clinicLocationSlice.reducer;
