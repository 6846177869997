import React, { FC } from 'react';
import { FilterModel } from '../../../common/model/ui/filter.model';
import styled from 'styled-components';
import OvSearchFilter from './OvSearchFilter';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';

interface FiltersComponentProps {
  filters: FilterModel[];
  filterChange?: (filter: FilterModel) => void;
}

const ArticleListFilters: FC<FiltersComponentProps> = ({
  filters,
  filterChange,
}) => {
  const { t } = useTranslation();
  const handleFilterChange = (filter: FilterModel) => {
    if (filterChange) {
      filterChange(filter);
    }
  };

  return (
    <Wrapper>
      <WidgetTitle>{t('common.filters')}</WidgetTitle>
      {filters.map((filter) => {
        return (
          <OvSearchFilter
            key={filter.fieldName}
            filter={filter}
            applyFilter={handleFilterChange}
          />
        );
      })}
    </Wrapper>
  );
};

export default ArticleListFilters;

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const WidgetTitle = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;
