import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useRouteMatch } from 'react-router-dom';
import OvListTableField from '../atoms/OvListTableField';
import StringUtils from '../../../common/utils/services/string-utils';
import { Product } from '../../../common/model/dto/product/product';
import OvCompactButton from '../atoms/OvCompactButton';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@mui/material';
import Colours from '../../../design-system/colours';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import LanguageIcon from '@mui/icons-material/Language';

const OvProductListRow: FC<{
  product: Product;
  archiveProductCallback?: (id: string) => void;
  unarchiveProductCallback?: (id: string) => void;
  narrowColumnWidth: string;
}> = ({
  product,
  archiveProductCallback,
  unarchiveProductCallback,
  narrowColumnWidth,
}) => {
  const { t } = useTranslation();
  const [isArhiveConfirmationDialogOpen, setIsArhiveConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isUnarchiveConfirmationDialogOpen,
    setIsUnarchiveConfirmationDialogOpen,
  ] = useState<boolean>(false);
  let { url } = useRouteMatch();

  const onArchive = () => {
    if (product.id && archiveProductCallback) {
      setIsArhiveConfirmationDialogOpen(false);
      archiveProductCallback(product.id);
    }
  };

  const onUnarchive = () => {
    if (product.id && unarchiveProductCallback) {
      setIsUnarchiveConfirmationDialogOpen(false);
      unarchiveProductCallback(product.id);
    }
  };

  const getPlatform = () => {
    if (!product.is_enabled_for_web && !product.is_enabled_for_mobile) {
      return '-';
    }

    return (
      <>
        {product.is_enabled_for_mobile && <SmartphoneIcon />}
        {product?.is_enabled_for_web && <LanguageIcon />}
      </>
    );
  };

  const fieldOpacity = product?.is_active ? 1 : 0.3;

  return (
    <>
      <ProductListTableField style={{ opacity: fieldOpacity }}>
        <TitleWrapper>
          <ThumbnailImage
            loading="lazy"
            src={product.stripe_product_image_url}
            alt={product.title}
          />
          <Title>{StringUtils.displayValue(product.title!)}</Title>
        </TitleWrapper>
      </ProductListTableField>
      <ProductListTableField
        style={{ width: narrowColumnWidth, opacity: fieldOpacity }}
      >
        {StringUtils.displayValue(`${product.product_prices.length}`)}
      </ProductListTableField>
      <ProductListTableField
        style={{ width: narrowColumnWidth, opacity: fieldOpacity }}
      >
        {!!product.is_enabled ? t('common.enabled') : t('common.disabled')}
      </ProductListTableField>
      <ProductListTableField
        style={{ width: narrowColumnWidth, opacity: fieldOpacity }}
      >
        {getPlatform()}
      </ProductListTableField>
      <ProductListTableField
        style={{ width: narrowColumnWidth, opacity: fieldOpacity }}
      >
        {product?.order ?? '-'}
      </ProductListTableField>
      <ProductListTableField
        style={{ width: narrowColumnWidth, opacity: fieldOpacity }}
      >
        {StringUtils.getPrettifiedBooleanValue(product?.is_active)}
      </ProductListTableField>
      <ProductListTableField style={{ width: narrowColumnWidth }}>
        {product.is_active ? (
          <Tooltip title={t('products.actions.archive')}>
            <StyledIconButton
              onClick={() => setIsArhiveConfirmationDialogOpen(true)}
              className="actionIcon"
            >
              <DeleteIcon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          <Tooltip title={t('products.actions.unarchive')}>
            <StyledIconButton
              onClick={() => setIsUnarchiveConfirmationDialogOpen(true)}
              className="actionIcon"
            >
              <RestoreFromTrashIcon />
            </StyledIconButton>
          </Tooltip>
        )}
      </ProductListTableField>
      <ProductListTableField style={{ width: narrowColumnWidth }}>
        <StyledCompactButton>
          <NavLink to={`${url}/detail/${product.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledCompactButton>
      </ProductListTableField>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isArhiveConfirmationDialogOpen}
        onCancel={() => setIsArhiveConfirmationDialogOpen(false)}
        onConfirm={onArchive}
        description="products.actions.archiveDescription"
        title="common.areYouSure"
      />

      <OvConfirmationDialog
        icon={<StyledRestoreFromTrashIcon />}
        isOpen={isUnarchiveConfirmationDialogOpen}
        onCancel={() => setIsUnarchiveConfirmationDialogOpen(false)}
        onConfirm={onUnarchive}
        description="products.actions.unarchiveDescription"
        title="common.areYouSure"
      />
    </>
  );
};

export default OvProductListRow;

const ProductListTableField = styled(OvListTableField)`
  vertical-align: middle !important;
`;

const StyledCompactButton = styled(OvCompactButton)`
  && {
    padding: 0;
  }
`;

const Title = styled.h4`
  margin: 0;
  word-wrap: break-word;
`;

const TitleWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

const ThumbnailImage = styled.img`
  max-width: 4rem;
  border-radius: 0.75rem;
  object-fit: cover;
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledRestoreFromTrashIcon = styled(RestoreFromTrashIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
