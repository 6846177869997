import React, { FC, useState } from 'react';
import styled from 'styled-components';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import { Scan } from '../../../common/model/dto/scan';
import OvListTableField from '../atoms/OvListTableField';
import OvDateParser from '../atoms/OvDateParser';
import { Constants } from '../../../common/constants';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';
import { isNumber } from 'lodash';
import { Link, useRouteMatch } from 'react-router-dom';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import OvNoContent from '../molecules/OvNoContent';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from '@mui/material';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import { deleteScanById } from '../../../redux/thunks/scans.thunk';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import OvCreateScanDialog from '../molecules/OvCreateScanDialog';
import { CreateScanRequest } from '../../../common/model/dto/create-scan-request';
import { createScan } from '../../../redux/thunks/scans.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';

const OvUserScanList: FC<{
  scans: Scan[];
  showDetailsAction?: boolean;
  showOperation?: boolean;
  selectedUserId?: string;
  selectedDay?: string;
}> = ({
  scans,
  showDetailsAction,
  showOperation,
  selectedUserId,
  selectedDay,
}) => {
  const [isCreateScanDialogOpen, setIsCreateScanDialogOpen] =
    useState<boolean>(false);
  const isLoading = useAppSelector((state) => state.scans.isLoading);

  const dispatch = useAppDispatch();
  const headerNames = [
    'common.createdAt',
    'common.modifiedAt',
    'common.dailyData.lh',
    'common.dailyData.pg',
    'common.dailyData.e3g',
  ];

  if (showDetailsAction) {
    headerNames.push('common.action');
  }

  if (showOperation) {
    headerNames.push('common.operation');
  }

  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const [isOpenDeleteScanDialog, setOpenDeleteScanDialog] =
    useState<boolean>(false);
  const [deletableScanId, setDeletableScanId] = useState<string>();

  const openDeleteScanDialog = (id?: string) => {
    setDeletableScanId(id);
    setOpenDeleteScanDialog(true);
  };

  const onDelete = () => {
    if (deletableScanId) {
      setOpenDeleteScanDialog(false);
      dispatch(deleteScanById(deletableScanId));
    }
  };

  const onSaveScan = (request: CreateScanRequest) => {
    dispatch(createScan(request));
    setIsCreateScanDialogOpen(false);
  };

  return (
    <Container>
      {selectedUserId && (
        <StyledCreateScanButton onClick={() => setIsCreateScanDialogOpen(true)}>
          Create scan
        </StyledCreateScanButton>
      )}

      <WrapperBox>
        {scans?.length ? (
          <OvListTable>
            <OvListTableRow>
              {headerNames.map((headerName) => (
                <OvListTableHeaderField key={headerName}>
                  {t(headerName)}
                </OvListTableHeaderField>
              ))}
            </OvListTableRow>
            {scans.map((scan, index) => (
              <OvListTableRow key={index}>
                <OvListTableField>
                  <OvDateParser
                    date={scan?.created_at as Date}
                    format={'YYYY.MM.DD - hh:mm A'}
                  />
                </OvListTableField>
                <OvListTableField>
                  <OvDateParser
                    date={scan?.updated_at as Date}
                    format={'YYYY.MM.DD - hh:mm A'}
                  />
                </OvListTableField>
                <OvListTableField>
                  {isNumber(scan?.lh)
                    ? DailyDataUtils.prettifyValue(
                        scan?.lh,
                        Constants.LH_MAX,
                        Constants.LH_UNIT_OF_MEASURE
                      )
                    : '-'}
                </OvListTableField>
                <OvListTableField>
                  {isNumber(scan?.pg)
                    ? DailyDataUtils.prettifyValue(
                        scan?.pg,
                        Constants.PG_MAX,
                        Constants.PG_UNIT_OF_MEASURE
                      )
                    : '-'}
                </OvListTableField>
                <OvListTableField>
                  {isNumber(scan?.lr_e3g)
                    ? DailyDataUtils.prettifyValue(
                        scan?.lr_e3g,
                        Constants.LR_E3G_MAX,
                        Constants.E3G_UNIT_OF_MEASURE
                      )
                    : '-'}
                </OvListTableField>
                {showOperation && (
                  <OvListTableField>
                    <Tooltip title={t('common.dailyData.deleteScan') || ''}>
                      <StyledIconButton
                        disabled={scan.is_daily_log_record}
                        onClick={() => openDeleteScanDialog(scan.id)}
                        className="actionIcon"
                      >
                        <DeleteIcon />
                      </StyledIconButton>
                    </Tooltip>
                  </OvListTableField>
                )}
                {showDetailsAction && (
                  <OvListTableField>
                    <StyledOvButton>
                      <Link to={`${url}/${scan.id}`}>
                        {t('common.actions.details')}
                      </Link>
                    </StyledOvButton>
                  </OvListTableField>
                )}
              </OvListTableRow>
            ))}
          </OvListTable>
        ) : (
          <OvNoContent>{t('common.empty')}</OvNoContent>
        )}
      </WrapperBox>
      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteScanDialog}
        onCancel={() => setOpenDeleteScanDialog(false)}
        onConfirm={onDelete}
        description="common.dailyData.permanentScanDelete"
        title="common.areYouSure"
      />

      {selectedUserId && selectedDay && (
        <OvCreateScanDialog
          isOpen={isCreateScanDialogOpen}
          onCancel={() => setIsCreateScanDialogOpen(false)}
          onSave={onSaveScan}
          selectedUserId={selectedUserId}
          selectedDay={selectedDay}
        />
      )}
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default OvUserScanList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperBox = styled.div`
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledCreateScanButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    padding: 0 1rem;
    text-transform: none;
    justify-self: center;
    align-self: flex-end;
  }
`;
