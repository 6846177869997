import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useTranslation } from 'react-i18next';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createLot, getLots } from '../../redux/thunks/lots.thunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { useHistory } from 'react-router-dom';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvPagination from '../UI/atoms/OvPagination';
import LotsService from '../../services/lots.service';
import {
  setLotListPaging,
  setLotListSorting,
} from '../../redux/reducers/lots.slice';
import { ListPaging, OrderBy, SortDirection } from '../../common/types';
import { Lot } from '../../common/model/dto/lot';
import OvListTable from '../UI/atoms/OvListTable';
import OvListTableHeaderField from '../UI/atoms/OvListTableHeaderField';
import OvListTableRow from '../UI/atoms/OvListTableRow';
import OvLotListRow from '../UI/organisms/OvLotListRow';
import OvLotKindSelectorModal from '../UI/molecules/OvLotKindSelectorModal';
import { LotType } from '../../common/model/type/lot.type';
import { LotFields } from '../../firebase/document-field.enums';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import OvCompactButton from '../UI/atoms/OvCompactButton';

const LotList: FC = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector((state) => state.lots.loading);
  const lotList: Lot[] = useAppSelector((state) => state.lots.lotList);
  const lotListPaging: ListPaging = useAppSelector(
    (state) => state.lots.lotListPaging
  );
  const lotListSorting: OrderBy = useAppSelector(
    (state) => state.lots.lotListSorting
  );
  const [isOpenLotKindSelectorModal, setOpenLotKindSelectorModal] =
    useState<boolean>(false);
  const narrowColumnWidth = '10rem';

  useEffect(() => {
    dispatch(getLots({ limit: LotsService.LOT_LIST_LIMIT }));
  }, [dispatch]);

  const goToPage = (event: any, value: number) => {
    dispatch(
      setLotListPaging({
        offset: (value - 1) * LotsService.LOT_LIST_LIMIT,
        total: lotListPaging.total,
      })
    );

    dispatch(getLots({ limit: LotsService.LOT_LIST_LIMIT }));
  };

  const pageCount: () => number = () =>
    Math.ceil(lotListPaging?.total / LotsService.LOT_LIST_LIMIT);

  const onCreateLot = (type: LotType) => {
    dispatch(createLot({ history, type: type }));
  };

  const onSortByColumn = (orderBy: OrderBy) => {
    dispatch(setLotListSorting(orderBy));
    dispatch(
      setLotListPaging({
        offset: 0,
        total: lotListPaging.total,
      })
    );
    dispatch(getLots({ limit: LotsService.LOT_LIST_LIMIT }));
  };

  const getCurrentSortDirection = (columnName: LotFields): SortDirection =>
    columnName === lotListSorting?.order_by
      ? lotListSorting?.order_dir
      : 'none';

  return (
    <>
      <Container {...props}>
        <TitleWrapper>
          <OvPageTitle title={t('lots.title')} icon={<QrCodeIcon />}>
            <OvCompactButton
              icon={<AddCircleIcon />}
              onClick={() => setOpenLotKindSelectorModal(true)}
            >
              {t('lots.createNew')}
            </OvCompactButton>
          </OvPageTitle>
        </TitleWrapper>

        {pageCount() > 1 ? (
          <OvPagination
            page={lotListPaging.offset / LotsService.LOT_LIST_LIMIT + 1}
            onChange={goToPage}
            count={pageCount()}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
            shape="rounded"
          />
        ) : (
          ''
        )}

        <StyledOvListTable>
          <OvListTableRow>
            <OvListTableHeaderField
              sortDirection={getCurrentSortDirection(LotFields.name)}
              sortBy={LotFields.name}
              onSort={onSortByColumn}
              style={{ width: narrowColumnWidth }}
            >
              {t('lots.fields.name')}
            </OvListTableHeaderField>
            <OvListTableHeaderField
              sortDirection={getCurrentSortDirection(LotFields.document_id)}
              sortBy={LotFields.document_id}
              onSort={onSortByColumn}
            >
              {t('lots.fields.documentId')}
            </OvListTableHeaderField>
            <OvListTableHeaderField>
              {t('lots.fields.createdAt')}
            </OvListTableHeaderField>
            <OvListTableHeaderField
              sortDirection={getCurrentSortDirection(LotFields.type)}
              sortBy={LotFields.type}
              onSort={onSortByColumn}
              style={{ width: narrowColumnWidth }}
            >
              {t('lots.fields.kind')}
            </OvListTableHeaderField>
            <OvListTableHeaderField style={{ width: narrowColumnWidth }}>
              {t('common.action')}
            </OvListTableHeaderField>
          </OvListTableRow>

          {lotList?.length
            ? lotList.map((lot) => {
                return (
                  <OvListTableRow key={lot.id}>
                    <OvLotListRow
                      lot={lot}
                      narrowColumnWidth={narrowColumnWidth}
                    />
                  </OvListTableRow>
                );
              })
            : ''}
        </StyledOvListTable>

        {isLoading && <OvLoadingIndicator position="fixed" />}
      </Container>

      <OvLotKindSelectorModal
        isOpen={isOpenLotKindSelectorModal}
        saveSelection={onCreateLot}
        cancelSelection={() => setOpenLotKindSelectorModal(false)}
      />
    </>
  );
};

export default LotList;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const TitleWrapper = styled.div`
  margin-bottom: 1.25rem;
`;

const StyledOvListTable = styled(OvListTable)`
  margin-top: 1.5rem;
`;
