export enum TableRowEditWidgetType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  MULTI_SELECT = 'multi_select',
  NON_NULLABLE_MULTI_SELECT = 'non_nullable_multi_select',
  BOOLEAN = 'boolean',
  DATE = 'date',
  HEIGHT = 'height',
  TEXT_AREA = 'text_area',
  TEXT_WITH_PREFIX = 'text_with_prefix',
}
