import axios from 'axios';
import { stringify } from 'query-string';
import { ADMIN_CLINIC_INFO_ROUTE } from '../../api/backend';
import { Clinic } from '../../common/model/dto/clinic';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { CreateClinicLocationRequest } from '../../common/model/dto/create-clinic-location-request';
import { CreateClinicRequest } from '../../common/model/dto/create-clinic-request';
import { Paginated } from '../../common/types';

export class AdminClinicService {
  public static readonly CLINIC_LIST_LIMIT: number = 10;

  static async loadClinicsByQuery(query: string) {
    const { data } = await axios.get<Paginated<Clinic>>(
      `${ADMIN_CLINIC_INFO_ROUTE}?${query}`
    );

    return data;
  }

  static async getClinicTypeahead(clinicName: string) {
    const query = stringify({
      name: clinicName.toUpperCase(),
      limit: 10,
    });

    const { data } = await axios.get<Paginated<Clinic>>(
      `${ADMIN_CLINIC_INFO_ROUTE}?${query}`
    );

    return data.docs;
  }

  static async getClinicById(clinicId: string): Promise<Clinic> {
    const { data } = await axios.get<Clinic>(
      `${ADMIN_CLINIC_INFO_ROUTE}/${clinicId}`
    );

    return data;
  }

  static async createClinic(request: CreateClinicRequest): Promise<Clinic> {
    const { data } = await axios.post<Clinic>(
      `${ADMIN_CLINIC_INFO_ROUTE}`,
      request
    );

    return data;
  }

  static async updateClinic(clinicId: string, updateObj: any): Promise<void> {
    await axios.patch<Clinic>(
      `${ADMIN_CLINIC_INFO_ROUTE}/${clinicId}`,
      updateObj
    );
  }

  static async createClinicLocation(
    clinicId: string,
    request: CreateClinicLocationRequest
  ) {
    const { data } = await axios.post<ClinicLocation>(
      `${ADMIN_CLINIC_INFO_ROUTE}/${clinicId}/locations`,
      request
    );

    return data;
  }

  static async deleteClinic(clinicId: string) {
    await axios.delete(`${ADMIN_CLINIC_INFO_ROUTE}/${clinicId}`);
  }
}
