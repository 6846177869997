import { stringify } from 'query-string';
import { ClinicLocation } from '../../model/dto/clinic-location';
import { UserInfo } from '../../model/dto/user-info';
import { Constants } from '../../constants';

export class StripeUtils {
  static populatePaymentLinkWithData(
    user: UserInfo,
    clinicLocation: ClinicLocation,
    prefilledEmail: string
  ) {
    const clienReferenceId = `OV1_${user.nano_id}_${clinicLocation.clinic_location_id}`;

    const queryParams = stringify({
      prefilled_email: prefilledEmail,
      client_reference_id: clienReferenceId,
      prefilled_promo_code: Constants.IS_PRODUCTION ? 'PROVIDER10' : 'OOVA10',
    });

    return queryParams;
  }
}
