import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrderStatus } from '../../../common/model/type/order-status.enum';
import breakpoints from '../../../design-system/breakpoints';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import OvSelect from '../atoms/OvSelect';
import OvTextButton from '../atoms/OvTextButton';

export interface OvEditOrderStatusDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onSave: ({ status }: { status?: OrderStatus }) => void;
  currentStatus?: OrderStatus;
}

const SET_STATUS_OPTIONS = [
  OrderStatus.Hold,
  OrderStatus.Processing,
  OrderStatus.Completed,
  OrderStatus.Archived,
];

const OvEditOrderStatusDialog: FC<OvEditOrderStatusDialogProps> = ({
  isOpen,
  onCancel,
  onSave,
  currentStatus,
}) => {
  const { t } = useTranslation();
  const formik = useFormik<{ status?: OrderStatus }>({
    initialValues: {
      status: SET_STATUS_OPTIONS.includes(currentStatus!)
        ? currentStatus
        : OrderStatus.Processing,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values);
    },
    validateOnBlur: true,
  });

  return (
    <StyledDialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>
        {t('orders.details.dialogs.updateStatus.title')}
      </StyledDialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent>
          <StyledSelect {...formik.getFieldProps('status')}>
            {SET_STATUS_OPTIONS.map((status) => (
              <MenuItem
                key={status}
                value={status}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '5px',
                }}
              >
                {status}
              </MenuItem>
            ))}
          </StyledSelect>
          <StyledDialogActions>
            <OvTextButton onClick={onCancel}>
              {t('common.actions.cancel')}
            </OvTextButton>
            <StyledOvButton type="submit">
              {t('common.actions.save')}
            </StyledOvButton>
          </StyledDialogActions>
        </StyledDialogContent>
      </form>
    </StyledDialog>
  );
};

export default OvEditOrderStatusDialog;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        min-width: 20rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: 1rem;
    padding: 0 1rem;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0 1rem;
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 1.5rem 0 0 0;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 1.5rem 0 0 0 !important;
  }
`;

const StyledSelect = styled(OvSelect)`
  && {
    &.MuiInputBase-root {
      font-size: 0.875rem;
      border-color: ${Colours.OV_BASE};
      width: 100%;
      padding: 0.5rem;

      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colours.OV_BASE};
      }
    }
  }
`;
