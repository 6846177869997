import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowEditWidgetType } from '../../../common/model/ui/table-row-edit-widget.type';
import styled from 'styled-components';
import OvSelect from '../atoms/OvSelect';
import { Checkbox, MenuItem } from '@mui/material';
import OvTextField from '../atoms/OvTextField';
import { isNumber, isUndefined } from 'lodash';
import OvRadioGroup from './OvRadioGroup';
import OvDatePicker from './OvDatePicker';
import OvHeightPicker from './OvHeightPicker';
import Variables from '../../../design-system/variables';
import OvTextArea from '../atoms/OvTextArea';
import Colours from '../../../design-system/colours';

export interface OvTableRowEditModel {
  options?: { label: string; value: any; translate?: boolean }[];
  widgetType: TableRowEditWidgetType;
  value?: any;
  propertyName?: string;
  canReset?: boolean;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  uppercase?: boolean;
  minLength?: number;
  maxLength?: number;
  prefixedString?: {
    prefix?: string;
    value?: string;
  };
  validate?: (field: any) => Promise<boolean>;
  validationErrorMsg?: string;
}

const OvTableRowEditWidget: FC<{
  editOptions: OvTableRowEditModel;
  onChange: (value: any) => void;
  error: string;
  setError: (val: string) => void;
}> = ({ editOptions, error, setError, onChange }) => {
  const { t } = useTranslation();
  let widgetComponent: any;

  const onValueChanged = (event: any, newValue?: any) => {
    let value = event?.target ? event.target.value : newValue;
    let parsedValue = value;

    if (editOptions.widgetType === TableRowEditWidgetType.NUMBER) {
      parsedValue = parseFloat(value);

      if (
        isNumber(parsedValue) &&
        !isUndefined(editOptions.min) &&
        parsedValue < editOptions.min
      ) {
        parsedValue = editOptions.min;
      } else if (
        !isUndefined(editOptions.max) &&
        parsedValue > editOptions.max
      ) {
        parsedValue = editOptions.max;
      }
    } else if (editOptions.widgetType === TableRowEditWidgetType.BOOLEAN) {
      parsedValue = parsedValue === 'true';
    } else if (editOptions.widgetType === TableRowEditWidgetType.HEIGHT) {
      parsedValue = JSON.parse(parsedValue);
    } else if (editOptions.widgetType === TableRowEditWidgetType.TEXT) {
      setError('');
      if (editOptions.uppercase) {
        parsedValue = parsedValue.toUpperCase();
      }

      if (editOptions.minLength && parsedValue.length < editOptions.minLength) {
        setError(
          t('common.errors.minLength', { minLength: editOptions.minLength })
        );
      }

      if (editOptions.maxLength && parsedValue.length > editOptions.maxLength) {
        parsedValue = parsedValue.substring(0, editOptions.maxLength);
      }
    } else if (
      editOptions.widgetType === TableRowEditWidgetType.TEXT_WITH_PREFIX
    ) {
      setError('');
      if (editOptions.uppercase) {
        parsedValue = parsedValue.toUpperCase();
      }

      if (editOptions.minLength && parsedValue.length < editOptions.minLength) {
        setError(
          t('common.errors.minLength', { minLength: editOptions.minLength })
        );
      }

      if (editOptions.maxLength && parsedValue.length > editOptions.maxLength) {
        parsedValue = parsedValue.substring(0, editOptions.maxLength);
      }
    }

    onChange(parsedValue === 'null' ? null : parsedValue);

    return parsedValue;
  };

  const onDateValueChanged = (newDate: Date) => {
    onChange(
      new Date(
        Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate())
      )
    );
  };

  const onMultiSelectValueChanged = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(
      typeof value === 'string'
        ? value.split(',')
        : value?.length
        ? value
        : null
    );
  };

  const onNonNullableMultiSelectValueChanged = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(
      typeof value === 'string'
        ? value.split(',')
        : value?.length
        ? value
        : undefined
    );
  };

  switch (editOptions.widgetType) {
    case TableRowEditWidgetType.SELECT:
      widgetComponent = (
        <StyledOvSelect
          value={editOptions.value}
          onChange={onValueChanged}
          displayEmpty
        >
          <StyledMenuItem key="-" value="null">
            {t('common.actions.notSelected')}
          </StyledMenuItem>
          {editOptions?.options &&
            editOptions?.options.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {option?.translate ? t(option.label) : option.label}
              </StyledMenuItem>
            ))}
        </StyledOvSelect>
      );
      break;
    case TableRowEditWidgetType.MULTI_SELECT:
      widgetComponent = (
        <StyledOvSelect
          value={editOptions.value || []}
          onChange={onMultiSelectValueChanged}
          renderValue={(selected: any) => (selected ? selected.join(', ') : '')}
          multiple
          displayEmpty
        >
          {editOptions?.options &&
            editOptions?.options.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {
                  <Checkbox
                    checked={editOptions.value?.indexOf(option.value) > -1}
                  />
                }
                {option?.translate ? t(option.label) : option.label}
              </StyledMenuItem>
            ))}
        </StyledOvSelect>
      );
      break;
    case TableRowEditWidgetType.NON_NULLABLE_MULTI_SELECT:
      widgetComponent = (
        <StyledOvSelect
          value={editOptions.value || []}
          onChange={onNonNullableMultiSelectValueChanged}
          renderValue={(selected: any) => (selected ? selected.join(', ') : '')}
          multiple
          displayEmpty
        >
          {editOptions?.options &&
            editOptions?.options.map((option) => (
              <StyledMenuItem key={option.value} value={option.value}>
                {
                  <Checkbox
                    checked={editOptions.value?.indexOf(option.value) > -1}
                  />
                }
                {option?.translate ? t(option.label) : option.label}
              </StyledMenuItem>
            ))}
        </StyledOvSelect>
      );
      break;
    case TableRowEditWidgetType.NUMBER:
      widgetComponent = (
        <StyledOvTextField
          type={TableRowEditWidgetType.NUMBER}
          value={
            editOptions.value === null ? editOptions.min : editOptions.value
          }
          onChange={onValueChanged}
          label={editOptions?.placeholder}
          InputProps={{
            inputProps: {
              min: editOptions.min,
              max: editOptions.max,
              step: editOptions.step,
            },
          }}
        />
      );
      break;
    case TableRowEditWidgetType.BOOLEAN:
      widgetComponent = (
        <StyledOvRadioGroup
          options={[
            {
              label: t('common.actions.yes'),
              value: true,
            },
            {
              label: t('common.actions.no'),
              value: false,
            },
          ]}
          value={editOptions.value}
          onChange={onValueChanged}
        />
      );
      break;
    case TableRowEditWidgetType.DATE:
      widgetComponent = (
        <OvDatePicker
          views={['year', 'month', 'day']}
          onChange={onDateValueChanged}
          value={editOptions?.value ? new Date(editOptions?.value) : null}
          slots={(params: any) => <OvTextField {...params} helperText={null} />}
        />
      );
      break;
    case TableRowEditWidgetType.HEIGHT:
      widgetComponent = editOptions.value && (
        <OvHeightPicker
          value={JSON.stringify(editOptions.value)}
          onChange={onValueChanged}
          displayEmpty
        />
      );
      break;
    case TableRowEditWidgetType.TEXT:
      widgetComponent = (
        <StyledOvTextInput
          value={editOptions.value}
          onChange={onValueChanged}
          label={editOptions?.placeholder}
          error={!!error}
          helperText={error ? error : ''}
          onClick={(e: any) => e.stopPropagation()}
        />
      );
      break;
    case TableRowEditWidgetType.TEXT_AREA:
      widgetComponent = (
        <OvTextArea
          value={editOptions.value}
          onChange={onValueChanged}
          label={editOptions?.placeholder}
        />
      );
      break;
    case TableRowEditWidgetType.TEXT_WITH_PREFIX:
      widgetComponent = (
        <StyledTextWithPrefixContainer>
          <StyledDisabledTextField
            value={editOptions.prefixedString?.prefix}
            disabled
          />
          <p>-</p>
          <StyledOvTextInput
            value={editOptions.value}
            onChange={onValueChanged}
            label={editOptions?.placeholder}
            size="small"
            error={!!error}
            helperText={error ? error : ''}
          />
        </StyledTextWithPrefixContainer>
      );
      break;
  }

  return <WidgetWrapper>{widgetComponent}</WidgetWrapper>;
};

export default OvTableRowEditWidget;

const WidgetWrapper = styled.div``;

const StyledOvTextField = styled(OvTextField)`
  && {
    & .MuiOutlinedInput-root {
      & input {
        padding: 6px 10px;
        min-width: 120px;
        font-size: ${Variables.fontSizes.MEDIUM};
      }
    }
  }
`;

const StyledTextWithPrefixContainer = styled.div`
  display: flex;
  justify-content: stretch;
`;

const StyledOvTextInput = styled(OvTextField)`
  && {
    height: 100%;
    flex: 1 0 60%;

    .MuiFormHelperText-root.Mui-error {
      background: ${Colours.OV_LIGHT_GRAY};
      margin: 0;
      height: 0.5rem;
    }
  }
`;

const StyledDisabledTextField = styled(OvTextField)`
  && {
    flex: 1 0 30%;
    background-color: ${Colours.OV_DISABLED};
    color: ${Colours.OV_BASE};
    height: 100%;
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    max-width: 360px;
    width: 100%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledOvRadioGroup = styled(OvRadioGroup)`
  && {
    .MuiFormControlLabel-root {
      margin-right: 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }

    .MuiRadio-root {
      padding: 0.5rem;
      border-radius: 50%;
    }
  }
`;
