import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../common/model/dto/user-info';
import OvUserList from '../UI/organisms/OvUserList';
import OvUserSearchFilter from '../UI/organisms/OvUserSearchFilter';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  loadUsers,
  restoreUserSearchFromUrl,
} from '../../redux/thunks/user.thunk';
import {
  clearUserList,
  setUserListPaging,
  setUserListStoredQuery,
} from '../../redux/reducers/user.slice';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNoContent from '../UI/molecules/OvNoContent';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import OvPagination from '../UI/atoms/OvPagination';
import { ListPaging } from '../../common/types';
import UserService from '../../services/user.service';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { PaginationItem } from '@mui/material';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import OvUserTypeahead from '../UI/molecules/OvUserTypeahead';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';

const FilterableUserList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const userList: UserInfo[] = useAppSelector((state) => state.user.userList);
  const userListPaging: ListPaging = useAppSelector(
    (state) => state.user.userListPaging
  );
  const isLoading: boolean = useAppSelector((state) => state.user.isLoading);
  const userListStoredQuery: string = useAppSelector(
    (state) => state.user.userListStoredQuery
  );
  const errorMessage = useAppSelector((state) => state.user.errorMessage);

  useEffect(() => {
    if (search && search !== `?${userListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreUserSearchFromUrl(search));
    } else if (!userListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(loadUsers(history));
    } else if (!search && userListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setUserListPaging({
          offset: 0,
          total: userListPaging.total,
        })
      );
      dispatch(loadUsers(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setUserListStoredQuery(''));
      dispatch(clearUserList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(userListPaging.offset / UserService.USER_LIST_LIMIT + 1);
  }, [userListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setUserListPaging({
        offset: (value - 1) * UserService.USER_LIST_LIMIT,
        total: userListPaging.total,
      })
    );

    dispatch(loadUsers(history));
  };

  const selectUser = (user: UserInfo | any) => {
    if (user && user?.id) {
      history.push(`/users/${user?.id}`);
    }
  };

  const pageCount: () => number = () =>
    Math.ceil(userListPaging?.total / UserService.USER_LIST_LIMIT);

  const fetchUsers = (searchText: string) => {
    return UserService.getUserTypeahead(searchText);
  };

  return (
    <Container>
      <CenterPane>
        <UserActions>
          <SearchArea>
            <StyledOvUserTypeahead
              placeholder={t('common.actions.search')}
              onUserSelect={selectUser}
              fetchUsers={fetchUsers}
            ></StyledOvUserTypeahead>
          </SearchArea>
          {pageCount() > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </UserActions>
        {errorMessage && (
          <ErrorMessageWrapper>
            <OvErrorMessage message={errorMessage} />
          </ErrorMessageWrapper>
        )}
        {userList?.length ? <OvUserList users={userList} /> : ''}
        {!userList?.length && !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvUserSearchFilter />
    </Container>
  );
};

export default FilterableUserList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SearchArea = styled.div`
  min-width: 13rem;
  flex-grow: 1;

  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }

  & input {
    padding: 0.5rem !important;
    background: ${Colours.WHITE};
  }
`;

const StyledOvUserTypeahead = styled(OvUserTypeahead)`
  max-width: 22.5rem;
`;

const ErrorMessageWrapper = styled.div`
  margin: 1rem 0;
`;
