import React, { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvDateParser from '../UI/atoms/OvDateParser';
import OvScanResults from '../UI/organisms/OvScanResults';
import { Scan } from '../../common/model/dto/scan';
import OvPrettyUserName from '../UI/atoms/OvPrettyUserName';
import { findScanById, updateScan } from '../../redux/thunks/scans.thunk';
import { clearSelectedScan } from '../../redux/reducers/scans.slice';
import { Alert, AlertTitle } from '@mui/material';
import { parseISO } from 'date-fns';
import OvBackButton from '../UI/atoms/OvBackButton';

const ScanDetails: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { scanId }: any = useParams();
  const isLoading: boolean = useAppSelector((state) => state.scans.isLoading);
  const selectedScan: Scan = useAppSelector(
    (state) => state.scans.selectedScan
  );

  useEffect(() => {
    dispatch(findScanById(scanId));

    return function cleanup() {
      dispatch(clearSelectedScan());
    };
  }, [scanId, dispatch]);

  const onEditScan = (editObject: any) => {
    if (selectedScan.id) {
      dispatch(
        updateScan({
          scanId: selectedScan.id,
          updatedProperties: {
            day: selectedScan.day,
            ...editObject,
          },
        })
      );
    }
  };

  return (
    <>
      <DetailPageWrapper>
        <Header>
          <OvBackButton label={t('common.actions.back')} />
          {selectedScan?.user && (
            <StyledLink to={`/users/${selectedScan?.user?.id}`}>
              <PrettyUserName>
                <OvPrettyUserName user={selectedScan.user} />
              </PrettyUserName>
            </StyledLink>
          )}
          {selectedScan?.created_at && (
            <DateInfo>
              <Separator>-</Separator>
              <OvDateParser
                date={parseISO(selectedScan.day as string)}
                format={'dddd, MMMM DD, YYYY'}
              />
            </DateInfo>
          )}
        </Header>

        {selectedScan?.is_demo_scan && (
          <StyledAlert severity="warning">
            <AlertTitle>{t('common.scanDetails.demoAlertTitle')}</AlertTitle>
            {t('common.scanDetails.demoAlertDesc')}
          </StyledAlert>
        )}

        <OvScanResults scan={selectedScan} onSaveEditedScan={onEditScan} />
      </DetailPageWrapper>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default ScanDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const PrettyUserName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const DateInfo = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const Separator = styled.span`
  margin: 0 0.75rem;
`;

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${Colours.OV_BASE};

    &:hover {
      color: ${Colours.OV_BASE_HOVER};
    }
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
