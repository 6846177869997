import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import breakpoints from '../../../design-system/breakpoints';
import DialogActions from '@mui/material/DialogActions';
import OvTextButton from '../atoms/OvTextButton';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import OvSelect from '../atoms/OvSelect';
import { MenuItem } from '@mui/material';
import { LotType } from '../../../common/model/type/lot.type';

export interface OvLotKindSelectorProps {
  isOpen: boolean;
  saveSelection: (type: LotType) => void;
  cancelSelection: () => void;
  title?: string;
}

const OvLotKindSelectorModal: FC<OvLotKindSelectorProps> = ({
  isOpen,
  saveSelection,
  cancelSelection,
  title,
}) => {
  const { t } = useTranslation();
  const lotKindList: { label: string; value: LotType }[] = [
    {
      value: LotType.LR_E3G,
      label: t('common.estrogen'),
    },
    {
      value: LotType.CTRL_LH_PG,
      label: `${t('common.luteinizing')} - ${t('common.progesterone')}`,
    },
    {
      value: LotType.CTRL_LH_PG,
      label: `${t('common.progesterone')} - ${t('common.luteinizing')}`,
    },
  ];

  const [selectedValue, setSelectedValue] = useState<LotType>(
    lotKindList[0].value
  );

  const handleChange = (event: any, newValue?: any) => {
    const value = event?.target ? event.target.value : newValue;
    setSelectedValue(value);
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={cancelSelection}
      aria-labelledby={t(title || 'lots.kindSelection.title')}
    >
      <Title>{t(title || 'lots.kindSelection.title')}</Title>

      <Wrapper>
        <StyledOvSelect value={selectedValue} onChange={handleChange}>
          {lotKindList &&
            lotKindList.map((kind) => (
              <StyledMenuItem key={kind.value} value={kind.value}>
                {kind.label}
              </StyledMenuItem>
            ))}
        </StyledOvSelect>
      </Wrapper>

      <StyledDialogActions>
        <OvTextButton onClick={cancelSelection}>
          {t('common.actions.cancel')}
        </OvTextButton>
        <StyledOvButton onClick={() => saveSelection(selectedValue)}>
          {t('common.actions.create')}
        </StyledOvButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default OvLotKindSelectorModal;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperScrollPaper {
      max-width: 100%;
      background-color: ${Colours.OV_WHITE};

      @media (min-width: ${breakpoints.sm}) {
        max-width: 38rem;
        padding: 1.5rem;
        border-radius: 0.75rem;
      }
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: 1rem;
    padding: 0 1rem;
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    width: 100%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;
