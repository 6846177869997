import React, { FC, useState } from 'react';
import { ScanSequence } from '../../../common/model/dto/scan-sequences/scan-sequence';
import OvListTableField from '../atoms/OvListTableField';
import StringUtils from '../../../common/utils/services/string-utils';
import OvListTableButtonField from '../atoms/OvListTableButtonField';
import styled from 'styled-components';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import Colours from '../../../design-system/colours';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';
import { useAppDispatch } from '../../../redux/hooks';
import { duplicateScanSequence } from '../../../redux/thunks/admin/admin-scan-sequences.thunk';

const OvScanSequenceListRow: FC<{ scanSequence: ScanSequence }> = ({
  scanSequence,
}) => {
  const { t } = useTranslation();
  let { url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onDuplicate = () => {
    if (scanSequence?.id) {
      setIsDialogOpen(false);
      dispatch(duplicateScanSequence({ id: scanSequence.id }));
    }
  };

  return (
    <>
      <OvListTableField>
        {StringUtils.displayValue(scanSequence.health_goal)}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.getPrettifiedBooleanValue(
          scanSequence.is_health_goal_default
        )}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.displayValue(String(scanSequence.order))}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.displayValue(scanSequence.scanning_plan_type)}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.displayValue(String(scanSequence.scanning_plans.length))}
      </OvListTableField>
      <OvListTableField>
        {StringUtils.getPrettifiedBooleanValue(scanSequence.is_enabled)}
      </OvListTableField>
      <OvListTableField>
        <Tooltip title={t('scanSequence.dialogs.duplicateTooltip')}>
          <StyledIconButton
            onClick={() => setIsDialogOpen(true)}
            className="actionIcon"
          >
            <ContentCopyIcon />
          </StyledIconButton>
        </Tooltip>
      </OvListTableField>
      <OvListTableButtonField>
        <StyledOvButton>
          <NavLink to={`${url}/${scanSequence.id}`}>
            {t('common.actions.seeDetails')}
          </NavLink>
        </StyledOvButton>
      </OvListTableButtonField>

      <OvConfirmationDialog
        icon={<StyledContentCopyIcon />}
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onConfirm={onDuplicate}
        description="scanSequence.dialogs.duplicateDialogDescription"
        title="scanSequence.dialogs.duplicateDialogTitle"
      />
    </>
  );
};

export default OvScanSequenceListRow;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0;
    margin: 0;
    background-color: ${Colours.OV_BASE};

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    a {
      border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
      padding: 0.25rem 1rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    height: 2rem;
    width: 2rem;

    .MuiSvgIcon-root {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.MuiButtonBase-root {
      padding: 0.375rem;
      border-radius: 50%;
    }
  }
`;

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
