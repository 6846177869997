import { UserInfo } from '../common/model/dto/user-info';
import axios from 'axios';
import { stringify } from 'query-string';
import { ADMIN_USERS_ROUTE, USERS_ROUTE } from '../api/backend';
import { Paginated } from '../common/types';
import DateUtils from '../common/utils/services/date-utils';
import { SetDemoAccountPasswordRequest } from '../common/model/dto/set-demo-account-password-request.ts';
import { ActivateScanningPlanRequest } from '../common/model/dto/scan-sequences/activate-scanning-plan';
import { CancelActiveScanningPlanRequest } from '../common/model/dto/scan-sequences/cancel-active-scanning-plan-request';

class UserService {
  public static readonly USER_LIST_LIMIT: number = 10;

  static async getUserInfo(): Promise<UserInfo> {
    const { data } = await axios.get<UserInfo>(`/users/me`);
    return data;
  }

  static async updateOwnUserInfo(updateObj: any): Promise<UserInfo> {
    const { data } = await axios.patch<UserInfo>(`/users/me`, updateObj);
    return data;
  }

  static async getUserInfoByEmail(email: string): Promise<UserInfo> {
    const query = stringify({
      email,
    });

    const { data } = await axios.get<UserInfo>(
      `${USERS_ROUTE}/by-email?${query}`
    );
    return data;
  }

  static async getUserInfoById(userId: string): Promise<UserInfo> {
    const dayString = DateUtils.getDbDateTag(new Date());

    const { data } = await axios.get<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${userId}?day=${dayString}`
    );

    return data;
  }

  static async getSetPasswordToken(
    userId: string
  ): Promise<{ id: string; set_password_token: string }> {
    const { data } = await axios.get<{
      id: string;
      set_password_token: string;
    }>(`${ADMIN_USERS_ROUTE}/${userId}/set-password-token`);

    return data;
  }

  static async getDemoAccountPassword(
    userId: string
  ): Promise<{ demo_account_password: string }> {
    const { data } = await axios.get<{
      demo_account_password: string;
    }>(`${ADMIN_USERS_ROUTE}/${userId}/demo-account-password`);

    return data;
  }

  static async deleteDemoAccountPassword(userId: string): Promise<UserInfo> {
    const { data } = await axios.patch<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${userId}/demo-accounts/delete-password`
    );

    return data;
  }

  static async refreshSetPasswordToken(userId: string): Promise<UserInfo> {
    const { data } = await axios.patch<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${userId}/refresh-set-password-token`
    );

    return data;
  }

  static async activateScanningPlanForUser(
    id: string,
    activateScanningPlanRequest: ActivateScanningPlanRequest
  ): Promise<UserInfo> {
    const { data } = await axios.post<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${id}/scanning-plans/activate`,
      activateScanningPlanRequest
    );

    return data;
  }

  static async cancelActiveScanningPlanForUser(
    id: string,
    cancelActiveScanningPlanRequest: CancelActiveScanningPlanRequest
  ): Promise<UserInfo> {
    const { data } = await axios.patch<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${id}/active-scanning-plan/cancel`,
      cancelActiveScanningPlanRequest
    );

    return data;
  }

  static async updateUserInfo(userId: string, updateObj: any): Promise<void> {
    await axios.patch<UserInfo>(`${ADMIN_USERS_ROUTE}/${userId}`, updateObj);
  }

  static async getUserTypeahead(searchText: string): Promise<UserInfo[]> {
    const query = stringify({
      search_text: searchText.toUpperCase().split(' ').join(','),
      limit: 10,
    });

    const { data } = await axios.get<Paginated<UserInfo>>(
      `${ADMIN_USERS_ROUTE}?${query}`
    );

    return data.docs;
  }

  static async loadUsersByQuery(query: string) {
    const { data } = await axios.get<Paginated<UserInfo>>(
      `${ADMIN_USERS_ROUTE}?${query}`
    );

    return data;
  }

  static async setPasswordForDemoAccount(
    id: string,
    setPassword: SetDemoAccountPasswordRequest
  ) {
    const { data } = await axios.patch<UserInfo>(
      `${ADMIN_USERS_ROUTE}/${id}/demo-accounts/set-password`,
      setPassword
    );

    return data;
  }

  static async deleteUserById(userId: string) {
    return await axios.delete(`${ADMIN_USERS_ROUTE}/${userId}`);
  }

  static async deactivateUserById(userId: string) {
    return await axios.patch(`${ADMIN_USERS_ROUTE}/${userId}/deactivate`);
  }

  static async loadAllFertilityDisorders() {
    const { data } = await axios.get<
      {
        known_reproductive_disorder: string;
      }[]
    >(`${USERS_ROUTE}/disorders`);

    return data;
  }

  static async linkFullScriptPatientIdToUser(
    userId: string,
    fsPatientId: string
  ): Promise<void> {
    await axios.patch<UserInfo>(`${USERS_ROUTE}/${userId}/fullscript`, {
      fullscript_patient_id: fsPatientId,
    });
  }

  static async unlinkFullScriptPatientIdToUser(userId: string): Promise<void> {
    await axios.delete(`${USERS_ROUTE}/${userId}/fullscript`);
  }
}

export default UserService;
