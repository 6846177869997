import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from '../../services/auth.service';
import { SignInAttempt } from '../../common/model/dto/sign-in-attempt';
import { UserCredential } from 'firebase/auth';
import { clearCurrentUser } from '../reducers/user.slice';
import { History, Location } from 'history';
import UserService from '../../services/user.service';
import { UserInfo } from '../../common/model/dto/user-info';
import { Role } from '../../common/model/type/role.enum';
import i18n from '../../i18n/config';
import { ApprovementStatus } from '../../common/model/dto/approvement-status.enum';
import { Constants } from '../../common/constants';

const login = createAsyncThunk(
  'auth/login',
  async (
    {
      signInData,
      location,
    }: {
      signInData: SignInAttempt;
      location: Location<{ from: string }>;
    },
    { rejectWithValue }
  ) => {
    try {
      sessionStorage.setItem(
        Constants.SESSION_STORAGE_KEYS.LOCATION_BEFORE_AUTHENTICATION,
        location.state?.from
      );
      const response: UserCredential = await AuthService.login(signInData);

      const loggedInUser = await UserService.getUserInfo();

      if (!isUserAllowedToLogin(loggedInUser)) {
        throw new Error(i18n.t('login.youAreNotAllowedToLogin'));
      }

      return response.user.uid;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await AuthService.logout();
      dispatch(clearCurrentUser());
      sessionStorage.removeItem(
        Constants.SESSION_STORAGE_KEYS.LOCATION_BEFORE_AUTHENTICATION
      );

      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getEntitlements = createAsyncThunk(
  'auth/entitlements',
  async (_, { rejectWithValue }) => {
    try {
      return AuthService.getEntitlements();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setPassword = createAsyncThunk(
  'auth/setPassword',
  async (
    {
      history,
      token,
      password,
      confirmPassword,
    }: {
      history: History;
      token: string;
      password: string;
      confirmPassword: string;
    },
    { rejectWithValue }
  ) => {
    try {
      await AuthService.setPassword(token, password, confirmPassword);

      history.push('/login');
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function isUserAllowedToLogin(user: UserInfo) {
  return (
    user.roles.includes(Role.Admin) ||
    user.provider_clinic_locations.filter(
      (clinicLocationProvider) =>
        clinicLocationProvider.status === ApprovementStatus.ACCEPTED
    ).length > 0
  );
}

export { logout, login, getEntitlements, setPassword };
