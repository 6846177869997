import React, { FC, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import OvButton from '../atoms/OvButton';
import Variables from '../../../design-system/variables';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../../firebase/config';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const OvImageUploadDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  title: string;
  imageBasePath: string;
  onUploadSuccess?: (value: any) => void;
  previewImageStyles?: React.CSSProperties;
}> = ({
  isOpen,
  onCancel,
  title,
  onUploadSuccess,
  imageBasePath,
  previewImageStyles,
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>();

  const onDrop = useCallback((acceptedFiles: any) => {
    handleFileChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'image/*': [] },
      onDrop,
    });

  useEffect(() => {
    setFile(undefined);
    setErrorMessage(undefined);
  }, [isOpen]);

  useEffect(() => {
    let objectUrl: string;
    if (file) {
      objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleFileChange = (files: File[]) => {
    if (files) {
      setFile(files[0]);
    }
  };

  const handleUpload = async (e: any) => {
    e.preventDefault();

    if (!file) {
      setErrorMessage('Please choose a file first');
    }

    setIsLoading(true);

    const storageRef = ref(
      storage,
      `${imageBasePath}/${new Date().getTime()}-${file?.name}`
    );

    const uploadTask = uploadBytesResumable(storageRef, file, {
      contentType: 'image/png',
    });

    uploadTask.on(
      'state_changed',
      () => {},
      (err) => {
        setErrorMessage(err.message);
        setIsLoading(false);
      },
      async () => {
        const imagePath = await getDownloadURL(uploadTask.snapshot.ref);
        setIsLoading(false);
        onCancel();

        if (onUploadSuccess) {
          onUploadSuccess({ image_path: imagePath });
        }
      }
    );
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogContent>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <form onSubmit={handleUpload}>
          <StyledContainer>
            {file ? (
              <StyledImage
                style={previewImageStyles}
                src={preview}
                alt="Preview"
              />
            ) : (
              <DropzoneContainer
                {...getRootProps({ isFocused, isDragAccept, isDragReject })}
              >
                <input {...getInputProps()} />
                <p>{t('imageUpload.dragNDropText')}</p>
              </DropzoneContainer>
            )}

            <StyledSubmitButton type="submit" disabled={!file}>
              {t('imageUpload.uploadImageButtonLabel')}
            </StyledSubmitButton>

            {errorMessage && (
              <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
            )}
            {isLoading && <OvLoadingIndicator position={'fixed'} />}
          </StyledContainer>
        </form>
      </StyledDialogContent>
    </Dialog>
  );
};

export default OvImageUploadDialog;

const StyledDialogTitle = styled.h2`
  color: ${Colours.OV_BASE};
`;

const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;
  border-radius: ${Variables.borderRadius.MEDIUM};
  background-color: ${Colours.OV_LIGHT_GRAY};
  color: ${Colours.OV_BASE};
  outline: none;
  cursor: pointer;
`;

const StyledDialogContent = styled(DialogContent)`
  &&  {
    text-align: center;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSubmitButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.XLARGE};
    padding: 0.5rem 0.5rem;
    background-color: ${Colours.OV_BASE};
    margin: 2rem 0;
    width: 100%;

    &:hover {
      a {
        color: ${Colours.OV_BASE};
      }
    }

    &:disabled {
      background-color: ${Colours.OV_GRAY};
      color: ${Colours.OV_LIGHT_GRAY};

      a {
        color: ${Colours.OV_LIGHT_GRAY};
      }
    }

    a {
      margin: 0 0.75rem;
      text-decoration: none;
      text-transform: none;
      font-weight: bold;
      font-size: 0.75rem;
      color: ${Colours.OV_WHITE};
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 0.625rem 0;
`;

const StyledImage = styled.img`
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  margin-bottom: 0.25rem;
  overflow: hidden;
  object-fit: cover;
`;
