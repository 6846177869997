import { last } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { ClinicLocationDetailsTabType } from '../../common/model/type/clinic-location-details-tab.type';
import Colours from '../../design-system/colours';
import Variables from '../../design-system/variables';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearSelectedClinicLocation } from '../../redux/reducers/admin-clinic-location.slice';
import {
  deleteClinicLocation,
  getClinicLocationById,
  updateClinicLocation,
} from '../../redux/thunks/admin/admin-clinic-location.thunk';
import OvButton from '../UI/atoms/OvButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClinicLocationInfo from './ClinicLocationInfo';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import FilterableClinicLocationProvidersList from './FilterableClinicLocationProvidersList';
import FilterableClinicLocationPatientsList from './FilterableClinicLocationPatientsList';

const ClinicLocationDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { clinicId, clinicLocationId }: any = useParams();
  const { path, url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState<ClinicLocationDetailsTabType>(
    ClinicLocationDetailsTabType.CLINIC_LOCATION_INFO
  );
  const [isOpenDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);
  const selectedClinicLocation = useAppSelector(
    (state) => state.adminClinicLocation.selectedClinicLocation
  );
  const isLoading = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );
  const clinicLocationStoredQuery = useAppSelector(
    (state) => state.adminClinicLocation.clinicLocationStoredQuery
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const changeTab = (event: any, newValue: ClinicLocationDetailsTabType) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(
        location.pathname.split('/')
      ) as ClinicLocationDetailsTabType;
      const { CLINIC_LOCATION_INFO, PATIENTS, PROVIDERS } =
        ClinicLocationDetailsTabType;

      if ([CLINIC_LOCATION_INFO, PATIENTS, PROVIDERS].includes(pathName)) {
        setActiveTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [dispatch, selectedClinicLocation, location.pathname]);

  useEffect(() => {
    dispatch(clearSelectedClinicLocation());
    dispatch(getClinicLocationById(clinicLocationId));
  }, [dispatch, clinicLocationId]);

  const onBackClicked = () => {
    if (clinicLocationStoredQuery) {
      history.push(
        `/clinics/${clinicId}/clinic-locations${clinicLocationStoredQuery}`
      );
    } else {
      history.push(`/clinics/${clinicId}/clinic-locations`);
    }
  };

  const handleDeleteClinic = () => {
    setOpenDeleteConfirmationDialog(false);

    if (selectedClinicLocation?.id) {
      dispatch(
        deleteClinicLocation({
          history,
          clinicLocationId: selectedClinicLocation.id,
          clinicId,
        })
      );
    }
  };

  const handleClinicLocationUpdate = (updatedProperties: any) => {
    if (selectedClinicLocation.id) {
      const updateObj = {
        clinicLocationId: selectedClinicLocation.id,
        updatedProperties: {
          ...updatedProperties,
        },
      };

      dispatch(updateClinicLocation(updateObj));
    }
  };

  return (
    <>
      <DetailPageWrapper>
        <DetailsHeader>
          <DetailsWrapper>
            <BackCta onClick={onBackClicked}>
              <StyledArrowBackIcon />
              {t('common.actions.back')}
            </BackCta>

            <ClinicLocationName>
              {selectedClinicLocation?.name}
            </ClinicLocationName>
          </DetailsWrapper>

          <CtaWrapper>
            <StyledOvButton
              onClick={() => setOpenDeleteConfirmationDialog(true)}
            >
              {t('common.actions.delete')}
            </StyledOvButton>
          </CtaWrapper>
        </DetailsHeader>

        {selectedClinicLocation?.is_demo_clinic_location && (
          <StyledAlert severity="warning">
            <AlertTitle>{t('clinicLocationDetails.demoAlertTitle')}</AlertTitle>
            {t('clinicLocationDetails.demoAlertDesc')}
          </StyledAlert>
        )}

        <TabContainer>
          <Tabs value={activeTab} onChange={changeTab}>
            <Tab
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
              value="clinic-location-info"
              label={t('clinicLocationDetails.tabs.clinicSiteInfo')}
              component={Link}
              to={`${url}/clinic-location-info`}
            />

            <Tab
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
              value="patients"
              label={t('clinicLocationDetails.tabs.patients')}
              component={Link}
              to={`${url}/patients`}
            />

            <Tab
              style={{
                borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                textTransform: 'none',
              }}
              value="providers"
              label={t('clinicLocationDetails.tabs.providers')}
              component={Link}
              to={`${url}/providers`}
            />
          </Tabs>
        </TabContainer>

        <Switch>
          <Route exact path={path}>
            <Redirect to={`${url}/clinic-location-info`} />
          </Route>
          <Route path={`${path}/clinic-location-info`}>
            <ClinicLocationInfo
              selectedClinicLocation={selectedClinicLocation}
              handleClinicLocationUpdate={handleClinicLocationUpdate}
              isLoading={isLoading}
              canEdit={true}
            />
          </Route>
          <Route path={`${path}/providers`}>
            <FilterableClinicLocationProvidersList
              selectedClinicLocation={selectedClinicLocation}
              clinicId={clinicId}
            />
          </Route>
          <Route path={`${path}/patients`}>
            <FilterableClinicLocationPatientsList
              selectedClinicLocation={selectedClinicLocation}
              clinicId={clinicId}
            />
          </Route>
        </Switch>
      </DetailPageWrapper>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteConfirmationDialog}
        onCancel={() => setOpenDeleteConfirmationDialog(false)}
        onConfirm={handleDeleteClinic}
        title="clinicLocationDetails.actions.deleteTitle"
        description="clinicLocationDetails.actions.deleteDescription"
      />

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default ClinicLocationDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const DetailsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ClinicLocationName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 16px;
    border-radius: ${Variables.borderRadius.XLARGE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 4px;
  height: 20px;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
