import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import OvTable from '../molecules/OvTable';
import Colours from '../../../design-system/colours';
import { JournalEntry } from '../../../common/model/dto/journal-entry';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';

const OvJournalEntryDetailPanel: FC<{
  journalEntry?: JournalEntry;
  onSaveEditedJournalField?: (value: any) => void;
}> = ({ journalEntry, onSaveEditedJournalField }) => {
  const { t } = useTranslation();
  const overviewGroups = onSaveEditedJournalField
    ? DailyDataUtils.getOverviewGroups(journalEntry)
    : DailyDataUtils.getReadonlyOverviewGroups(journalEntry);

  return (
    <OverviewContainer>
      {journalEntry ? (
        overviewGroups.map((group) => (
          <InfoGroup key={group.title}>
            <GroupHeader>
              <GroupTitle>{t(group.title)}</GroupTitle>
            </GroupHeader>

            <OvTable
              data={group.rows}
              onSaveRowValue={onSaveEditedJournalField}
            />
          </InfoGroup>
        ))
      ) : (
        <NoContentWrapper>
          {t('dailyDataDetails.journalEntry.noContent')}
        </NoContentWrapper>
      )}
    </OverviewContainer>
  );
};

export default OvJournalEntryDetailPanel;

const OverviewContainer = styled.section`
  padding: 12px;
`;

const NoContentWrapper = styled.section`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const InfoGroup = styled.article`
  padding-bottom: 40px;
  margin-bottom: 24px;
  border-bottom: 1px dotted ${Colours.OV_GRAY};

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const GroupHeader = styled.header`
  margin: 0 0 24px 12px;
  display: flex;
  align-items: center;
`;

const GroupTitle = styled.h6`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
