import React, { FC } from 'react';
import styled from 'styled-components';
import Variables from '../../../design-system/variables';
import { OrderBy, SortDirection } from '../../../common/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton } from '@mui/material';
import Colours from '../../../design-system/colours';

export interface OvListTableHeaderFieldProps {
  style?: any;
  sortDirection?: SortDirection;
  sortBy?: string;
  onSort?: (order: OrderBy) => void;
  children?: React.ReactNode;
}

const OvListTableHeaderField: FC<OvListTableHeaderFieldProps> = ({
  style,
  sortDirection,
  sortBy,
  onSort,
  children,
  ...props
}) => {
  const isSorted = sortDirection === 'asc' || sortDirection === 'desc';

  const handleSort = () => {
    if (sortBy && onSort) {
      let order_dir: SortDirection;

      if (sortDirection === 'asc') {
        order_dir = 'desc';
      } else {
        order_dir = 'asc';
      }

      const orderBy: OrderBy = {
        order_by: sortBy,
        order_dir,
      };

      onSort(orderBy);
    }
  };

  return (
    <>
      {sortBy ? (
        <StyledSortableTableField onClick={handleSort} style={style} {...props}>
          {children}
          {isSorted && (
            <StyledIconButton>
              {sortDirection === 'asc' ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </StyledIconButton>
          )}
        </StyledSortableTableField>
      ) : (
        <StyledTableField style={style} {...props}>
          {children}
        </StyledTableField>
      )}
    </>
  );
};

export default OvListTableHeaderField;

const StyledTableField = styled.td`
  font-weight: 500;
  font-size: ${Variables.fontSizes.MEDIUM};
  line-height: 1rem;
  letter-spacing: 0.13px;
  padding: 0 1rem 0.375rem;
  vertical-align: middle;
  opacity: 0.7;
`;

const StyledSortableTableField = styled(StyledTableField)`
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    height: 1rem;
    width: 1rem;
    padding: 0.25rem;
    margin-left: 0.75rem;
    vertical-align: bottom;

    &:hover {
      background: none;

      .MuiSvgIcon-root {
        fill: ${Colours.OV_BASE_HOVER};
      }
    }

    .MuiTouchRipple-root {
      display: none !important;
    }
  }
`;
