import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { LotType } from '../../../common/model/type/lot.type';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OvSelect from '../atoms/OvSelect';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import { useFormik } from 'formik';
import { CreateScanRequest } from '../../../common/model/dto/create-scan-request';
import * as Yup from 'yup';
import breakpoints from '../../../design-system/breakpoints';
import OvTextField from '../atoms/OvTextField';
import Colours from '../../../design-system/colours';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Lot } from '../../../common/model/dto/lot';
import { getLots } from '../../../redux/thunks/lots.thunk';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { clearLotsList } from '../../../redux/reducers/lots.slice';

const OvCreateScanDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: any) => void;
  selectedUserId: string;
  selectedDay: string;
}> = ({ isOpen, onCancel, selectedUserId, selectedDay, onSave }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const scanTypeList: { label: string; value: LotType }[] = [
    {
      value: LotType.E3G,
      label: t('common.estrogen'),
    },
    {
      value: LotType.CTRL_PG_LH,
      label: `${t('common.progesterone')} - ${t('common.luteinizing')}`,
    },
    {
      value: LotType.CTRL_LH_PG,
      label: `${t('common.luteinizing')} - ${t('common.progesterone')}`,
    },
  ];
  const steps = [
    t('scans.dialogs.steps.chooseScanType'),
    t('scans.dialogs.steps.insertValues'),
  ];
  const [selectedValue, setSelectedValue] = useState<LotType>(
    scanTypeList[0].value
  );
  const dispatch = useAppDispatch();
  const lotList: Lot[] = useAppSelector((state) => state.lots.lotList);
  const isLoading: boolean = useAppSelector((state) => state.lots.loading);

  const formik = useFormik<CreateScanRequest>({
    initialValues: {
      user_id: selectedUserId,
      day: selectedDay,
      lr_e3g: undefined,
      lh: undefined,
      pg: undefined,
      lot_id: 'not-selected',
    },
    onSubmit: (createScanRequest) => {
      onSave(createScanRequest);
    },
    validationSchema: Yup.object().shape({
      user_id: Yup.string().required(),
      lot_id: Yup.string().required().not(['not-selected']),
      lh:
        selectedValue === LotType.CTRL_PG_LH
          ? Yup.number().min(0).required(t('scans.lhRequired'))
          : Yup.number().notRequired(),
      pg:
        selectedValue === LotType.CTRL_PG_LH
          ? Yup.number().min(0).required(t('scans.pgRequired'))
          : Yup.number().notRequired(),
      lr_e3g:
        selectedValue === 'E3G'
          ? Yup.number().min(0).required(t('scans.e3gRequired'))
          : Yup.number().notRequired(),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setActiveStep(0);
      setSelectedValue(scanTypeList[0].value);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleChange = (event: any, newValue?: any) => {
    const value = event?.target ? event.target.value : newValue;
    setSelectedValue(value);
  };

  const handleNextClick = () => {
    setActiveStep(1);
    dispatch(getLots({ limit: 0, lotType: selectedValue }));
  };

  const handleBackClick = () => {
    formik.resetForm();
    dispatch(clearLotsList);
    setActiveStep(0);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>{t('scans.createScan')}</StyledDialogTitle>

      <StyledStepper activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps}>
              <StyledStepLabel>{label}</StyledStepLabel>
            </Step>
          );
        })}
      </StyledStepper>

      {activeStep === 0 && (
        <form>
          <StyledDialogContent>
            <StyledOvSelect value={selectedValue} onChange={handleChange}>
              {scanTypeList &&
                scanTypeList.map((type) => (
                  <StyledMenuItem key={type.value} value={type.value}>
                    {type.label}
                  </StyledMenuItem>
                ))}
            </StyledOvSelect>
            <StyledDialogActions>
              <StyledLightOvButton onClick={onCancel}>
                {t('common.actions.cancel')}
              </StyledLightOvButton>
              <StyledOvButton onClick={handleNextClick}>
                {t('common.actions.next')}
              </StyledOvButton>
            </StyledDialogActions>
          </StyledDialogContent>
        </form>
      )}

      {activeStep === 1 && (
        <form onSubmit={formik.handleSubmit}>
          <StyledDialogContent>
            {selectedValue === LotType.CTRL_PG_LH ||
            selectedValue === LotType.CTRL_LH_PG ? (
              <>
                <StyledTextField
                  type="number"
                  fullWidth
                  autoComplete="off"
                  label="LH"
                  disabled={isLoading}
                  error={formik.touched.lh && !!formik.errors.lh}
                  helperText={
                    formik.errors.lh && formik.touched.lh && formik.errors.lh
                  }
                  {...formik.getFieldProps('lh')}
                />
                <StyledTextField
                  type="number"
                  fullWidth
                  autoComplete="off"
                  label="PG"
                  disabled={isLoading}
                  error={formik.touched.pg && !!formik.errors.pg}
                  helperText={
                    formik.errors.pg && formik.touched.pg && formik.errors.pg
                  }
                  {...formik.getFieldProps('pg')}
                />
              </>
            ) : (
              <StyledTextField
                type="number"
                fullWidth
                autoComplete="off"
                label="E3G"
                disabled={isLoading}
                error={formik.touched.lr_e3g && !!formik.errors.lr_e3g}
                helperText={
                  formik.errors.lr_e3g &&
                  formik.touched.lr_e3g &&
                  formik.errors.lr_e3g
                }
                {...formik.getFieldProps('lr_e3g')}
              />
            )}
            {lotList && lotList.length > 0 && (
              <StyledOvSelect
                error={formik.touched.lot_id && !!formik.errors.lot_id}
                {...formik.getFieldProps('lot_id')}
              >
                <StyledMenuItem value={'not-selected'}>
                  <em>{t('common.actions.notSelected')}</em>
                </StyledMenuItem>
                {lotList.map((lot) => (
                  <StyledMenuItem key={lot.id} value={lot.id}>
                    {`${lot?.type} - ${
                      lot?.name ?? lot?.document_id ? lot?.document_id : lot.id
                    }`}
                  </StyledMenuItem>
                ))}
              </StyledOvSelect>
            )}
            <StyledDialogActions>
              <StyledLightOvButton onClick={handleBackClick}>
                {t('common.actions.back')}
              </StyledLightOvButton>
              <StyledOvButton type="submit" disabled={isLoading}>
                {t('scans.createScan')}
              </StyledOvButton>
            </StyledDialogActions>
          </StyledDialogContent>
        </form>
      )}
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvCreateScanDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    width: 100%;
    align-self: center;
    max-width: 100%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledTextField = styled(OvTextField)`
  && {
    flex: 1 0 100%;

    .MuiFormHelperText-root.Mui-error {
      background: white;
      margin: 0;
      padding: 0.25rem 0.75rem;
    }
    .MuiInputLabel-formControl {
      top: -0.2rem;
      opacity: 0.6;
    }
  }
`;

const StyledStepper = styled(Stepper)`
  && {
    padding: 1rem 3rem;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  && {
    .Mui-active {
      color: ${Colours.OV_BASE};
    }

    .Mui-completed {
      color: ${Colours.OV_GREEN};
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;
