import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { ScanSequence } from '../../common/model/dto/scan-sequences/scan-sequence';
import { ListPaging } from '../../common/types';
import {
  createScanSequence,
  loadScanSequences,
  restoreScanSequenceSearchFromUrl,
} from '../../redux/thunks/admin/admin-scan-sequences.thunk';
import {
  clearScanSequenceList,
  setScanSequenceListPaging,
  setScanSequenceListStoredQuery,
} from '../../redux/reducers/admin-scan-sequence.slice';
import { History } from 'history';
import { AdminScanSequencesService } from '../../services/admin/admin-scan-sequences.service';
import styled from 'styled-components';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Colours from '../../design-system/colours';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvScanSequencesList from '../UI/organisms/OvScanSequencesList';
import OvButton from '../UI/atoms/OvButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Variables from '../../design-system/variables';
import OvCreateScanSequenceDialog from '../UI/molecules/OvCreateScanSequenceDialog';
import { CreateScanSequenceRequest } from '../../common/model/dto/scan-sequences/create-scan-sequence-request.dto';

const ScanSequencesList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const [page, setPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const history: History = useHistory();
  const scanSequenceList: ScanSequence[] = useAppSelector(
    (state) => state.adminScanSequence.scanSequenceList
  );
  const scanSequenceListPaging: ListPaging = useAppSelector(
    (state) => state.adminScanSequence.scanSequenceListPaging
  );
  const scanSequenceListStoredQuery: string = useAppSelector(
    (state) => state.adminScanSequence.scanSequenceListStoredQuery
  );
  const error = useAppSelector((state) => state.adminScanSequence.error);
  const isLoading: boolean = useAppSelector(
    (state) => state.adminScanSequence.isLoading
  );

  useEffect(() => {
    if (search && search !== `?${scanSequenceListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(restoreScanSequenceSearchFromUrl(search));
    } else if (!scanSequenceListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(loadScanSequences(history));
    } else if (!search && scanSequenceListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setScanSequenceListPaging({
          offset: 0,
          total: scanSequenceListPaging.total,
        })
      );
      dispatch(loadScanSequences(history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(setScanSequenceListStoredQuery(''));
      dispatch(clearScanSequenceList());
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(
      scanSequenceListPaging.offset /
        AdminScanSequencesService.SCAN_SEQUENCE_LIST_LIMIT +
        1
    );
  }, [scanSequenceListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setScanSequenceListPaging({
        offset:
          (value - 1) * AdminScanSequencesService.SCAN_SEQUENCE_LIST_LIMIT,
        total: scanSequenceListPaging.total,
      })
    );

    dispatch(loadScanSequences(history));
  };

  const pageCount: () => number = () =>
    Math.ceil(
      scanSequenceListPaging?.total /
        AdminScanSequencesService.SCAN_SEQUENCE_LIST_LIMIT
    );

  const handleOnSave = (
    createScanSequenceRequest: CreateScanSequenceRequest
  ) => {
    dispatch(createScanSequence({ createScanSequenceRequest }));
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <CenterPane>
        <Actions>
          {pageCount() > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
          <StyledOvButton onClick={() => setIsDialogOpen(true)}>
            <AddCircleIcon style={{ marginRight: '5' }} />
            {t('scanSequenceDetails.createScanSequence')}
          </StyledOvButton>
        </Actions>
        {error ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {error}
          </StyledErrorMessage>
        ) : (
          ''
        )}
        {scanSequenceList?.length ? (
          <OvScanSequencesList scanSequences={scanSequenceList} />
        ) : (
          ''
        )}
        {!scanSequenceList?.length && !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      <OvCreateScanSequenceDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
      />
    </Container>
  );
};

export default ScanSequencesList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.125rem 0.7rem 0.125rem 0.35rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
