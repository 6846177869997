import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { Constants } from '../../constants';
import DateUtils from './date-utils';
import { Scan } from '../../model/dto/scan';
import { isNumber } from 'lodash';
import { DailyDataUtils } from './daily-data-utils';
import MathUtils from './math-utils';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import { ScanFields } from '../../../firebase/document-field.enums';

export const scanProcessingToolkitErrorCodes = {
  101: 'The first line in the image is not RED enough',
  102: 'The strength (intensity difference) of the first line is not enough',
  103: 'Did not find the right position of the ellipse model - it will be placed in the center',
  104: 'The ellipse model is out of the image - it will be placed in the center',
};

export const scanProcessingToolkitWarningCodes = {
  201: 'The sampling background is colorful (mainly the image is too blue or yellow)',
  202: 'Some part of the sampling background is colorful',
  203: `The intensity of the second or third line is higher than the control line's`,
  204: `The first line has uneven coloring`,
  205: `The second line has uneven coloring`,
  206: `The third line has uneven coloring`,
  207: `The first line has uneven coloring at full length`,
  208: `The second line has uneven coloring at full length`,
  209: `The third line has uneven coloring at full length`,
  210: `Not proper image width / image height rate (need to be 3:2)`,
};

export const scanErrorDisplay = (errorCode?: number) =>
  Object.keys(scanProcessingToolkitErrorCodes).includes(`${errorCode}` || '')
    ? `${errorCode}: ${
        // @ts-ignore
        scanProcessingToolkitErrorCodes[errorCode]
      }`
    : '-';

export const scanWarningDisplay = (warningCode?: number) =>
  Object.keys({
    ...scanProcessingToolkitWarningCodes,
    ...scanProcessingToolkitErrorCodes,
  }).includes(`${warningCode}` || '')
    ? `${warningCode}: ${
        {
          ...scanProcessingToolkitWarningCodes,
          ...scanProcessingToolkitErrorCodes,
          // @ts-ignore
        }[warningCode]
      }`
    : '-';

export const scanWarningsDisplay = (warningCodes?: number[], separator = ',') =>
  (warningCodes?.length ?? 0) > 0
    ? `[${warningCodes
        ?.map((warningCode) => {
          return Object.keys(scanProcessingToolkitWarningCodes).includes(
            `${warningCode}` || ''
          )
            ? `${warningCode}: ${
                // @ts-ignore
                scanProcessingToolkitWarningCodes[warningCode]
              }`
            : '';
        })
        .join(separator)}]`
    : '-';

export class ScanDataUtils {
  static mapDataForScanResultTable(scan: Scan): OvTableData {
    let hormones: OvTableData = [];

    if (isNumber(scan?.lh) || isNumber(scan?.pg)) {
      hormones = [
        {
          label: 'common.dailyData.lh',
          translateLabel: true,
          value: isNumber(scan?.lh)
            ? MathUtils.normalizeValue(
                scan?.lh,
                Constants.LH_MIN,
                Constants.LH_MAX
              ).toFixed(2)
            : null,
          prettifiedValue: isNumber(scan?.lh)
            ? DailyDataUtils.prettifyValue(
                scan?.lh,
                Constants.LH_MAX,
                Constants.LH_UNIT_OF_MEASURE
              )
            : null,
          editOptions: {
            widgetType: TableRowEditWidgetType.NUMBER,
            propertyName: ScanFields.lh,
            min: Constants.LH_MIN,
            max: Constants.LH_MAX,
            step: 0.01,
          },
        },
        {
          label: 'common.dailyData.pg',
          translateLabel: true,
          value: isNumber(scan?.pg)
            ? MathUtils.normalizeValue(
                scan?.pg,
                Constants.PG_MIN,
                Constants.PG_MAX
              ).toFixed(2)
            : null,
          prettifiedValue: isNumber(scan?.pg)
            ? DailyDataUtils.prettifyValue(
                scan?.pg,
                Constants.PG_MAX,
                Constants.PG_UNIT_OF_MEASURE
              )
            : null,
          editOptions: {
            widgetType: TableRowEditWidgetType.NUMBER,
            propertyName: ScanFields.pg,
            min: Constants.PG_MIN,
            max: Constants.PG_MAX,
            step: 0.01,
          },
        },
      ];
    }

    if (isNumber(scan?.lr_e3g)) {
      hormones = [
        {
          label: 'common.dailyData.e3g',
          translateLabel: true,
          value: isNumber(scan?.lr_e3g)
            ? MathUtils.normalizeValue(
                scan?.lr_e3g,
                Constants.LR_E3G_MIN,
                Constants.LR_E3G_MAX
              ).toFixed(2)
            : null,
          prettifiedValue: isNumber(scan?.lr_e3g)
            ? DailyDataUtils.prettifyValue(
                scan?.lr_e3g,
                Constants.LR_E3G_MAX,
                Constants.E3G_UNIT_OF_MEASURE
              )
            : null,
          editOptions: {
            widgetType: TableRowEditWidgetType.NUMBER,
            propertyName: ScanFields.lr_e3g,
            min: Constants.LR_E3G_MIN,
            max: Constants.LR_E3G_MAX,
            step: 0.01,
          },
        },
      ];
    }

    return [
      ...hormones,
      {
        label: 'lots.fields.lot',
        translateLabel: true,
        value: scan.lot?.name
          ? scan?.lot?.name
          : scan?.lot?.document_id ?? scan?.lot?.id,
        linkUrl: `/lots/${scan.lot?.document_id ?? scan.lot?.id}`,
        linkShouldNotRedirect: true,
      },
      {
        label: 'common.scanDetails.results.firstLineIdx',
        translateLabel: true,
        value: scan.toolkit_output?.first_line_index,
      },
      {
        label: 'common.scanDetails.results.firstLineStr',
        translateLabel: true,
        value: scan.toolkit_output?.first_line_strength,
      },
      {
        label: 'common.scanDetails.results.secondLineIdx',
        translateLabel: true,
        value: scan.toolkit_output?.second_line_index,
      },
      {
        label: 'common.scanDetails.results.secondLineStr',
        translateLabel: true,
        value: scan.toolkit_output?.second_line_strength,
      },
      {
        label: 'common.scanDetails.results.thirdLineIdx',
        translateLabel: true,
        value: scan.toolkit_output?.third_line_index,
      },
      {
        label: 'common.scanDetails.results.thirdLineStr',
        translateLabel: true,
        value: scan.toolkit_output?.third_line_strength,
      },
      {
        label: 'common.scanDetails.results.toolkitVersion',
        translateLabel: true,
        value: scan.toolkit_output?.version,
      },
      {
        label: 'common.scanDetails.results.error',
        translateLabel: true,
        value: scanErrorDisplay(scan.toolkit_output?.error),
      },
      {
        label: 'common.scanDetails.results.warning',
        translateLabel: true,
        value: scanWarningDisplay(scan.toolkit_output?.warning),
      },
      {
        label: 'common.scanDetails.results.warnings',
        translateLabel: true,
        value: scanWarningsDisplay(scan.toolkit_output?.warnings),
      },
      {
        label: 'common.scanDetails.meta.appVersion',
        translateLabel: true,
        value: scan.meta?.app_version,
      },
      {
        label: 'common.scanDetails.meta.countOfScanTrials',
        translateLabel: true,
        value: scan.meta?.count_of_scan_trials,
      },
      {
        label: 'common.scanDetails.meta.deviceModel',
        translateLabel: true,
        value: scan.meta?.device_model,
      },
      {
        label: 'common.scanDetails.meta.os',
        translateLabel: true,
        value: scan.meta?.os,
      },
      {
        label: 'common.scanDetails.meta.osVersion',
        translateLabel: true,
        value: scan.meta?.os_version,
      },
      {
        label: 'common.scanDetails.meta.secondsWaitedOnTimerScreen',
        translateLabel: true,
        value: scan.meta?.seconds_waited_on_timer_screen,
      },
      {
        label: 'common.scanDetails.meta.timezoneAbbreviation',
        translateLabel: true,
        value: scan.meta?.time_zone_abbreviation,
      },
      {
        label: 'common.scanDetails.meta.timezoneIdentifier',
        translateLabel: true,
        value: scan.meta?.time_zone_identifier,
      },
      {
        label: 'common.scanDetails.meta.focusDistance',
        translateLabel: true,
        value: scan.meta?.focus_distance,
      },
      {
        label: 'common.scanDetails.meta.cameraZoom',
        translateLabel: true,
        value: scan.meta?.camera_zoom,
      },
      {
        label: 'common.scanDetails.results.userHealthGoal',
        translateLabel: true,
        value: scan.health_goal,
      },
      {
        label: 'common.scanDetails.results.userScanningPlanType',
        translateLabel: true,
        value: scan.scanning_plan_type,
      },
      {
        label: 'common.modifiedAt',
        translateLabel: true,
        value: scan?.updated_at
          ? DateUtils.formatDate(
              scan?.updated_at as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : scan?.updated_at,
      },
      {
        label: 'common.createdAt',
        translateLabel: true,
        value: scan?.created_at
          ? DateUtils.formatDate(
              scan?.created_at as Date,
              'MMMM DD, YYYY, HH:mm:ss'
            )
          : scan?.created_at,
      },
    ];
  }
}
