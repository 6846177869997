import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isNumber } from 'lodash';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ClinicLocationPatient } from '../../common/model/dto/clinic-location-patient';
import { UserInfo } from '../../common/model/dto/user-info';
import { ClinicRole } from '../../common/model/type/clinic-role';
import { FilterModel } from '../../common/model/ui/filter.model';
import { FilterType } from '../../common/model/ui/filter.type';
import { ListPaging } from '../../common/types';
import { ClinicLocationUtils } from '../../common/utils/services/clinic-location-utils';
import {
  DocumentFields,
  UserInfoFields,
} from '../../firebase/document-field.enums';
import i18n from '../../i18n/config';
import ReducerUtils from '../reducer.utils';
import { AdminClinicLocationState } from '../states/admin-clinic-location.state';
import {
  approvePatientInvitationForUser,
  approveProviderInvitationForUser,
  assignPatientToClinicLocation,
  assignProviderToClinicLocation,
  createClinicLocation,
  createDemoAccount,
  createDemoClinicLocation,
  deleteClinicLocation,
  getApproveInvitationToken,
  getClinicLocationById,
  getSetPasswordToken,
  loadAllFertilityDisorders,
  loadClinicLocationPatients,
  loadClinicLocationProviders,
  loadClinicLocationProvidersFilters,
  loadClinicLocations,
  refreshApproveInvitationToken,
  refreshSetPasswordToken,
  removePatientFromClinicLocation,
  removeProviderFromClinicLocation,
  updateClinicLocation,
} from '../thunks/admin/admin-clinic-location.thunk';
import { ClinicLocationProvider } from '../../common/model/dto/clinic-location-provider';

const initialState: AdminClinicLocationState = {
  clinicLocationList: [],
  clinicLocationListPaging: {
    total: 0,
    offset: 0,
  },
  sortingOptions: [],
  filters: [],
  selectedClinicLocation: null,
  clinicLocationStoredQuery: '',
  isLoading: false,
  showFilters: false,
  userList: [],
  providerListFilters: [
    {
      fieldName: UserInfoFields.clinic_role,
      label: 'common.userFields.clinicRole',
      type: FilterType.select,
      options: [
        {
          label: 'common.clinicLocations.clinicRoleValues.physician',
          value: ClinicRole.Physician,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.nursePractitioner',
          value: ClinicRole.NursePractitioner,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.nurse',
          value: ClinicRole.Nurse,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.medicalAssistant',
          value: ClinicRole.MedicalAssistant,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.administrative',
          value: ClinicRole.Administrative,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.dietitian',
          value: ClinicRole.Dietitian,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.acupuncturist',
          value: ClinicRole.Acupuncturist,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.healthCoach',
          value: ClinicRole.HealthCoach,
          translate: true,
        },
        {
          label: 'common.clinicLocations.clinicRoleValues.doctor',
          value: ClinicRole.Doctor,
          translate: true,
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.should_hide_not_registered_users,
      label: 'common.userSearchFilters.shouldHideNotRegisteredUsers',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
  ],
  userListSortingOptions: [
    {
      value: DocumentFields.id,
      label: 'common.userFields.userId',
      direction: 'asc',
      multiDir: false,
    },
    {
      value: UserInfoFields.first_name,
      label: 'common.userFields.firstName',
      direction: '',
      multiDir: true,
    },
    {
      value: UserInfoFields.last_name,
      label: 'common.userFields.lastName',
      direction: '',
      multiDir: true,
    },
  ],
  patientListFilters: [
    {
      fieldName: UserInfoFields.provider_id,
      label: 'common.userFields.provider',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: UserInfoFields.should_hide_not_registered_users,
      label: 'common.userSearchFilters.shouldHideNotRegisteredUsers',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.date_of_birth,
      label: 'common.userFields.age',
      type: FilterType.slider,
      value: [0, 99],
      min: 0,
      max: 99,
      convertTo: 'age',
    },
    {
      fieldName: UserInfoFields.is_pregnant,
      label: 'common.userSearchFilters.isPregnant',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.known_reproductive_disorder,
      label: 'common.userFields.fertilityDisorder',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: UserInfoFields.is_taking_birth_control,
      label: 'common.userSearchFilters.isTakingBirthControl',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.length_of_average_monthly_cycle,
      label: 'common.userFields.averageCycleLength',
      type: FilterType.slider,
      value: [0, 40],
      min: 0,
      max: 40,
      convertTo: 'number',
    },
  ],
  showUserListFilters: true,
  userListPaging: {
    total: 0,
    offset: 0,
  },
  areFertilityDisordersLoading: false,
  areProvidersLoading: false,
  error: '',
  currentApproveInvitationToken: '',
  currentSetPasswordToken: '',
};

const adminClinicLocationSlice = createSlice({
  name: 'adminClinicLocation',
  initialState,
  reducers: {
    setClinicLocationListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.clinicLocationListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.clinicLocationListPaging.offset = action.payload.offset;
      }
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.sortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.sortingOptions
      );
      state.clinicLocationListPaging.offset = action.payload.offset;
    },
    setClinicLocationListStoredQuery: (
      state,
      action: PayloadAction<string>
    ) => {
      state.clinicLocationStoredQuery = action.payload;
    },
    clearClinicLocationList: (state) => {
      state.clinicLocationList = [];
    },
    clearSelectedClinicLocation: (state) => {
      state.selectedClinicLocation = null;
    },
    setUserListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.userListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.userListPaging.offset = action.payload.offset;
      }
    },
    clearUserList: (state) => {
      state.userList = [];
    },
    setUserListStoredQuery: (state, action: PayloadAction<string>) => {
      state.userListStoredQuery = action.payload;
    },
    toggleFilterVisibility: (state) => {
      state.showFilters = !state.showFilters;
    },
    clearProviderFilters: (state) => {
      state.providerListFilters = state.providerListFilters.map(
        (filter, index) => {
          return {
            ...filter,
            value: initialState.providerListFilters[index].value,
            disabled: false,
          };
        }
      );
      state.userListStoredQuery = '';
      state.userListPaging.offset = 0;
    },
    clearPatientFilters: (state) => {
      state.patientListFilters = state.patientListFilters.map(
        (filter, index) => {
          return {
            ...filter,
            value: initialState.patientListFilters[index].value,
            disabled: false,
          };
        }
      );
      state.userListStoredQuery = '';
      state.userListPaging.offset = 0;
    },
    restoreProviderSearchModel: (state, action: PayloadAction<any>) => {
      state.providerListFilters = ReducerUtils.restoreFilters(
        action.payload,
        state.providerListFilters
      );
      state.userListSortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.userListSortingOptions
      );
      state.userListPaging.offset = action.payload.offset;
    },
    restorePatientSearchModel: (state, action: PayloadAction<any>) => {
      state.patientListFilters = ReducerUtils.restoreFilters(
        action.payload,
        state.patientListFilters
      );
      state.userListSortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.userListSortingOptions
      );
      state.userListPaging.offset = action.payload.offset;
    },
    changeProviderListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.providerListFilters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      let parsedValue = action.payload?.value;

      if (action.payload?.value === 'true') {
        parsedValue = true;
      } else if (action.payload?.value === 'false') {
        parsedValue = false;
      }

      state.providerListFilters[index].value = parsedValue;
      state.userListPaging.offset = 0;
    },
    changePatientListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.patientListFilters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      let parsedValue = action.payload?.value;

      if (action.payload?.value === 'true') {
        parsedValue = true;
      } else if (action.payload?.value === 'false') {
        parsedValue = false;
      }

      state.patientListFilters[index].value = parsedValue;
      state.userListPaging.offset = 0;
    },
    changeUserListSorting: (state, action: PayloadAction<UserInfoFields>) => {
      state.userListSortingOptions = state.userListSortingOptions.map(
        (option) => {
          if (option.value === action.payload) {
            if (option.multiDir) {
              option.direction = option.direction === 'asc' ? 'desc' : 'asc';
            } else {
              option.direction = 'asc';
            }
          } else {
            option.direction = '';
          }

          return option;
        }
      );

      state.userListPaging.offset = 0;
    },
    clearCurrentApproveInvitationToken: (state) => {
      state.currentApproveInvitationToken = '';
    },
    clearCurrentSetPasswordToken: (state) => {
      state.currentSetPasswordToken = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadClinicLocations.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        loadClinicLocations.fulfilled,
        (state, action: PayloadAction<ClinicLocation[]>) => {
          state.clinicLocationList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadClinicLocationProviders.pending, (state) => {
        state.isLoading = true;
        state.userList = [];
        state.error = '';
      })
      .addCase(
        loadClinicLocationProviders.fulfilled,
        (state, action: PayloadAction<UserInfo[]>) => {
          state.userList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadClinicLocationPatients.pending, (state) => {
        state.isLoading = true;
        state.userList = [];
        state.error = '';
      })
      .addCase(
        loadClinicLocationPatients.fulfilled,
        (state, action: PayloadAction<UserInfo[]>) => {
          state.userList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getClinicLocationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getClinicLocationById.fulfilled,
        (state, action: PayloadAction<ClinicLocation>) => {
          state.selectedClinicLocation = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getSetPasswordToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getSetPasswordToken.fulfilled,
        (
          state,
          action: PayloadAction<{ id: string; set_password_token: string }>
        ) => {
          state.currentSetPasswordToken = action.payload.set_password_token;
          state.isLoading = false;
        }
      )
      .addCase(getSetPasswordToken.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(refreshSetPasswordToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        refreshSetPasswordToken.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.userList = state.userList.map((user) => {
            if (user.id === action.payload.id) {
              return {
                ...user,
                ...action.payload,
              };
            }

            return user;
          });
          state.isLoading = false;
        }
      )
      .addCase(refreshSetPasswordToken.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(refreshApproveInvitationToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        refreshApproveInvitationToken.fulfilled,
        (state, action: PayloadAction<ClinicLocationProvider>) => {
          state.userList = state.userList.map((user) => {
            if (user.id === action.payload.user.id) {
              return {
                ...user,
                provider_clinic_locations: [
                  action.payload,
                  ...user.provider_clinic_locations,
                ],
              };
            }

            return user;
          });
          state.isLoading = false;
        }
      )
      .addCase(refreshApproveInvitationToken.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getApproveInvitationToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getApproveInvitationToken.fulfilled,
        (
          state,
          action: PayloadAction<{ approve_invitation_token: string }>
        ) => {
          state.currentApproveInvitationToken =
            action.payload.approve_invitation_token;
          state.isLoading = false;
        }
      )
      .addCase(getApproveInvitationToken.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createDemoClinicLocation.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        createDemoClinicLocation.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.isLoading = false;
        }
      )
      .addCase(
        createDemoClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createDemoAccount.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        createDemoAccount.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.isLoading = false;
        }
      )
      .addCase(
        createDemoAccount.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateClinicLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateClinicLocation.fulfilled,
        (state, action: PayloadAction<ClinicLocation>) => {
          state.selectedClinicLocation = {
            ...state.selectedClinicLocation,
            ...action.payload,
          };
          state.clinicLocationList = state.clinicLocationList.map(
            (clinicLocation) => {
              if (clinicLocation.id === action.payload.id) {
                return {
                  ...clinicLocation,
                  ...action.payload,
                };
              }

              return clinicLocation;
            }
          );

          state.isLoading = false;
        }
      )
      .addCase(updateClinicLocation.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(createClinicLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        createClinicLocation.fulfilled,
        (state, action: PayloadAction<ClinicLocation>) => {
          state.isLoading = false;
          state.clinicLocationList = [
            action.payload,
            ...state.clinicLocationList,
          ];
        }
      )
      .addCase(createClinicLocation.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteClinicLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteClinicLocation.fulfilled, (state) => {
        state.clinicLocationList = [];
        state.isLoading = false;
      })
      .addCase(deleteClinicLocation.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(assignPatientToClinicLocation.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        assignPatientToClinicLocation.fulfilled,
        (state, action: PayloadAction<ClinicLocationPatient>) => {
          state.isLoading = false;
          if (state.userList) {
            state.userList = [action.payload.user, ...state.userList];
          } else {
            state.userList = [action.payload.user];
          }
        }
      )
      .addCase(
        assignPatientToClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          const responseData = action.payload.response?.data as any;

          state.error = ClinicLocationUtils.isDuplicateKeyError(
            responseData?.message
          )
            ? i18n.t('clinicLocationDetails.errors.patientAlreadyInvited')
            : action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(assignProviderToClinicLocation.pending, (state) => {
        state.isLoading = true;
        state.error = '';
      })
      .addCase(
        assignProviderToClinicLocation.fulfilled,
        (state, action: PayloadAction<ClinicLocationPatient>) => {
          state.isLoading = false;
          if (state.userList) {
            state.userList = [action.payload.user, ...state.userList];
          } else {
            state.userList = [action.payload.user];
          }
        }
      )
      .addCase(
        assignProviderToClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          const responseData = action.payload.response?.data as any;

          state.error = ClinicLocationUtils.isDuplicateKeyError(
            responseData?.message
          )
            ? i18n.t('clinicLocationDetails.errors.providerAlreadyInvited')
            : action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(removePatientFromClinicLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removePatientFromClinicLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(
        removePatientFromClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(removeProviderFromClinicLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeProviderFromClinicLocation.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(
        removeProviderFromClinicLocation.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(approveProviderInvitationForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveProviderInvitationForUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(
        approveProviderInvitationForUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(approvePatientInvitationForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approvePatientInvitationForUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(
        approvePatientInvitationForUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadAllFertilityDisorders.pending, (state) => {
        state.areFertilityDisordersLoading = true;
      })
      .addCase(
        loadAllFertilityDisorders.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.areFertilityDisordersLoading = false;
          const fertilityDisorderFilterIdx = state.patientListFilters.findIndex(
            (filter) =>
              filter.fieldName === UserInfoFields.known_reproductive_disorder
          );
          state.patientListFilters[fertilityDisorderFilterIdx].options = [];
          state.patientListFilters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.none'),
            value: 'None',
          });

          action.payload?.forEach((disorder) => {
            state.patientListFilters[fertilityDisorderFilterIdx]?.options?.push(
              {
                label: disorder,
                value: disorder,
              }
            );
          });

          state.patientListFilters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.other'),
            value: 'Other',
          });
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadClinicLocationProvidersFilters.pending, (state) => {
        state.areProvidersLoading = true;
      })
      .addCase(
        loadClinicLocationProvidersFilters.fulfilled,
        (
          state,
          action: PayloadAction<
            { first_name: string; last_name: string; id: string }[]
          >
        ) => {
          state.areProvidersLoading = false;
          const fertilityDisorderFilterIdx = state.patientListFilters.findIndex(
            (filter) => filter.fieldName === UserInfoFields.provider_id
          );

          action.payload?.forEach((provider) => {
            state.patientListFilters[fertilityDisorderFilterIdx]?.options?.push(
              {
                label: `${provider.first_name} ${provider.last_name}`,
                value: provider.id,
              }
            );
          });
        }
      );
  },
});

export const {
  setClinicLocationListPaging,
  restoreSearchModel,
  setClinicLocationListStoredQuery,
  clearClinicLocationList,
  clearSelectedClinicLocation,
  setUserListPaging,
  clearUserList,
  setUserListStoredQuery,
  toggleFilterVisibility,
  clearProviderFilters,
  clearPatientFilters,
  restoreProviderSearchModel,
  changeProviderListFilter,
  changeUserListSorting,
  restorePatientSearchModel,
  changePatientListFilter,
  clearCurrentApproveInvitationToken,
  clearCurrentSetPasswordToken,
} = adminClinicLocationSlice.actions;
export default adminClinicLocationSlice.reducer;
