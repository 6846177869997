import React, { FC, useState } from 'react';
import styled from 'styled-components';
import breakpoints from '../../design-system/breakpoints';
import Variables from '../../design-system/variables';
import { useTranslation } from 'react-i18next';
import OvBackButton from '../UI/atoms/OvBackButton';
import OvAddressControl from '../UI/molecules/OvAddressControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Colours from '../../design-system/colours';
import { Address } from '../../common/model/dto/address';
import OvOrderGeneralInfoControl, {
  OrderGeneralInfo,
} from '../UI/molecules/OvOrderGeneralInfoControl';
import { OrderItem } from '../../common/model/dto/order/order-item';
import OvEditableOrderItems from '../UI/molecules/OvEditableOrderItems';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OrderStatus } from '../../common/model/type/order-status.enum';
import { OrderPaymentStatus } from '../../common/model/type/order-payment-status.enum';
import { FulfillmentStatus } from '../../common/model/type/fulfillment-status.enum';
import OvSelect from '../UI/atoms/OvSelect';
import { MenuItem } from '@mui/material';
import OvButton from '../UI/atoms/OvButton';
import OrdersService from '../../services/orders.service';
import { useHistory } from 'react-router-dom';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';

const OrderForm: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [sameAsShipping, setSameAsShipping] = useState<boolean>(true);
  const statusForm = useFormik<{
    status: OrderStatus;
    payment_status: OrderPaymentStatus;
    fulfillment_status: FulfillmentStatus;
  }>({
    initialValues: {
      status: OrderStatus.Completed,
      payment_status: OrderPaymentStatus.Complete,
      fulfillment_status: FulfillmentStatus.Fulfilled,
    },
    enableReinitialize: true,
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      status: Yup.string(),
      payment_status: Yup.string(),
      fulfillment_status: Yup.string(),
    }),
  });
  const [generalInfo, setGeneralInfo] = useState<{
    values?: OrderGeneralInfo;
    isValid: boolean;
  }>({ values: undefined, isValid: false });
  const [shippingAddress, setShippingAddress] = useState<{
    values?: Address;
    isValid: boolean;
  }>({ values: undefined, isValid: false });
  const [billingAddress, setBillingAddress] = useState<{
    values?: Address;
    isValid: boolean;
  }>({ values: undefined, isValid: false });
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCopyAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameAsShipping(event.target.checked);
  };

  const handleBillingAddressChange = (address: Address, isValid: boolean) => {
    setBillingAddress({
      values: address,
      isValid,
    });
  };

  const handleShippingAddressChange = (address: Address, isValid: boolean) => {
    setShippingAddress({
      values: address,
      isValid,
    });

    if (sameAsShipping) {
      handleBillingAddressChange(address, isValid);
    }
  };

  const handleGeneralInfoChange = (
    orderGeneralInfo: OrderGeneralInfo,
    isValid: boolean
  ) => {
    setGeneralInfo({
      values: orderGeneralInfo,
      isValid,
    });
  };

  const handleItemChanges = (items: OrderItem[]) => {
    setOrderItems(items);
  };

  const createManualOrder = async () => {
    if (
      shippingAddress.values &&
      billingAddress.values &&
      generalInfo.values &&
      statusForm.values
    ) {
      try {
        setLoading(true);
        const shipping = shippingAddress.values as Address;
        const billing = billingAddress.values as Address;
        const general = generalInfo.values;
        const orderedAt: Date = general.ordered_at;

        const order = await OrdersService.createManualOrder({
          is_subscription: general.is_subscription,
          user_email: general.user_email,
          channel: general.channel,
          ordered_at: new Date(
            Date.UTC(
              orderedAt.getFullYear(),
              orderedAt.getMonth(),
              orderedAt.getDate()
            )
          ),
          delivery_method: general.delivery_method,
          note: general.note,
          payment_status: statusForm.values.payment_status,
          fulfillment_status: statusForm.values.fulfillment_status,
          status: statusForm.values.status,
          shipping_address: shipping,
          billing_address: billing,
          items: orderItems,
          is_submitted: false,
        });

        setLoading(false);
        history.push(`/orders/${order.id}`);
      } catch (error: any) {
        setLoading(false);
        setErrorMessages(error?.response?.data?.message);
      }
    }
  };

  return (
    <Wrapper>
      <Header>
        <StretchedDiv>
          <OvBackButton label={t('common.actions.back')} />
        </StretchedDiv>

        <PageTitle>
          <StyledOrderIcon />
          {t('orders.create')}
        </PageTitle>

        <StretchedDiv />
      </Header>

      <DisclaimerText>
        <InfoIcon />
        {t('orders.details.manualCreationDisclaimer')}
      </DisclaimerText>

      <InfoBar>
        <StyledForm>
          <InlineFormControl>
            {t('orders.details.tooltip.orderStatus')}
            <StyledSelect
              disabled
              autoComplete="off"
              label={t('orders.details.tooltip.orderStatus')}
              {...statusForm.getFieldProps('status')}
              error={statusForm.touched.status && !!statusForm.errors.status}
            >
              <StyledMenuItem
                key={OrderStatus.Completed}
                value={OrderStatus.Completed}
              >
                {OrderStatus.Completed}
              </StyledMenuItem>
            </StyledSelect>
          </InlineFormControl>
          <span>&#9679;</span>
          <InlineFormControl>
            {t('orders.details.tooltip.paymentStatus')}
            <StyledSelect
              disabled
              autoComplete="off"
              label={t('orders.details.tooltip.paymentStatus')}
              {...statusForm.getFieldProps('payment_status')}
              error={
                statusForm.touched.payment_status &&
                !!statusForm.errors.payment_status
              }
            >
              <StyledMenuItem
                key={OrderPaymentStatus.Complete}
                value={OrderPaymentStatus.Complete}
              >
                {OrderPaymentStatus.Complete}
              </StyledMenuItem>
            </StyledSelect>
          </InlineFormControl>
        </StyledForm>

        <StyledOvButton
          disabled={!orderItems.length}
          onClick={createManualOrder}
        >
          <StyledOrderIcon />
          {t('orders.create')}
        </StyledOvButton>
      </InfoBar>

      {errorMessages?.length ? (
        <InfoBar>
          {errorMessages.map((errorMessage) => (
            <OvErrorMessage message={errorMessage} />
          ))}
        </InfoBar>
      ) : (
        ''
      )}

      <Content>
        <ContentGroup>
          <SectionTitle>{t('orders.details.generalInfo')}</SectionTitle>

          <OvOrderGeneralInfoControl
            onChangeGeneralInfo={handleGeneralInfoChange}
          />
        </ContentGroup>

        <ContentGroup>
          <SectionTitle>{t('orders.details.shippingInfo')}</SectionTitle>
          <OvAddressControl onChangeAddress={handleShippingAddressChange} />
          <HorizontalSeparator />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={sameAsShipping}
                onChange={handleCopyAddress}
                name="sameAsShipping"
              />
            }
            label={t('orders.details.sameAsShipping')}
          />
        </ContentGroup>

        {!sameAsShipping && (
          <ContentGroup>
            <SectionTitle>{t('orders.details.billingInfo')}</SectionTitle>
            <OvAddressControl onChangeAddress={handleBillingAddressChange} />
          </ContentGroup>
        )}

        <ContentGroup>
          <SectionTitle>{t('orders.details.items')}</SectionTitle>

          <OvEditableOrderItems
            items={orderItems}
            onChange={handleItemChanges}
          />
        </ContentGroup>
      </Content>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Wrapper>
  );
};

export default OrderForm;

const Wrapper = styled.div`
  margin-left: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 1rem;
  }
`;

const Box = styled.div`
  box-shadow: ${Variables.boxShadow.defaultBox};
  padding: 0.75rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const Header = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.25rem;
  margin-bottom: 1.5rem;
  align-items: center;
`;

const DisclaimerText = styled.p`
  font-size: ${Variables.fontSizes.MEDIUM};
  color: ${Colours.GRAY};
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin: 1rem 0 1rem 1rem;
`;

const PageTitle = styled.h3`
  margin: 0;
  text-align: center;
  font-size: ${Variables.fontSizes.LARGE};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledOrderIcon = styled(AddShoppingCartIcon)`
  && {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
`;

const StretchedDiv = styled.div`
  flex-grow: 1;
`;

const Content = styled.section`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

const InfoBar = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  align-items: center;
`;

const ContentGroup = styled(Box)`
  flex-grow: 1;
  flex-basis: 100%;

  @media (min-width: ${breakpoints.md}) {
    flex-grow: 1;
    flex-basis: calc(50% - 1.5rem);
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-grow: 1;
    flex-basis: calc(25% - 2.25rem);
  }
`;

const SectionTitle = styled.h4`
  margin: 0 0 1rem 0;
  text-align: center;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    padding-bottom: 0.75rem;
    width: 100%;
    margin-left: 0;
  }
`;

const HorizontalSeparator = styled.hr`
  height: 1px;
  background: ${Colours.GRAY};
  width: 100%;
  margin: 1.5rem 0;
`;

const StyledSelect = styled(OvSelect)`
  && {
    width: 100%;
    padding: 0.75rem 0.875rem;

    &.MuiInputBase-root {
      border: inherit;
    }

    .MuiSelect-select {
      padding: 0;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const InlineFormControl = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  gap: 0.75rem;
  white-space: nowrap;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    background-color: ${Colours.OV_BASE};
    margin-left: auto;
    font-size: ${Variables.fontSizes.LARGE};
  }
`;
