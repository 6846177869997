import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { History } from 'history';
import { ListPaging } from '../../common/types';
import {
  approveProviderInvitationForUser,
  assignProviderToClinicLocation,
  loadClinicLocationProviders,
  removeProviderFromClinicLocation,
  restoreProviderSearchFromUrl,
} from '../../redux/thunks/admin/admin-clinic-location.thunk';
import {
  clearUserList,
  setUserListPaging,
  setUserListStoredQuery,
} from '../../redux/reducers/admin-clinic-location.slice';
import { AdminClinicLocationService } from '../../services/admin/admin-clinic-location.service';
import styled from 'styled-components';
import OvPagination from '../UI/atoms/OvPagination';
import { PaginationItem } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import OvClinicLocationUserList from '../UI/organisms/OvClinicLocationUserList';
import { UserInfo } from '../../common/model/dto/user-info';
import OvNoContent from '../UI/molecules/OvNoContent';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvClinicLocationProviderSearchFilter from '../UI/organisms/OvClinicLocationProviderSearchFilter';
import breakpoints from '../../design-system/breakpoints';
import OvButton from '../UI/atoms/OvButton';
import Variables from '../../design-system/variables';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AssignProviderToClinicLocationRequest } from '../../common/model/dto/assign-provider-to-clinic-location-request';
import OvAssignProviderToClinicLocationDialog from '../UI/molecules/OvAssignProviderToClinicLocationDialog';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Colours from '../../design-system/colours';

const FilterableClinicLocationProvidersList: FC<{
  selectedClinicLocation: ClinicLocation;
  clinicId: string;
}> = ({ selectedClinicLocation, clinicId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const providerList: UserInfo[] = useAppSelector(
    (state) => state.adminClinicLocation.userList
  );
  const userListPaging: ListPaging = useAppSelector(
    (state) => state.adminClinicLocation.userListPaging
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.adminClinicLocation.isLoading
  );
  const userListStoredQuery: string = useAppSelector(
    (state) => state.adminClinicLocation.userListStoredQuery
  );
  const error = useAppSelector((state) => state.adminClinicLocation.error);

  useEffect(() => {
    if (selectedClinicLocation && clinicId) {
      if (search && search !== `?${userListStoredQuery}`) {
        // load from url (cases: reloading whole page, navigating with the browser history buttons)
        dispatch(
          restoreProviderSearchFromUrl({
            clinicLocationId: selectedClinicLocation.id,
            query: search,
          })
        );
      } else if (!userListStoredQuery) {
        // the simplest load. There is no stored url query string, and nothing in the url
        dispatch(
          loadClinicLocationProviders({
            history,
            clinicLocationId: selectedClinicLocation.id,
            clinicId,
          })
        );
      } else if (!search && userListStoredQuery) {
        // jump back to the first page with side nav
        dispatch(
          setUserListPaging({
            offset: 0,
            total: userListPaging.total,
          })
        );
        dispatch(
          loadClinicLocationProviders({
            history,
            clinicLocationId: selectedClinicLocation.id,
            clinicId,
          })
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history, clinicId, selectedClinicLocation]);

  useEffect(() => {
    return () => {
      dispatch(setUserListStoredQuery(''));
      dispatch(clearUserList());
      dispatch(
        setUserListPaging({
          total: 0,
          offset: 0,
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    setPage(
      userListPaging.offset /
        AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT +
        1
    );
  }, [userListPaging.offset]);

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setUserListPaging({
        offset:
          (value - 1) *
          AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT,
        total: userListPaging.total,
      })
    );

    dispatch(
      loadClinicLocationProviders({
        history,
        clinicLocationId: selectedClinicLocation.id,
        clinicId,
      })
    );
  };

  const pageCount: () => number = () =>
    Math.ceil(
      userListPaging?.total /
        AdminClinicLocationService.CLINIC_LOCATION_USER_LIST_LIMIT
    );

  const handleOnSave = (request: AssignProviderToClinicLocationRequest) => {
    if (selectedClinicLocation?.id) {
      dispatch(
        assignProviderToClinicLocation({
          history,
          clinicLocationId: selectedClinicLocation.id,
          clinicId,
          request,
        })
      );
    }
    setIsDialogOpen(false);
  };

  return (
    <Container>
      <CenterPane>
        <UserActions>
          {pageCount() > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount()}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}

          <ButtonWrapper>
            <StyledOvButton onClick={() => setIsDialogOpen(true)}>
              <AddCircleIcon style={{ marginRight: '5' }} />
              {t('clinicLocations.actions.addNewProvider')}
            </StyledOvButton>
          </ButtonWrapper>
        </UserActions>
        {error ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {error}
          </StyledErrorMessage>
        ) : (
          ''
        )}

        {providerList?.length ? (
          <OvClinicLocationUserList
            users={providerList}
            clinicLocationId={selectedClinicLocation?.id}
            clinicId={clinicId}
            deleteAction={removeProviderFromClinicLocation}
            deleteActionCtaLabel={t('clinicLocationDetails.actions.removeUser')}
            showClinicRole={true}
            approveInvitationAction={approveProviderInvitationForUser}
            approveInvitationCtaLabel={t(
              'clinicLocationDetails.actions.acceptInvitation'
            )}
          />
        ) : (
          ''
        )}

        {!providerList?.length && !isLoading ? (
          <OvNoContent>
            {t('clinicLocationDetails.emptyList.providers')}
          </OvNoContent>
        ) : null}
        {isLoading && <OvLoadingIndicator position="fixed" />}
      </CenterPane>

      {selectedClinicLocation && (
        <OvClinicLocationProviderSearchFilter
          clinicId={clinicId}
          clinicLocationId={selectedClinicLocation.id ?? ''}
        />
      )}

      <OvAssignProviderToClinicLocationDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
        title={t('clinicLocationDialogs.assignProviderTitle')}
      />
    </Container>
  );
};

export default FilterableClinicLocationProvidersList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem 2px 0.25rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    background-color: ${Colours.OV_BASE};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;
