import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { OvTableRowData } from '../molecules/OvTable';
import { isArray } from 'lodash';
import StringUtils from '../../../common/utils/services/string-utils';
import styled from 'styled-components';
import Colours from '../../../design-system/colours';
import { useTranslation } from 'react-i18next';
import OvListDrillDown from '../molecules/OvListDrillDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import Variables from '../../../design-system/variables';
import OvButton from './OvButton';
import OvTextButton from './OvTextButton';
import OvTableRowEditWidget from '../molecules/OvTableRowEditWidget';
import OvConfirmationDialog from '../molecules/OvConfirmationDialog';

const OvTableRow: FC<{
  row: OvTableRowData;
  onSave?: (field: any) => any;
  toggleNestedRows?: () => any;
  hasNestedRows?: boolean;
  cursor?: string;
  backgroundColor?: string;
  icon: any;
  theme?: 'Light' | 'Dark';
  getIdFromRowData?: boolean;
}> = ({
  row,
  onSave,
  toggleNestedRows,
  hasNestedRows,
  cursor,
  backgroundColor,
  icon,
  theme,
  getIdFromRowData,
}) => {
  const { t } = useTranslation();
  const [isEditingMode, setEditingMode] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState<any>(row.value);
  const [isOpenConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  useEffect(() => {
    setValue(row.value);
  }, [row]);

  function isEmptyValue(value: any): boolean {
    return StringUtils.displayValue(value) === '-';
  }

  const onResetValue = () => {
    setEditingMode(false);
    if (row.editOptions?.propertyName && onSave) {
      onSave({
        [row.editOptions.propertyName]: null,
      });
    }
  };

  const onCancelEditing = (e: any) => {
    e.stopPropagation();
    setEditingMode(false);
    setValue(row.value);
    setError('');
  };

  const onSaveEditing = async (e: any) => {
    e.stopPropagation();
    if (row.editOptions?.propertyName && row.editOptions?.validate) {
      const isValid = await row.editOptions.validate(
        row.editOptions.prefixedString
          ? `${row.editOptions.prefixedString.prefix}-${value}`
          : value
      );

      if (!isValid && row.editOptions.validationErrorMsg) {
        setError(row.editOptions.validationErrorMsg);
        return;
      }
    }

    setEditingMode(false);

    if (getIdFromRowData && row.editOptions?.propertyName && onSave) {
      setValue(row.value);
      onSave({
        id: row.rowId,
        updatedProperties: {
          [row.editOptions.propertyName]: value,
        },
      });
    } else if (row.editOptions?.propertyName && onSave) {
      setValue(row.value);
      onSave({
        [row.editOptions.propertyName]: value,
      });
    }
  };

  const onChange = (value: any) => {
    setValue(value);
  };

  const openConfirmDeletionModal = (event: SyntheticEvent) => {
    event.stopPropagation();
    setOpenConfirmationDialog(true);
  };

  const onDelete = () => {
    setOpenConfirmationDialog(false);

    if (row.deleteOptions?.deleteAction) {
      row.deleteOptions?.deleteAction();
    }
  };

  const onCreate = (event: SyntheticEvent) => {
    event.stopPropagation();

    if (row.createOptions?.createAction) {
      row.createOptions?.createAction();
    }
  };

  const displayValue = () => {
    if (row.translateValue) {
      return t(value || '');
    }

    if (row.prettifiedValue) {
      return row.prettifiedValue;
    }

    if (row.linkUrl) {
      return (
        <a
          href={row.linkUrl}
          rel="noreferrer"
          target={row.linkShouldNotRedirect ? '' : '_blank'}
        >
          {row.value}
        </a>
      );
    }

    if (row.imageUrl) {
      return <StyledImage loading="lazy" src={row.imageUrl} alt={row.label} />;
    }

    if (row.displayAsHTML) {
      return <div dangerouslySetInnerHTML={{ __html: value }}></div>;
    }

    return value;
  };

  return (
    <>
      <Row
        key={row.label}
        onClick={toggleNestedRows}
        style={{
          opacity: isEmptyValue(row.value) && !isEditingMode ? 0.5 : 1,
          cursor: cursor || 'normal',
          borderBottom: hasNestedRows ? `0.375rem solid` : '1px solid',
          backgroundColor,
          borderColor:
            theme === 'Dark'
              ? Colours.OV_GRAY
              : hasNestedRows
              ? Colours.WHITE
              : '#ebedf0',
          color: theme === 'Dark' ? Colours.OV_SEMI_LIGHT : Colours.OV_BASE,
        }}
      >
        <LabelField
          style={{
            borderTopLeftRadius: hasNestedRows ? '0.5rem' : 0,
            borderBottomLeftRadius: hasNestedRows ? '0.5rem' : 0,
            verticalAlign: isEditingMode ? 'middle' : 'top',
          }}
        >
          {row?.translateLabel ? t(row.label || '') : row.label}
        </LabelField>
        <LabelField
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            fontWeight: 'normal',
            verticalAlign: isEditingMode ? 'middle' : 'top',
          }}
        >
          {row?.secondaryValue}
        </LabelField>
        <ValueField
          style={{
            borderTopRightRadius: hasNestedRows ? '0.5rem' : 0,
            borderBottomRightRadius: hasNestedRows ? '0.5rem' : 0,
          }}
        >
          <ValueContainer>
            {isEditingMode && row.editOptions ? (
              <EditModeContainer>
                <OvTableRowEditWidget
                  editOptions={{
                    ...row.editOptions,
                    value,
                  }}
                  onChange={onChange}
                  error={error}
                  setError={setError}
                />

                <CtaContainer>
                  {row?.editOptions?.canReset && (
                    <StyledIconButton
                      onClick={onResetValue}
                      style={{ display: 'flex' }}
                    >
                      <HighlightOffIcon />
                    </StyledIconButton>
                  )}

                  <StyledTextButton onClick={onCancelEditing}>
                    {t('common.actions.cancel')}
                  </StyledTextButton>

                  <StyledButton disabled={!!error} onClick={onSaveEditing}>
                    {t('common.actions.save')}
                  </StyledButton>
                </CtaContainer>
              </EditModeContainer>
            ) : (
              <ReadOnlyModeContainer>
                {!isArray(value) || row.prettifiedValue ? (
                  StringUtils.displayValue(displayValue())
                ) : (
                  <OvListDrillDown value={value} />
                )}

                <ActionContainer>
                  {row?.editOptions && (
                    <StyledIconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setEditingMode(true);
                      }}
                      className="actionIcon"
                    >
                      <EditIcon />
                    </StyledIconButton>
                  )}

                  {row?.deleteOptions && (
                    <StyledIconButton
                      onClick={(event) => openConfirmDeletionModal(event)}
                      className="actionIcon"
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  )}

                  {row?.createOptions && (
                    <StyledActionButton
                      onClick={(event) => onCreate(event)}
                      className="actionCta"
                      disabled={row.createOptions.disabled}
                    >
                      {row.createOptions.icon}
                    </StyledActionButton>
                  )}
                </ActionContainer>
              </ReadOnlyModeContainer>
            )}

            {icon}
          </ValueContainer>
        </ValueField>
      </Row>
      {row.deleteOptions && (
        <OvConfirmationDialog
          icon={<StyledDeleteIcon />}
          isOpen={isOpenConfirmationDialog}
          onCancel={() => setOpenConfirmationDialog(false)}
          onConfirm={onDelete}
          description={
            row.deleteOptions?.confirmationOptions?.description || ''
          }
          title={row.deleteOptions?.confirmationOptions?.title || ''}
        />
      )}
    </>
  );
};

export default OvTableRow;

const Row = styled.tr`
  display: table-row;
  vertical-align: middle;
  outline: 0px;

  &:last-child {
    border-bottom: 0;
  }
`;

const LabelField = styled.th`
  text-align: left;
  padding: 0.875rem 0.5rem;
  vertical-align: top;
  font-size: ${Variables.fontSizes.MEDIUM};
`;

const ValueField = styled.td`
  text-align: left;
  padding: 0.875rem 0.25rem;
  word-break: break-word;
  vertical-align: middle;
  width: 50%;
  font-size: ${Variables.fontSizes.MEDIUM};

  &:hover {
    .actionIcon {
      display: flex;
      visibility: visible;
      max-height: 25px;
    }
  }
`;

const ValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 1.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const StyledIconButton = styled(IconButton)`
  && {
    color: ${Colours.OV_BASE};
    visibility: hidden;
    height: 24px;
    width: 24px;
    margin-left: 24px;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    &.MuiButtonBase-root {
      padding: 6px;
      border-radius: 50%;
    }
  }
`;

const EditModeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-radius: ${Variables.borderRadius.SMALL};
  background-color: ${Colours.OV_LIGHT_GRAY};
`;

const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;

const ReadOnlyModeContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(OvButton)`
  && {
    padding: 0;
    min-width: 64px;
    text-align: center;
    border-radius: ${Variables.borderRadius.XLARGE};
    font-size: 12px;
  }
`;

const StyledTextButton = styled(OvTextButton)`
  && {
    padding: 0;
    min-width: 64px;
    text-align: center;
    font-size: 12px;
    margin: 0 12px;
  }
`;

const StyledActionButton = styled(IconButton)`
  && {
    height: 24px;
    padding: 1px 0.5rem;
    text-align: center;
    font-size: 12px;
    margin: 0 12px;
    border-radius: ${Variables.borderRadius.XLARGE};
    white-space: nowrap;
    text-transform: none;

    .MuiSvgIcon-root {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background-color: ${Colours.OV_WHITE_HOVER};
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const StyledImage = styled.img`
  max-width: 8rem;
  border-radius: 0.75rem;
  object-fit: cover;
  position: relative;
`;
