import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Configuration } from '../../common/model/dto/configuration';
import {
  getLatestConfiguration,
  updateConfiguration,
} from '../../redux/thunks/admin/admin-configuration.thunk';
import OvErrorMessage from '../UI/atoms/OvErrorMessage';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import SettingsIcon from '@mui/icons-material/Settings';
import OvPageTitle from '../UI/molecules/OvPageTitle';
import { ConfigurationSettingsDataUtils } from '../../common/utils/services/configuration-settings-data-utils';
import OvConfigurationSettingItem, {
  ConfigurationOption,
} from '../UI/organisms/OvConfigurationSettingItem';
import { ConfigurationSettingsFields } from '../../firebase/document-field.enums';
import Snackbar from '@mui/material/Snackbar';
import UpdateIcon from '@mui/icons-material/Update';
import OvCompactButton from '../UI/atoms/OvCompactButton';

const ConfigurationSettings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(
    (state) => state.adminConfiguration.loading
  );
  const errorMessage: string = useAppSelector(
    (state) => state.adminConfiguration.errorMessage
  );
  const latestConfiguration: Configuration = useAppSelector(
    (state) => state.adminConfiguration.latestConfiguration
  );

  const [mappedConfigs, setMappedConfigs] = useState<ConfigurationOption[]>([]);
  const [openErrorMessageSnackbar, setOpenErrorMessageSnackbar] =
    useState(false);

  useEffect(() => {
    dispatch(getLatestConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (latestConfiguration) {
      setMappedConfigs(
        ConfigurationSettingsDataUtils.mapConfigurationsForUI(
          latestConfiguration
        )
      );
    }
  }, [latestConfiguration]);

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorMessageSnackbar(true);
    }
  }, [errorMessage]);

  const onConfigurationValueChanged = (
    fieldName: ConfigurationSettingsFields,
    value: any
  ) => {
    const modifiedConfigs = mappedConfigs.map((config) => {
      if (config.fieldName === fieldName) {
        return { ...config, value };
      }

      return config;
    });

    setMappedConfigs(modifiedConfigs);
  };

  const onConfigurationUpdate = () => {
    const updatedConfigs = mappedConfigs.reduce(
      (acc, config) => ({ ...acc, [config.fieldName as string]: config.value }),
      {}
    );

    dispatch(
      updateConfiguration({ ...latestConfiguration, ...updatedConfigs })
    );
  };

  return (
    <Container>
      <OvPageTitleWrapper>
        <OvPageTitle
          title={t('configurationSettings.title')}
          icon={<StyledSettingsIcon />}
        >
          <OvCompactButton
            onClick={onConfigurationUpdate}
            icon={<UpdateIcon />}
          >
            {t('configurationSettings.update')}
          </OvCompactButton>
        </OvPageTitle>
      </OvPageTitleWrapper>

      {mappedConfigs &&
        mappedConfigs.map((config, index) => (
          <OvConfigurationSettingItem
            key={index}
            options={config}
            onChange={onConfigurationValueChanged}
            isDisabled={isLoading}
          />
        ))}

      {errorMessage && <OvErrorMessage message={errorMessage} />}
      {isLoading && <OvLoadingIndicator position="fixed" />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openErrorMessageSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorMessageSnackbar(false)}
      >
        <ErrorMessageContainer>
          <OvErrorMessage message={errorMessage} />
        </ErrorMessageContainer>
      </Snackbar>
    </Container>
  );
};

export default ConfigurationSettings;

const Container = styled.div`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const OvPageTitleWrapper = styled.div`
  margin-bottom: 2rem;
`;

const ErrorMessageContainer = styled.div`
  padding: 1rem;
  border-radius: ${Variables.borderRadius.LARGE};
  background-color: ${Colours.STRAWBERRY['50']};
`;
