import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrdersState } from '../states/orders.state';
import * as firebase from 'firebase/app';
import {
  findOrderActivitiesById,
  findOrderById,
  loadOrders,
  submitOrder,
  updateOrder,
} from '../thunks/orders.thunk';
import { Order } from '../../common/model/dto/order/order';
import { ListPaging } from '../../common/types';
import { isNumber } from 'lodash';
import { OrderActivity } from '../../common/model/dto/order/order-activity';
import { OrderFields } from '../../firebase/document-field.enums';
import { FilterType } from '../../common/model/ui/filter.type';
import { OrderStatus } from '../../common/model/type/order-status.enum';
import { FilterModel } from '../../common/model/ui/filter.model';
import { OrderPaymentStatus } from '../../common/model/type/order-payment-status.enum';

const initialState: OrdersState = {
  isLoading: false,
  error: '',
  orderList: [],
  orderListPaging: {
    total: 0,
    offset: 0,
  },
  orderListStoredQuery: '',
  orderActivityList: [],
  showFilters: true,
  filters: [
    {
      fieldName: OrderFields.internal_id,
      label: 'orders.searchFilters.internalId',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: OrderFields.user_email,
      label: 'orders.searchFilters.userEmail',
      type: FilterType.text,
      value: '',
    },
    {
      fieldName: OrderFields.status,
      label: 'orders.searchFilters.status',
      type: FilterType.select,
      options: [
        {
          label: 'orders.options.statusOptions.created',
          value: OrderStatus.Created,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.submitted',
          value: OrderStatus.Submitted,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.processing',
          value: OrderStatus.Processing,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.shipped',
          value: OrderStatus.Shipped,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.completed',
          value: OrderStatus.Completed,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.canceled',
          value: OrderStatus.Canceled,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.hold',
          value: OrderStatus.Hold,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.archived',
          value: OrderStatus.Archived,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.failed',
          value: OrderStatus.Failed,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.waitingForManualUpdate',
          value: OrderStatus.WaitingForManualUpdate,
          translate: true,
        },
        {
          label: 'orders.options.statusOptions.unknown',
          value: OrderStatus.Unknown,
          translate: true,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
          translate: true,
        },
      ],
      value: '',
    },
    {
      fieldName: OrderFields.payment_status,
      label: 'orders.searchFilters.paymentStatus',
      type: FilterType.select,
      options: [
        {
          label: 'orders.options.paymentStatusOptions.pending',
          value: OrderPaymentStatus.Pending,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.complete',
          value: OrderPaymentStatus.Complete,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.refunded',
          value: OrderPaymentStatus.Refunded,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.failed',
          value: OrderPaymentStatus.Failed,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.abandoned',
          value: OrderPaymentStatus.Abandoned,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.revoked',
          value: OrderPaymentStatus.Revoked,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.preapproved',
          value: OrderPaymentStatus.Preapproved,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.cancelled',
          value: OrderPaymentStatus.Cancelled,
          translate: true,
        },
        {
          label: 'orders.options.paymentStatusOptions.subscription',
          value: OrderPaymentStatus.Subscription,
          translate: true,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
          translate: true,
        },
      ],
      value: '',
    },
  ],
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.orderListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.orderListPaging.offset = action.payload.offset;
      }
    },
    setOrderListStoredQuery: (state, action: PayloadAction<string>) => {
      state.orderListStoredQuery = action.payload;
    },
    clearOrdersList: (state) => {
      state.orderList = [];
    },
    clearSelectedOrder: (state) => {
      state.selectedOrder = undefined;
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.orderListPaging.offset = action.payload.offset;
    },
    clearOrderActivityList: (state) => {
      state.orderActivityList = [];
    },
    clearFilters: (state) => {
      state.filters = state.filters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.filters[index].value,
          disabled: false,
        };
      });
      state.orderListStoredQuery = '';
      state.orderListPaging.offset = 0;
    },
    changeOrderListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.filters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      let parsedValue = action.payload?.value;

      if (action.payload?.value === 'true') {
        parsedValue = true;
      } else if (action.payload?.value === 'false') {
        parsedValue = false;
      }

      state.filters[index].value = parsedValue;
      state.orderListPaging.offset = 0;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        loadOrders.fulfilled,
        (state, action: PayloadAction<Order[]>) => {
          state.orderList = action.payload;
          state.isLoading = false;
          state.error = '';
        }
      )
      .addCase(
        loadOrders.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.isLoading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(findOrderActivitiesById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        findOrderActivitiesById.fulfilled,
        (state, action: PayloadAction<OrderActivity[]>) => {
          state.orderActivityList = action.payload;
          state.isLoading = false;
          state.error = '';
        }
      )
      .addCase(
        findOrderActivitiesById.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.isLoading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(findOrderById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        findOrderById.fulfilled,
        (state, action: PayloadAction<Order>) => {
          state.selectedOrder = action.payload;
          state.isLoading = false;
          state.error = '';
        }
      )
      .addCase(
        findOrderById.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.isLoading = false;
          state.error = action.payload.code;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action: PayloadAction<Order>) => {
        state.selectedOrder = {
          ...state.selectedOrder,
          ...action.payload,
        };

        state.orderList = state.orderList.map((order) => {
          if (order.id === action.payload.id) {
            return {
              ...order,
              ...action.payload,
            };
          }

          return order;
        });

        state.isLoading = false;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(submitOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitOrder.fulfilled, (state, action: PayloadAction<Order>) => {
        state.selectedOrder = {
          ...state.selectedOrder,
          ...action.payload,
        };

        state.orderList = state.orderList.map((order) => {
          if (order.id === action.payload.id) {
            return {
              ...order,
              ...action.payload,
            };
          }

          return order;
        });

        state.isLoading = false;
      })
      .addCase(submitOrder.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setOrderListPaging,
  setOrderListStoredQuery,
  clearOrdersList,
  clearSelectedOrder,
  restoreSearchModel,
  clearOrderActivityList,
  clearFilters,
  changeOrderListFilter,
} = ordersSlice.actions;
export default ordersSlice.reducer;
