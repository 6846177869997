import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { DemoAccountType } from '../../../common/model/dto/demo-account-types.enum';
import {
  Alert,
  AlertTitle,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';
import styled from 'styled-components';
import OvSelect from '../atoms/OvSelect';
import breakpoints from '../../../design-system/breakpoints';
import Variables from '../../../design-system/variables';
import OvButton from '../atoms/OvButton';
import Colours from '../../../design-system/colours';
import OvLoadingIndicator from '../atoms/OvLoadingIndicator';
import { CreateAdminDemoAccountRequest } from '../../../common/model/dto/create-admin-demo-account-request';
import * as Yup from 'yup';
import OvPasswordField from './OvPasswordField';
import { HealthGoalType } from '../../../common/model/dto/health-goal-type.enum';

const OvCreateDemoAccountDialog: FC<{
  isOpen: boolean;
  onCancel: () => void;
  onSave: (request: any) => void;
  isLoading: boolean;
  title: string;
  enableCreatePassword: boolean;
}> = ({ isOpen, onCancel, onSave, isLoading, title, enableCreatePassword }) => {
  const { t } = useTranslation();
  const [showCreatePasswordSection, setShowCreatePasswordSection] =
    useState(false);
  const [validation, setValidation] = useState(Yup.object().shape({}));
  const demoAccountTypeList: { label: string; value: DemoAccountType }[] = [
    {
      label: t(
        'common.demoAccounts.types.naturalCycleMonitoringAndTimeIntercourse'
      ),
      value: DemoAccountType.NATURAL_CYCLE_MONITORING_AND_TIMED_INTERCOURSE,
    },
    {
      label: t('common.demoAccounts.types.timedIntercourseCycleWithClomid'),
      value: DemoAccountType.TIMED_INTERCOURSE_CYCLE_WITH_CLOMID,
    },
    {
      label: t('common.demoAccounts.types.timedIntercourseCycleWithLetrozole'),
      value: DemoAccountType.TIMED_INTERCOURSE_CYCLE_WITH_LETROZOLE,
    },
    {
      label: t(
        'common.demoAccounts.types.naturalCycleIuiWithClomidOrLetrozole'
      ),
      value: DemoAccountType.NATURAL_CYCLE_IUI_WITH_CLOMID_OR_LETROZOLE,
    },
    {
      label: t(
        'common.demoAccounts.types.naturalOrModifiedNaturalFrozenEmbryoTransfer'
      ),
      value: DemoAccountType.NATURAL_OR_MODIFIED_NATURAL_FROZEN_EMBRYO_TRANSFER,
    },
    {
      label: t('common.demoAccounts.types.lupronTriggershotMonitoring'),
      value: DemoAccountType.LUPRON_TRIGGER_SHOT_MONITORING,
    },
  ];
  const currentFertilityGoalList: {
    label: string;
    value: HealthGoalType;
  }[] = [
    {
      label: t('common.demoAccounts.goalTypes.tryingToConceive'),
      value: HealthGoalType.TRYING_TO_CONCEIVE,
    },
    {
      label: t('common.demoAccounts.goalTypes.perimenopauseTracking'),
      value: HealthGoalType.PERIMENOPAUSE_TRACKING,
    },
    {
      label: t('common.demoAccounts.goalTypes.generalHealthMonitoring'),
      value: HealthGoalType.GENERAL_HEALTH_MONITORING,
    },
  ];
  const [selectedDemoAccountTypeValue, setSelectedDemoAccountTypeValue] =
    useState<DemoAccountType>(demoAccountTypeList[0].value);
  const [selectedFertilityGoal, setSelectedFertilityGoal] =
    useState<HealthGoalType>(currentFertilityGoalList[0].value);
  const formik = useFormik<CreateAdminDemoAccountRequest>({
    initialValues: {
      demo_account_type:
        DemoAccountType.NATURAL_CYCLE_MONITORING_AND_TIMED_INTERCOURSE,
      password: undefined,
      current_fertility_goal: HealthGoalType.TRYING_TO_CONCEIVE,
    },
    onSubmit: (createDemoAccountRequest) => {
      onSave(createDemoAccountRequest);
    },
    validationSchema: validation,
  });

  useEffect(() => {
    if (isOpen) {
      formik.resetForm();
      setSelectedDemoAccountTypeValue(demoAccountTypeList[0].value);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (showCreatePasswordSection) {
      setValidation(
        Yup.object().shape({
          password: Yup.string()
            .min(8, 'Password must be at least 8 characters long.')
            .max(128, 'Password cannot exceed 180 characters.')
            .required(),
        })
      );
    } else {
      setValidation(Yup.object().shape({}));
    }
  }, [showCreatePasswordSection]);

  const handleDemoAccountTypeValueChange = (event: any, newValue?: any) => {
    const value = event?.target ? event.target.value : newValue;
    setSelectedDemoAccountTypeValue(value);
    formik.setFieldValue('demo_account_type', value);
  };

  const handleCurrentFertilityGoalChange = (event: any, newValue?: any) => {
    const value = event?.target ? event.target.value : newValue;
    setSelectedFertilityGoal(value);
    formik.setFieldValue('current_fertility_goal', value);
  };

  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledAlert severity="warning">
        <AlertTitle>
          {t('common.demoAccounts.demoAccountWarningTitle')}
        </AlertTitle>
        {t('common.demoAccounts.demoAccountWarningDesc')}
      </StyledAlert>
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent>
          <StyledSelectTitle>
            {t('common.demoAccounts.chooseDemoAccountType')}:
          </StyledSelectTitle>
          <StyledOvSelect
            value={selectedDemoAccountTypeValue}
            onChange={handleDemoAccountTypeValueChange}
          >
            {demoAccountTypeList &&
              demoAccountTypeList.map((type) => (
                <StyledMenuItem key={type.value} value={type.value}>
                  {type.label}
                </StyledMenuItem>
              ))}
          </StyledOvSelect>
          {enableCreatePassword && (
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={showCreatePasswordSection}
                  onChange={() =>
                    setShowCreatePasswordSection(!showCreatePasswordSection)
                  }
                  name="showCreatePasswordSection"
                />
              }
              label={t('common.demoAccounts.createPasswordForDemoUser')}
            />
          )}

          {showCreatePasswordSection && enableCreatePassword && (
            <>
              <StyledDialogDescription>
                {t(
                  'userDialogs.createPasswordAndSelectFertilityGoalDescription'
                )}
              </StyledDialogDescription>
              <StyledSelectTitle>
                {t('common.demoAccounts.chooseFertilityGoal')}:
              </StyledSelectTitle>
              <StyledOvSelect
                value={selectedFertilityGoal}
                onChange={handleCurrentFertilityGoalChange}
              >
                {currentFertilityGoalList &&
                  currentFertilityGoalList.map((type) => (
                    <StyledMenuItem key={type.value} value={type.value}>
                      {type.label}
                    </StyledMenuItem>
                  ))}
              </StyledOvSelect>
              <OvPasswordField
                style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  justifySelf: 'center',
                }}
                name="password"
                fullWidth
                label={t('login.passwordLabel') + '*'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && !!formik.errors.password}
                helperText={
                  formik.errors.password &&
                  formik.touched.password &&
                  formik.errors.password
                }
              />
            </>
          )}
          <StyledDialogActions>
            <StyledLightOvButton onClick={onCancel}>
              {t('common.actions.cancel')}
            </StyledLightOvButton>
            <StyledOvButton type="submit">
              {t('common.actions.create')}
            </StyledOvButton>
          </StyledDialogActions>
        </StyledDialogContent>
      </form>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Dialog>
  );
};

export default OvCreateDemoAccountDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    text-align: center;
    font-weight: bold;
    text-transform: none;
  }
`;

const StyledSelectTitle = styled.p`
  font-weight: bold;
  padding: 0;
  margin: 0;
  color: ${Colours.OV_BASE};
`;

const StyledOvSelect = styled(OvSelect)`
  && {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    width: 100%;
    min-width: 31.25rem !important;
    padding: 0.5rem 1.5rem !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.75rem;

    @media (min-width: ${breakpoints.sm}) {
      width: 34rem;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    &.MuiButtonBase-root {
      display: flex;
      justify-content: flex-start;
      padding: 0.375rem 1rem;
      font-size: ${Variables.fontSizes.MEDIUM};
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin-top: 2rem;
    padding: 0 0 0.5rem 1.5rem;
  }
`;

const StyledLightOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0 1rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};
    font-weight: bold;
    text-transform: none;
    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: rgba(1, 39, 70, 0.04);
    }
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    padding: 0 1rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    transition: none;
    font-weight: bold;
    text-transform: none;
    margin-left: 1rem !important;
    align-self: flex-end;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0.25rem 0.5rem 0.25rem;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};
    max-width: 32rem;
    align-self: center;
    text-align: justify;

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    padding-bottom: 0.5rem;
    width: 100%;
    margin-left: 0;
  }
`;

const StyledDialogDescription = styled(DialogContentText)`
  && {
    max-width: 30rem;
    text-align: left;
    margin-bottom: 1rem;
  }
`;
