import { Resource } from '../common/model/type/resource.enum';
import { Entitlement } from '../common/model/dto/entitlement';
import { useAppSelector } from '../redux/hooks';
import { AllowedOperations } from '../common/model/dto/allowed-operations';
import { Operation } from '../common/model/type/operation.enum';

export default function useAuthorized(
  resource: Resource,
  clinicLocationId?: string,
  selectedClinicRole?: string
): AllowedOperations {
  const entitlements: Entitlement[] = useAppSelector(
    (state) => state.auth.entitlements
  );

  const filteredEntitlements = entitlements?.filter(
    (ent) =>
      ent.resource === resource &&
      (clinicLocationId ? ent.clinic_location_id === clinicLocationId : true)
  );

  if (selectedClinicRole && selectedClinicRole === 'Patient') {
    return {};
  }

  return {
    supervise: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Supervise
    ),
    create: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Create
    ),
    delete: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Delete
    ),
    update: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Update
    ),
    read: filteredEntitlements?.some((ent) => ent.operation === Operation.Read),
    manage: filteredEntitlements?.some(
      (ent) => ent.operation === Operation.Manage
    ),
  };
}
