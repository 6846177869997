import React, { FC, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import { clearSelectedUser } from '../../redux/reducers/user.slice';
import {
  deactivateUser,
  deletePasswordForDemoAccount,
  deleteUser,
  getUserDetails,
  setPasswordForDemoAccount,
} from '../../redux/thunks/user.thunk';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { UserInfo } from '../../common/model/dto/user-info';
import { Alert, AlertTitle, Tab, Tabs } from '@mui/material';
import { UserDetailTabType } from '../../common/model/type/user-detail-tab.type';
import { last } from 'lodash';
import OvUserInfo from '../UI/organisms/OvUserInfo';
import OvUserInfoCalendar from '../UI/organisms/OvUserInfoCalendar';
import OvUserDetailReports from '../UI/organisms/OvUserDetailReports';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ErrorIcon from '@mui/icons-material/Error';
import OvInfoModal from '../UI/molecules/OvInfoModal';
import OvConfirmationInput from '../UI/molecules/OvConfirmationInput';
import InfoIcon from '@mui/icons-material/Info';
import OvResetPasswordDialog from '../UI/molecules/OvResetPasswordDialog';
import {
  loadDailyDataBetweenDateRange,
  loadUserDailyData,
} from '../../redux/thunks/daily-data.thunk';
import OvUserCharts from '../UI/organisms/OvUserCharts';
import OvSetPasswordDialog from '../UI/molecules/OvSetPasswordDialog';
import OvViewPasswordDialog from '../UI/molecules/OvViewPasswordDialog';
import { SetDemoAccountPasswordRequest } from '../../common/model/dto/set-demo-account-password-request.ts';

const UserDetails: FC = () => {
  let { userId }: any = useParams();
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<UserDetailTabType>(
    UserDetailTabType.USER_INFO
  );
  const [
    isOpenDeactivateConfirmationDialog,
    setOpenDeactivateConfirmationDialog,
  ] = useState<boolean>(false);
  const [isOpenConfirmationInputModal, setOpenConfirmationInputModal] =
    useState<boolean>(false);
  const [isOpenDeleteUserDialog, setIsOpenDeleteUserDialog] =
    useState<boolean>(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [isCreatePasswordDialogOpen, setIsCreatePasswordDialogOpen] =
    useState(false);
  const [isViewPasswordDialogOpen, setIsViewPasswordDialogOpen] =
    useState(false);
  const [isDeleteDemoAccountPasswordOpen, setIsDeleteDemoAccountPasswordOpen] =
    useState(false);
  const selectedUser: UserInfo = useAppSelector(
    (state) => state.user.selectedUser
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoading = useAppSelector((state) => state.user.isLoading);

  const errorMessage = useAppSelector((state) => state.user.errorMessage);

  const changeTab = (event: any, newValue: UserDetailTabType) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(clearSelectedUser());
    dispatch(getUserDetails(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const setActiveTabFromUrl = () => {
      const pathName = last(location.pathname.split('/')) as UserDetailTabType;
      const { USER_INFO, CALENDAR, REPORTS, HORMONE_CHARTS } =
        UserDetailTabType;

      if ([USER_INFO, CALENDAR, REPORTS, HORMONE_CHARTS].includes(pathName)) {
        setActiveTab(pathName);
      }
    };

    setActiveTabFromUrl();
  }, [dispatch, selectedUser?.document_id, location.pathname]);

  const isAccountDeactivated = (): boolean => {
    if (selectedUser) {
      const deactivated = '[ACCOUNT DEACTIVATED]';
      const { first_name, last_name, email } = selectedUser;

      return (
        first_name === deactivated &&
        last_name === deactivated &&
        email === deactivated
      );
    }

    return false;
  };

  const handleDeactivateUser = () => {
    setOpenConfirmationInputModal(false);

    if (selectedUser?.id) {
      dispatch(deactivateUser({ id: selectedUser?.id, history }));
    }
  };

  const handleDeleteUser = () => {
    setIsOpenDeleteUserDialog(false);

    if (selectedUser?.id) {
      dispatch(deleteUser({ id: selectedUser.id, history }));
    }
  };

  const handleDeleteDemoAccountPassword = () => {
    setIsDeleteDemoAccountPasswordOpen(false);

    if (selectedUser?.id) {
      dispatch(deletePasswordForDemoAccount(selectedUser.id));
    }
  };

  const handleSetPasswordForDemoAccount = (
    setPasswordAttempt: SetDemoAccountPasswordRequest
  ) => {
    if (selectedUser?.id) {
      dispatch(
        setPasswordForDemoAccount({
          userId: selectedUser.id,
          setPasswordAttempt,
        })
      );

      setIsCreatePasswordDialogOpen(false);
    }
  };

  const handleUserDeactivationFirstStepConfirmed = () => {
    setOpenDeactivateConfirmationDialog(false);
    setOpenConfirmationInputModal(true);
  };

  return (
    <>
      {
        <DetailPageWrapper>
          <DetailsHeader>
            <UserName>
              {`${selectedUser?.first_name} ${selectedUser?.last_name}`}
              <StyledEmail>{selectedUser?.email}</StyledEmail>
            </UserName>

            <CtaWrapper>
              {errorMessage ? (
                <ErrorMessage>
                  <StyledErrorIcon />
                  {errorMessage}
                </ErrorMessage>
              ) : (
                ''
              )}

              {!selectedUser?.is_demo_account && (
                <StyledOvButton
                  onClick={() => setIsResetPasswordDialogOpen(true)}
                >
                  {t('common.actions.resetPassword')}
                </StyledOvButton>
              )}

              {selectedUser?.is_demo_account &&
                !selectedUser?.has_demo_account_password && (
                  <StyledOvButton
                    onClick={() => setIsCreatePasswordDialogOpen(true)}
                  >
                    {t('common.actions.createDemoAccountPassword')}
                  </StyledOvButton>
                )}

              {selectedUser?.is_demo_account &&
                selectedUser?.has_demo_account_password && (
                  <StyledOvButton
                    onClick={() => setIsViewPasswordDialogOpen(true)}
                  >
                    {t('common.actions.viewDemoAccountPassword')}
                  </StyledOvButton>
                )}

              {selectedUser?.is_demo_account &&
                selectedUser?.has_demo_account_password && (
                  <StyledOvButton
                    onClick={() => setIsDeleteDemoAccountPasswordOpen(true)}
                  >
                    {t('common.actions.deleteDemoAccountPassword')}
                  </StyledOvButton>
                )}

              {!isAccountDeactivated() &&
              selectedUser?.document_id &&
              !selectedUser?.is_demo_account ? (
                <StyledOvButton
                  onClick={() => setOpenDeactivateConfirmationDialog(true)}
                >
                  <StyledDeleteCtaIcon />
                  {t('common.actions.deactivate')}
                </StyledOvButton>
              ) : (
                ''
              )}

              {selectedUser && !selectedUser?.document_id ? (
                <StyledOvButton onClick={() => setIsOpenDeleteUserDialog(true)}>
                  {t('common.actions.delete')}
                </StyledOvButton>
              ) : (
                ''
              )}
            </CtaWrapper>
          </DetailsHeader>

          {selectedUser?.is_demo_account && (
            <StyledAlert severity="warning">
              <AlertTitle>{t('userDetails.demoTitle')}</AlertTitle>
              {t('userDetails.demoDesc')}
            </StyledAlert>
          )}

          <TabContainer>
            <Tabs onChange={changeTab} value={activeTab}>
              <Tab
                style={{
                  borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                value="user-info"
                label={t('userDetails.tabs.userInfo')}
                component={Link}
                to={`${url}/user-info`}
              />
              <Tab
                style={{
                  borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                value={UserDetailTabType.HORMONE_CHARTS}
                label={t('dashboard.tabs.hormoneCharts')}
                component={Link}
                to={`${url}/${UserDetailTabType.HORMONE_CHARTS}`}
                replace
              />
              <Tab
                style={{
                  borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                value="calendar"
                label={t('userDetails.tabs.calendar')}
                component={Link}
                to={`${url}/calendar`}
              />
              <Tab
                style={{
                  borderBottom: '2px solid rgba(0, 0, 0, 0.38)',
                  textTransform: 'none',
                }}
                value="reports"
                label={t('userDetails.tabs.reports')}
                component={Link}
                to={`${url}/reports`}
              />
            </Tabs>
          </TabContainer>

          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/user-info`} />
            </Route>
            <Route path={`${path}/user-info`}>
              <OvUserInfo selectedUser={selectedUser} />
            </Route>
            <Route path={`${path}/hormone-charts`}>
              <OvUserCharts
                user={selectedUser}
                loadDailyData={loadUserDailyData}
                loadDailyDataForCalendar={loadDailyDataBetweenDateRange}
              />
            </Route>
            <Route path={`${path}/calendar`}>
              <OvUserInfoCalendar selectedUser={selectedUser} />
            </Route>
            <Route path={`${path}/reports`}>
              <OvUserDetailReports
                selectedUser={selectedUser}
                loadDailyDataBetweenRange={loadDailyDataBetweenDateRange}
              />
            </Route>
          </Switch>
        </DetailPageWrapper>
      }

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeactivateConfirmationDialog}
        onCancel={() => setOpenDeactivateConfirmationDialog(false)}
        onConfirm={handleUserDeactivationFirstStepConfirmed}
        title="userDetails.actions.deactivateTitle"
        description="userDetails.actions.deactivateDescription"
      />

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenDeleteUserDialog}
        onCancel={() => setIsOpenDeleteUserDialog(false)}
        onConfirm={handleDeleteUser}
        title="userDetails.actions.deleteTitle"
        description="userDetails.actions.deleteDescription"
      />

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isDeleteDemoAccountPasswordOpen}
        onCancel={() => setIsDeleteDemoAccountPasswordOpen(false)}
        onConfirm={handleDeleteDemoAccountPassword}
        title="userDetails.actions.deleteDemoAccountPasswordTitle"
        description="userDetails.actions.deleteDemoAccountPasswordDescription"
      />

      <OvResetPasswordDialog
        isResetPasswordDialogOpen={isResetPasswordDialogOpen}
        onCancel={() => setIsResetPasswordDialogOpen(false)}
        email={selectedUser?.email}
      />

      <OvSetPasswordDialog
        isOpen={isCreatePasswordDialogOpen}
        onCancel={() => setIsCreatePasswordDialogOpen(false)}
        onSave={handleSetPasswordForDemoAccount}
      />

      <OvViewPasswordDialog
        isOpen={isViewPasswordDialogOpen}
        onCancel={() => setIsViewPasswordDialogOpen(false)}
        userId={selectedUser?.id}
      />

      <OvInfoModal
        isOpen={isOpenConfirmationInputModal}
        title={t('common.areYouSure')}
        onCancel={() => setOpenConfirmationInputModal(false)}
        icon={<StyledInfoIcon />}
      >
        <OvConfirmationInput
          expectedValue={selectedUser?.email}
          label={t('userDetails.actions.deactivateConfirm')}
          ctaLabel={t('userDetails.actions.deactivateConfirmAction')}
          onMatch={handleDeactivateUser}
          caseSensitive={false}
        />
      </OvInfoModal>

      {isLoading && <OvLoadingIndicator position="fixed" />}
    </>
  );
};

export default UserDetails;

const DetailPageWrapper = styled.div`
  margin-left: 1rem;
  border-radius: ${Variables.borderRadius.SMALL};
`;

const DetailsHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const UserName = styled.h6`
  margin: 0;
  font-weight: bold;
  font-size: ${Variables.fontSizes.LARGE};
`;

const StyledEmail = styled.span`
  border-left: 1px solid ${Colours.OV_BASE_HOVER};
  margin-left: 0.75rem;
  padding-left: 0.75rem;
`;

const CtaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TabContainer = styled.div`
  margin-bottom: 1.5rem;

  && {
    .MuiTabs-indicator {
      background-color: ${Colours.OV_BASE};
    }
  }
`;

const StyledDeleteCtaIcon = styled(PowerSettingsNewIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledDeleteIcon = styled(PowerSettingsNewIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const ErrorMessage = styled.span`
  color: ${Colours.OV_RED};
  font-weight: bold;
  margin-right: 2rem;
  display: flex;
  align-items: center;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  && {
    margin-right: 0.5rem;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    margin: 0.25rem 0 0.5rem 0;
    border-radius: ${Variables.borderRadius.LARGE};
    background-color: ${Colours.OV_YELLOW};
    color: ${Colours.OV_BASE};

    .MuiAlertTitle-root {
      font-weight: bold !important;
    }
  }
`;
