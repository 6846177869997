import React, { FC, useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearSelectedProductPrice } from '../../redux/reducers/admin-product-price.slice';
import {
  archiveProductPrice,
  getProductPriceById,
  updateProductPriceAndGoToDetails,
} from '../../redux/thunks/admin/admin-product-price.thunk';
import styled from 'styled-components';
import Variables from '../../design-system/variables';
import Colours from '../../design-system/colours';
import OvButton from '../UI/atoms/OvButton';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import breakpoints from '../../design-system/breakpoints';
import OvTable from '../UI/molecules/OvTable';
import { ProductPriceDataUtils } from '../../common/utils/services/product-price-data-utils';
import { ProductPrice } from '../../common/model/dto/product/product-price';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import { Constants } from '../../common/constants';
import OvCompactButton from '../UI/atoms/OvCompactButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OvConfirmationDialog from '../UI/molecules/OvConfirmationDialog';
import { getProductById } from '../../redux/thunks/products.thunk';
import { Product } from '../../common/model/dto/product/product';
import { Tooltip } from '@mui/material';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import AddIcon from '@mui/icons-material/Add';
import { UpdateProductPriceRequest } from '../../common/model/dto/product/update-product-price-request';
import OvCreatePaymentLinkDialog from '../UI/molecules/OvCreatePaymentLinkDialog';

const ProductPriceDetails: FC = () => {
  const { productId, productPriceId }: any = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isOpenArchiveConfirmationDialog, setIsOpenArchiveConfirmationDialog] =
    useState<boolean>(false);
  const [
    isOpenUnarchiveConfirmationDialog,
    setIsOpenUnarchiveConfirmationDialog,
  ] = useState<boolean>(false);
  const [isCreatePaymentLinkDialogOpen, setIsCreatePaymentLinkDialogOpen] =
    useState(false);
  const selectedProductPrice: ProductPrice | undefined = useAppSelector(
    (state) => state.adminProductPrice.selectedProductPrice
  );
  const isProductPriceLoading: boolean = useAppSelector(
    (state) => state.adminProductPrice.isLoading
  );
  const selectedProduct: Product = useAppSelector(
    (state) => state.products.selectedProduct
  );
  const isProductLoading: boolean = useAppSelector(
    (state) => state.products.loading
  );
  const isLoading = isProductPriceLoading || isProductLoading;

  useEffect(() => {
    dispatch(getProductPriceById(productPriceId));
    dispatch(getProductById(productId));

    return () => {
      dispatch(clearSelectedProductPrice());
    };
  }, [productPriceId, productId, dispatch]);

  const onBackClicked = () => {
    history.push(`/products/detail/${productId}/prices`);
  };

  const archivePrice = () => {
    if (productPriceId && productId) {
      setIsOpenArchiveConfirmationDialog(false);
      dispatch(
        archiveProductPrice({
          history,
          productId,
          productPriceId,
        })
      );
    }
  };

  const handleUnarchivePrice = () => {
    if (productPriceId && productId) {
      setIsOpenUnarchiveConfirmationDialog(false);
      dispatch(
        updateProductPriceAndGoToDetails({
          history,
          productId,
          productPriceId,
          updateProductPriceRequest: { active: true },
        })
      );
    }
  };

  const handleCreatePaymentLink = (
    updateProductPriceRequest: UpdateProductPriceRequest
  ) => {
    setIsCreatePaymentLinkDialogOpen(false);
    dispatch(
      updateProductPriceAndGoToDetails({
        history,
        productId,
        productPriceId,
        updateProductPriceRequest,
      })
    );
  };

  return (
    <Container>
      <DetailsHeader>
        <LeftSideWrapper>
          <BackCta onClick={onBackClicked}>
            <StyledArrowBackIcon />
            {t('common.actions.back')}
          </BackCta>
          {selectedProductPrice?.stripe_price_id && (
            <a
              href={`https://dashboard.stripe.com/${
                !Constants.IS_PRODUCTION ? 'test/' : ''
              }prices/${selectedProductPrice?.stripe_price_id}`}
              target="blank"
            >
              {t('productPrices.details.stripePriceLink')}
            </a>
          )}
        </LeftSideWrapper>
        <ActionsWrapper>
          {!selectedProductPrice?.stripe_payment_link_id && (
            <>
              {selectedProduct?.is_active && selectedProductPrice?.active ? (
                <StyledOvButton
                  onClick={() => setIsCreatePaymentLinkDialogOpen(true)}
                >
                  <AddIcon />
                  {t('productPrices.actions.createPaymentLink')}
                </StyledOvButton>
              ) : (
                <Tooltip
                  title={t(
                    'productPrices.cannotCreatePaymentLinkTooltipDescription'
                  )}
                >
                  <div>
                    <StyledOvButton
                      disabled={
                        !selectedProduct?.is_active ||
                        !selectedProductPrice?.active
                      }
                    >
                      <AddIcon />
                      {t('productPrices.actions.createPaymentLink')}
                    </StyledOvButton>
                  </div>
                </Tooltip>
              )}
            </>
          )}

          {selectedProduct?.default_price_id ===
          selectedProductPrice?.stripe_price_id ? (
            <Tooltip title={t('productPrices.actions.archiveTooltip')}>
              <div style={{ marginRight: '1rem' }}>
                <StyledOvButton disabled>
                  {t('productPrices.actions.archive')}
                </StyledOvButton>
              </div>
            </Tooltip>
          ) : (
            <StyledOvButton
              onClick={
                selectedProductPrice?.active
                  ? () => setIsOpenArchiveConfirmationDialog(true)
                  : () => setIsOpenUnarchiveConfirmationDialog(true)
              }
            >
              {selectedProductPrice?.active ? (
                <DeleteIcon style={{ marginRight: '0.5rem' }} />
              ) : (
                <RestoreFromTrashIcon style={{ marginRight: '0.5rem' }} />
              )}
              {selectedProductPrice?.active
                ? t('productPrices.actions.archive')
                : t('productPrices.actions.unarchive')}
            </StyledOvButton>
          )}
          <StyledNavLink
            to={`/products/${productId}/prices/${productPriceId}/edit`}
          >
            <OvCompactButton icon={<EditIcon />}>
              {t('common.actions.edit')}
            </OvCompactButton>
          </StyledNavLink>
        </ActionsWrapper>
      </DetailsHeader>
      <DetailsWrapper>
        <TableWrapper>
          <OvTable
            data={ProductPriceDataUtils.mapProductPriceDataToTable(
              selectedProductPrice
            )}
          ></OvTable>
        </TableWrapper>
      </DetailsWrapper>

      <OvConfirmationDialog
        icon={<StyledDeleteIcon />}
        isOpen={isOpenArchiveConfirmationDialog}
        onCancel={() => setIsOpenArchiveConfirmationDialog(false)}
        onConfirm={archivePrice}
        title="productPrices.actions.archiveConfirmationTitle"
        description="productPrices.actions.archiveDescription"
      />

      <OvConfirmationDialog
        icon={<StyledRestoreFromTrashIcon />}
        isOpen={isOpenUnarchiveConfirmationDialog}
        onCancel={() => setIsOpenUnarchiveConfirmationDialog(false)}
        onConfirm={handleUnarchivePrice}
        title="productPrices.actions.unarchiveTitle"
        description="productPrices.actions.unarchiveDescription"
      />

      <OvCreatePaymentLinkDialog
        isOpen={isCreatePaymentLinkDialogOpen}
        onCancel={() => setIsCreatePaymentLinkDialogOpen(false)}
        onSave={handleCreatePaymentLink}
      />

      {isLoading && <OvLoadingIndicator position={'fixed'} />}
    </Container>
  );
};

export default ProductPriceDetails;

const Container = styled.section`
  margin-left: 1rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  background-color: ${Colours.WHITE};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius.LARGE};
`;

const DetailsHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BackCta = styled(OvButton)`
  && {
    padding: 0 10px 0 4px;
    margin-right: 16px;
    border-radius: ${Variables.borderRadius.XLARGE};
    background-color: ${Colours.OV_BASE};
  }
`;

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  margin-right: 4px;
  height: 20px;
`;

const DetailsWrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  align-self: flex-start;
  margin-left: 12px;
  width: 100%;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

const StyledNavLink = styled(NavLink)`
  && {
    text-decoration: none;
  }
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: none;
    padding: 2px 10px;
    padding: 2px 0.625rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    background-color: ${Colours.OV_BASE};
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledRestoreFromTrashIcon = styled(RestoreFromTrashIcon)`
  && {
    box-sizing: content-box;
    padding: 0.5rem;
    background: ${Colours.OV_BASE};
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    display: block;
    margin: 1rem auto 0 auto;
    color: ${Colours.OV_SEMI_LIGHT};
  }
`;
