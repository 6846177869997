import { OvTableData } from '../../../components/UI/molecules/OvTable';
import { LotFields } from '../../../firebase/document-field.enums';
import { TableRowEditWidgetType } from '../../model/ui/table-row-edit-widget.type';
import DateUtils from './date-utils';
import i18n from 'i18next';
import StringUtils from './string-utils';
import { isNumber } from 'lodash';
import { Lot } from '../../model/dto/lot';

export class LotDataUtils {
  static mapLotToTable(lot: Lot): OvTableData {
    return [
      {
        label: 'LOT',
        value: lot?.name,
        editOptions: {
          propertyName: LotFields.name,
          widgetType: TableRowEditWidgetType.TEXT,
        },
      },
      {
        label: 'lots.fields.documentId',
        translateLabel: true,
        value: lot.document_id ? lot.document_id : lot.id,
      },
      {
        label: 'lots.fields.createdAt',
        translateLabel: true,
        value: DateUtils.formatDate(lot?.created_at as Date, 'MMMM DD, YYYY'),
      },
      {
        label: 'lots.fields.kind',
        translateLabel: true,
        value: lot?.type,
        prettifiedValue: lot?.type,
        editOptions: {
          options: [
            {
              label: 'CTRL-LH-PG',
              value: 'CTRL-LH-PG',
            },
            {
              label: 'CTRL-PG-LH',
              value: 'CTRL-PG-LH',
            },
            {
              label: 'E3G',
              value: 'E3G',
            },
          ],
          propertyName: LotFields.type,
          widgetType: TableRowEditWidgetType.SELECT,
        },
      },
      {
        label: 'lots.fields.scanWithFlash',
        translateLabel: true,
        value: lot?.scan_with_flash,
        prettifiedValue: lot?.scan_with_flash
          ? i18n.t('common.actions.yes')
          : i18n.t('common.actions.no'),
        editOptions: {
          propertyName: LotFields.scan_with_flash,
          widgetType: TableRowEditWidgetType.BOOLEAN,
        },
      },
      {
        label: 'lots.fields.status',
        translateLabel: true,
        value: lot?.status,
        editOptions: {
          options: [
            {
              label: i18n.t('lots.fields.statusValues.beta'),
              value: 'Beta',
            },
            {
              label: i18n.t('lots.fields.statusValues.validated'),
              value: 'Validated',
            },
            {
              label: i18n.t('lots.fields.statusValues.releasedForProd'),
              value: 'Released for Production',
            },
          ],
          propertyName: LotFields.status,
          widgetType: TableRowEditWidgetType.SELECT,
        },
      },
      {
        label: 'lots.fields.dateOfManufacture',
        translateLabel: true,
        value:
          lot?.date_of_manufacture &&
          DateUtils.formatDate(
            lot?.date_of_manufacture as Date,
            'MMMM DD, YYYY'
          ),
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: LotFields.date_of_manufacture,
        },
      },
      {
        label: 'lots.fields.dateOfExpiry',
        translateLabel: true,
        value:
          lot?.date_of_expiry &&
          DateUtils.formatDate(lot?.date_of_expiry as Date, 'MMMM DD, YYYY'),
        editOptions: {
          widgetType: TableRowEditWidgetType.DATE,
          propertyName: LotFields.date_of_expiry,
        },
      },
      {
        label: 'lots.fields.comment',
        translateLabel: true,
        value: lot?.comment,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: LotFields.comment,
        },
      },
      {
        label: 'lots.fields.lotDataDocumentUrl',
        translateLabel: true,
        value: lot?.lot_data_document_url,
        linkUrl: lot?.lot_data_document_url,
        editOptions: {
          widgetType: TableRowEditWidgetType.TEXT,
          propertyName: LotFields.lot_data_document_url,
        },
      },
      {
        label: 'lots.fields.nrOfManCartridges',
        translateLabel: true,
        value: lot?.no_of_manufactured_cartridges,
        editOptions: {
          widgetType: TableRowEditWidgetType.NUMBER,
          propertyName: LotFields.no_of_manufactured_cartridges,
        },
      },
      {
        label: 'lots.fields.numberOfScans',
        translateLabel: true,
        value: lot?.no_of_scans,
      },
      {
        label: 'lots.fields.usagePercentage',
        translateLabel: true,
        prettifiedValue:
          isNumber(lot?.no_of_manufactured_cartridges) &&
          lot?.no_of_manufactured_cartridges !== 0 &&
          StringUtils.displayWithPostfix(
            StringUtils.displayInPercentage(
              lot?.no_of_scans || 0,
              lot.no_of_manufactured_cartridges
            ),
            ' %'
          ),
      },
      {
        label: 'lots.fields.dateOfFirstScan',
        translateLabel: true,
        value:
          lot.date_of_first_scan &&
          DateUtils.formatDate(
            lot.date_of_first_scan,
            'MMMM DD, YYYY, HH:mm:ss'
          ),
      },
      {
        label: 'lots.fields.numberOfErrorScans',
        translateLabel: true,
        value: lot?.no_of_error_scans,
      },
      {
        label: 'lots.fields.errorPercentage',
        translateLabel: true,
        prettifiedValue:
          isNumber(lot?.no_of_manufactured_cartridges) &&
          lot?.no_of_manufactured_cartridges !== 0 &&
          StringUtils.displayWithPostfix(
            StringUtils.displayInPercentage(
              lot?.no_of_error_scans || 0,
              lot.no_of_manufactured_cartridges
            ),
            ' %'
          ),
      },
      {
        label: 'lots.fields.axArray',
        translateLabel: true,
        value: lot?.ax_array ? lot?.ax_array : [0, 0],
        prettifiedValue: lot?.ax_array ? JSON.stringify(lot?.ax_array) : '-',
        editOptions: {
          propertyName: LotFields.ax_array,
          widgetType: TableRowEditWidgetType.TEXT,
        },
      },
    ];
  }
}
