import React, { FC } from 'react';
import styled from 'styled-components';
import DateUtils from '../../../common/utils/services/date-utils';
import OvTable from './OvTable';
import { useTranslation } from 'react-i18next';
import Colours from '../../../design-system/colours';
import breakpoints from '../../../design-system/breakpoints';
import { Link, useLocation } from 'react-router-dom';
import OvButton from '../atoms/OvButton';
import OvTextButton from '../atoms/OvTextButton';
import { Timestamp } from 'firebase/firestore';
import Variables from '../../../design-system/variables';
import OvCalendarInfo from './OvCalendarInfo';
import { DailyData } from '../../../common/model/dto/daily-data';
import { DailyDataUtils } from '../../../common/utils/services/daily-data-utils';
import { UserInfo } from '../../../common/model/dto/user-info';
import { CalendarDayStatusUtils } from '../../../common/utils/services/calendar-day-status-utils';
import { TryingToConceiveStatus } from '../../../common/model/dto/trying-to-conceive-status.enum';

const OvDailyDataDetailsInCalendar: FC<{
  dailyData?: DailyData;
  selectedDay?: Timestamp;
  createJournalEntry?: (timestamp: Timestamp) => void;
  selectedUser?: UserInfo;
}> = ({ dailyData, selectedDay, createJournalEntry, selectedUser }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const onCreateJournalEntry = () =>
    selectedDay && createJournalEntry?.(selectedDay);

  const tryingToConceiveStatus =
    CalendarDayStatusUtils.getTryingToConceiveStatusLabel(
      dailyData?.trying_to_conceive_status
    );

  const statusColor = CalendarDayStatusUtils.getTryingToConceiveStatusColor(
    dailyData?.trying_to_conceive_status
  );

  return (
    <Wrapper>
      <Header>
        {selectedDay ? (
          <>
            <Title>
              {DateUtils.getDateStrFromTimestamp(
                selectedDay,
                'dddd, MMMM DD, YYYY'
              )}
            </Title>
            {dailyData ? (
              <StyledOvButton>
                <Link
                  to={{
                    pathname: `/daily-data/${selectedUser?.document_id}_${dailyData.day}`,
                    state: {
                      prevPath: `${location.pathname}${location.search}`,
                      selectedUser,
                    },
                  }}
                >
                  {t('common.actions.details')}
                </Link>
              </StyledOvButton>
            ) : null}
          </>
        ) : (
          <Title>{t('common.dailyData.noSelectedValue')}</Title>
        )}
      </Header>

      {dailyData ? (
        <CalendarDetailsWrapper>
          {tryingToConceiveStatus && (
            <StyledFertilityStatus
              style={{
                backgroundColor: statusColor,
                color:
                  dailyData.trying_to_conceive_status ===
                  TryingToConceiveStatus.LOW
                    ? Colours.OV_WHITE
                    : Colours.OV_BASE,
                border: `1px solid ${
                  dailyData.trying_to_conceive_status ===
                  TryingToConceiveStatus.LOW
                    ? Colours.OV_WHITE
                    : Colours.OV_BASE
                }`,
              }}
            >
              {tryingToConceiveStatus}
            </StyledFertilityStatus>
          )}
          <TableWrapper>
            <OvTable
              theme="Dark"
              data={DailyDataUtils.mapDailyDataToTable(dailyData)}
            />
          </TableWrapper>
        </CalendarDetailsWrapper>
      ) : (
        ''
      )}

      {selectedDay && !dailyData && createJournalEntry ? (
        <NoContentContainer>
          <NoContentText>{t('common.dailyData.noEventFound')}</NoContentText>
          <CreateJournalEntryButton onClick={onCreateJournalEntry}>
            {t('common.dailyData.create')}
          </CreateJournalEntryButton>
        </NoContentContainer>
      ) : (
        ''
      )}

      <CalendarInfoContainer>
        <OvCalendarInfo showScanIcons />
      </CalendarInfoContainer>
    </Wrapper>
  );
};

export default OvDailyDataDetailsInCalendar;

const Wrapper = styled.section`
  position: relative;
  border-radius: 0.25rem;
  color: #fffcf9;
  background-color: #012746;
  margin-left: 1rem;
  padding: 1rem;
  width: 17.5rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.xl}) {
    min-width: 23.75rem;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: 0.75rem;
    height: 37.5rem;
  }
`;

const Header = styled.header`
  padding: 0.75rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Title = styled.p`
  margin: 0 auto;
  font-size: ${Variables.fontSizes.MEDIUM};
  font-weight: bold;
  color: ${Colours.OV_SEMI_LIGHT};
`;

const StyledOvButton = styled(OvButton)`
  && {
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    padding: 0.25rem 0.5rem;
    background-color: ${Colours.OV_WHITE};
    border: 1px solid rgba(1, 39, 70, 0.5);
    color: ${Colours.OV_BASE};

    &:hover {
      border: 1px solid ${Colours.OV_BASE};
      background-color: ${Colours.OV_SEMI_LIGHT};
    }

    a {
      margin: 0 0.75rem;
      text-transform: none;
      font-weight: bold;
    }
  }
`;

const NoContentContainer = styled.div`
  margin: 8rem 1rem 1.5rem 1rem;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  flex-direction: column;
`;

const NoContentText = styled.p`
  color: ${Colours.OV_SEMI_LIGHT};
  font-size: ${Variables.fontSizes.MEDIUM};
  text-align: center;
`;

const TableWrapper = styled.div`
  max-height: 13rem;
  overflow-y: auto;
  width: 100%;
`;

const CreateJournalEntryButton = styled(OvTextButton)`
  && {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 1rem;
    text-align: center;
    color: ${Colours.WHITE};

    &:hover {
      color: ${Colours.OV_SEMI_LIGHT};
    }
  }
`;

const CalendarInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  margin: -1.5rem;
`;

const CalendarDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

const StyledFertilityStatus = styled.div`
  padding: 0.5rem 1rem;
  border-radius: ${Variables.borderRadius.XLARGE};
`;
