import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UserInfo } from '../../common/model/dto/user-info';
import OvPatientSearchFilter from '../UI/organisms/OvPatientSearchFilter';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import OvLoadingIndicator from '../UI/atoms/OvLoadingIndicator';
import OvNoContent from '../UI/molecules/OvNoContent';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';
import OvPagination from '../UI/atoms/OvPagination';
import { ListPaging } from '../../common/types';
import { ClinicService } from '../../services/clinic.service';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { PaginationItem } from '@mui/material';
import breakpoints from '../../design-system/breakpoints';
import Colours from '../../design-system/colours';
import { AssignPatientToClinicLocationRequest } from '../../common/model/dto/assign-user-to-clinic-location-request';
import OvButton from '../UI/atoms/OvButton';
import Variables from '../../design-system/variables';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import OvAssignPatientToClinicLocationDialog from '../UI/molecules/OvAssignPatientToClinicLocationDialog';
import OvUserTypeahead from '../UI/molecules/OvUserTypeahead';
import {
  assignPatientToClinicLocation,
  createDemoAccount,
  loadClinicLocationPatients,
  restorePatientSearchFromUrl,
} from '../../redux/thunks/clinic-location.thunk';
import {
  clearUserList,
  setUserListPaging,
  setUserListStoredQuery,
} from '../../redux/reducers/clinic-location.slice';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ClinicLocationService } from '../../services/clinic-location.service';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import OvClinicLocationUserList from '../UI/organisms/OvClinicLocationUserList';
import { CreateDemoAccountRequest } from '../../common/model/dto/create-demo-account-request';
import OvCreateDemoAccountDialog from '../UI/molecules/OvCreateDemoAccountDialog';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

const FilterablePatientList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const history: History = useHistory();
  const [page, setPage] = useState<number>(1);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isCreateDemoAccountDialogOpen, setIsCreateaDemoAccountDialogOpen] =
    useState(false);
  const patientList: UserInfo[] = useAppSelector(
    (state) => state.clinicLocation.userList
  );
  const patientListPaging: ListPaging = useAppSelector(
    (state) => state.clinicLocation.userListPaging
  );
  const isLoading = useAppSelector((state) => state.clinicLocation.isLoading);
  const patientListStoredQuery = useAppSelector(
    (state) => state.clinicLocation.userListStoredQuery
  );
  const [pageCount, setPageCount] = useState<number>(0);
  const selectedClinicLocation: ClinicLocation | undefined = useAppSelector(
    (state) => state.user.selectedClinicLocation
  );
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.clinicLocation.error);

  useEffect(() => {
    if (search && search !== `?${patientListStoredQuery}`) {
      // load from url (cases: reloading whole page, navigating with the browser history buttons)
      dispatch(
        restorePatientSearchFromUrl({
          query: search,
          clinicLocationId: selectedClinicLocation?.id,
        })
      );
    } else if (!patientListStoredQuery) {
      // the simplest load. There is no stored url query string, and nothing in the url
      dispatch(
        loadClinicLocationPatients({
          history,
          clinicLocationId: selectedClinicLocation?.id,
        })
      );
    } else if (!search && patientListStoredQuery) {
      // jump back to the first page with side nav
      dispatch(
        setUserListPaging({
          offset: 0,
          total: patientListPaging.total,
        })
      );
      dispatch(
        loadClinicLocationPatients({
          history,
          clinicLocationId: selectedClinicLocation?.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dispatch, history, selectedClinicLocation]);

  useEffect(() => {
    return () => {
      dispatch(setUserListStoredQuery(''));
      dispatch(clearUserList());
      dispatch(setUserListPaging({ offset: 0, total: 0 }));
    };
  }, [dispatch, selectedClinicLocation]);

  useEffect(() => {
    setPage(patientListPaging.offset / ClinicService.PATIENTS_LIST_LIMIT + 1);
  }, [patientListPaging.offset, selectedClinicLocation]);

  useEffect(() => {
    setPageCount(
      Math.ceil(patientListPaging?.total / ClinicService.PATIENTS_LIST_LIMIT)
    );
  }, [patientListPaging.total, selectedClinicLocation]);

  const onCreateDemoAccount = (
    createDemoAccountRequest: CreateDemoAccountRequest
  ) => {
    dispatch(
      createDemoAccount({
        createDemoAccountRequest,
        clinicLocationId: selectedClinicLocation?.id,
        history,
      })
    );
    setIsCreateaDemoAccountDialogOpen(false);
  };

  const goToPage = (event: any, value: number) => {
    setPage(value);
    dispatch(
      setUserListPaging({
        offset: (value - 1) * ClinicService.PATIENTS_LIST_LIMIT,
        total: patientListPaging.total,
      })
    );

    dispatch(
      loadClinicLocationPatients({
        history,
        clinicLocationId: selectedClinicLocation?.id,
      })
    );
  };

  const selectUser = (user: UserInfo | any) => {
    if (user && user?.id) {
      history.push(`/patients/${user?.id}`);
    }
  };

  const handleOnSave = (request: AssignPatientToClinicLocationRequest) => {
    if (selectedClinicLocation?.id) {
      dispatch(
        assignPatientToClinicLocation({
          clinicLocationId: selectedClinicLocation.id,
          request,
        })
      );
    }
    setIsDialogOpen(false);
  };

  const fetchPatientsTypeahead = (searchText: string) => {
    if (selectedClinicLocation?.id) {
      return ClinicLocationService.getClinicLocationPatientsTypeahead(
        searchText,
        selectedClinicLocation.id
      );
    }
  };

  return (
    <Container>
      <CenterPane>
        <ButtonWrapper>
          <StyledOvButton onClick={() => setIsDialogOpen(true)}>
            <AddCircleIcon style={{ marginRight: '5' }} />
            {t('clinicUsers.actions.addNewPatient')}
          </StyledOvButton>
          <StyledOvButton
            onClick={() => setIsCreateaDemoAccountDialogOpen(true)}
          >
            <PersonAddAltIcon style={{ marginRight: '5' }} />
            {t('common.actions.createDemoAccount')}
          </StyledOvButton>
        </ButtonWrapper>
        <UserActions>
          <SearchArea>
            <StyledOvUserTypeahead
              placeholder={t('common.actions.search')}
              onUserSelect={selectUser}
              fetchUsers={fetchPatientsTypeahead}
            ></StyledOvUserTypeahead>
          </SearchArea>
          {pageCount > 1 ? (
            <OvPagination
              page={page}
              onChange={goToPage}
              count={pageCount}
              renderItem={(item) => (
                <PaginationItem
                  components={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
              shape="rounded"
            />
          ) : (
            ''
          )}
        </UserActions>
        {error ? (
          <StyledErrorMessage>
            <StyledErrorOutlineIcon />
            {error}
          </StyledErrorMessage>
        ) : (
          ''
        )}

        {patientList?.length ? (
          <OvClinicLocationUserList
            users={patientList}
            seeDetails={true}
            seeDetailsCtaLabel={t('common.actions.seeResults')}
            clinicLocationId={selectedClinicLocation?.id}
            clinicId={selectedClinicLocation?.clinic?.id}
            isPatientSharingOn={
              selectedClinicLocation?.clinic?.is_patient_sharing_on
            }
            currentUser={currentUser}
          />
        ) : (
          ''
        )}

        {!patientList?.length && !isLoading ? (
          <OvNoContent>
            {t('common.userSearchFilters.emptyUserList')}
          </OvNoContent>
        ) : null}
      </CenterPane>

      <OvPatientSearchFilter clinicLocationId={selectedClinicLocation?.id} />

      <OvAssignPatientToClinicLocationDialog
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        onSave={handleOnSave}
        title={t('clinicLocationDialogs.assignPatientTitle')}
      />

      <OvCreateDemoAccountDialog
        isOpen={isCreateDemoAccountDialogOpen}
        onCancel={() => setIsCreateaDemoAccountDialogOpen(false)}
        onSave={onCreateDemoAccount}
        isLoading={isLoading}
        title={t('common.actions.createDemoAccount')}
        enableCreatePassword={false}
      />
      {isLoading && <OvLoadingIndicator position="fixed" />}
    </Container>
  );
};

export default FilterablePatientList;

const Container = styled.section`
  display: flex;
`;

const CenterPane = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 1rem;
`;

const UserActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const SearchArea = styled.div`
  min-width: 13rem;
  flex-grow: 1;

  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }

  & input {
    padding: 0.5rem !important;
    background: ${Colours.WHITE};
  }
`;

const StyledOvUserTypeahead = styled(OvUserTypeahead)`
  max-width: 22.5rem;
`;

const ButtonWrapper = styled.div`
  flex-grow: 1;
  min-width: 13rem;
  display: flex;
  flex-wrap: 'wrap';
  justify-content: flex-end;
  @media (min-width: ${breakpoints.md}) {
    min-width: auto;
  }
  margin-bottom: 10px;
`;

const StyledOvButton = styled(OvButton)`
  && {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0.125rem 0.7rem 0.125rem 0.35rem;
    border-radius: ${Variables.borderRadius.CLINIC_DASHBOARD_LARGE};
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledErrorMessage = styled.p`
  color: ${Colours.OV_RED};
  margin: 10px 0 24px 0;
  display: flex;
  align-items: center;
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 0.5rem;
`;
